import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const StockCardSchema = yup.object().shape({
  productId: yup.string().required(),
  shopId: yup.number().required(),
  count: yup.string().required().max(8, 'Кількість занадто велика'),
});

export const ProductStockFormSchema = yup.object().shape({
  cards: yup.array().of(StockCardSchema),
});

export const ProductStockFormErrorSchema = schemaError({
  default: 'Невірний запис',
  productId: {
    default: 'Помилка збереження товару',
    invalid: 'Інвентарна картка для товару вже створена',
  },
});
