import { useContext } from 'react';

import { ConfirmContext } from 'context/ConfirmContext';
import { HIDE_CONFIRM, SHOW_CONFIRM } from 'context/ConfirmContext/reducer';

let resolver;

const useConfirm = () => {
  const [confirmState, dispatch] = useContext(ConfirmContext);

  const onConfirm = () => {
    handleClose();
    handleResolve(true);
  };

  const onCancel = () => {
    handleClose();
    handleResolve(false);
  };

  const confirm = (message: string) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        message,
      },
    });

    return new Promise((resolve, _reject) => {
      resolver = resolve;
    });
  };

  const handleResolve = (result: boolean) => resolver && resolver(result);
  const handleClose = () => {
    dispatch({
      type: HIDE_CONFIRM,
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
};

export default useConfirm;
