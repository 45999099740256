import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const EmployeePassportSchema = yup.object().shape({
  inn: yup
    .string()
    .required("ІПН обов'язкове для заповнення поле")
    .min(10, 'Введіть дійсний номер ІПН співробітника'),
});

export const EmployeeFormSchema = yup.object().shape({
  roleId: yup.string().required(),
  email: yup.string().email().nullable(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const EmployeeFormErrorSchema = schemaError({
  default: 'Помилка профайлу',
  pinCode: {
    default: 'Помилка коду',
    taken:
      'Введений код вже призначений для іншого працівника. Згенеруйте нове значення коду доступу',
  },
  phoneNumber: {
    default: 'Помилка телефону',
    invalid: 'Невірний телефон',
    taken: 'Номер телефону вже зайнятий',
  },
});
