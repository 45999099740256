import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '@smart-kasa/ui';

import { useCreateRoleMutation } from 'services/api/core/roles/api';
import { IAMPolicyEffectType } from 'services/api/core/roles/enums';

import { IRoleFormData, RoleForm } from 'features/roles/components/RoleForm';
import { InlineLink } from 'components';

export const RoleCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateRoleMutation();

  const handleSubmit = useCallback(
    ({ name, actionTypeIds }: IRoleFormData) => {
      create({
        name,
        polices: [{ effectTypeId: IAMPolicyEffectType.ALLOW, actionTypeIds }],
      })
        .unwrap()
        .then(() => navigate(-1));
    },
    [create, navigate]
  );

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Посади
        </InlineLink>
      }
    >
      <RoleForm initialValues={{}} onSubmit={handleSubmit} />
    </Page>
  );
};
