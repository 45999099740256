import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, InlineSpace, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useLazyGetProductsQuery } from 'services/api/core/inventory/api';
import type { IStockCard } from 'services/api/core/inventory/types';
import { useShops, ShopSelectField } from 'features/shops';

import { StockFormSchema } from './StockFormSchema';
import styles from './StockForm.module.scss';

interface IStockForm {
  initialValues: Partial<IStockCard>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const StockForm: FC<IStockForm> = ({ initialValues, onCancel, onSubmit }) => {
  const { shops } = useShops();
  const [fetchProducts] = useLazyGetProductsQuery();

  const loadProducts = async (term: string) => {
    const { data } = await fetchProducts({ query: term }).unwrap();

    return data;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={transformValidationSchema(StockFormSchema)}
      initialValues={initialValues}
    >
      {({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={12}>
              <Field.Switch name="countingEnabled" label="Відслідковувати залишки?" />
            </Col>
            <Col span={12}>
              <ShopSelectField
                name="shopId"
                label="Торгова точка"
                placeholder="Оберіть торгову точку"
                options={shops}
              />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Field.Input name="count" label="Кількість" />
            </Col>
            <Col span={12}>
              <Field.AsyncSelect
                name="product"
                label="Товар"
                placeholder="Пошук товару"
                required
                loadOptions={loadProducts}
                getOptionLabel={({ title, number, price }) => `${number} / ${title} - ₴ ${price}`}
                getOptionValue={({ id }) => id}
                onChange={(option) => {
                  form.batch(() => {
                    form.change('product', option);
                    form.change('productId', option.id);
                  });
                }}
              />
            </Col>
          </Row>

          <div className={styles.actions}>
            <Button width="wide" color="light" onClick={onCancel}>
              Скасувати
            </Button>
            <InlineSpace />
            <Button width="wide" color="primary" type="submit">
              Створити
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
