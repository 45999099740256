import { createSelector } from '@reduxjs/toolkit';

import { inventoryApi } from 'services/api/core/inventory/api';
import type { ICategory, IProduct } from 'services/api/core/inventory/types';

export const selectAllCategories = createSelector(
  inventoryApi.endpoints.getCategories.select(),
  (result) => result?.data?.data ?? []
);

export const selectCategoryById = createSelector(
  [selectAllCategories, (_state, categoryId) => categoryId],
  (categories, categoryId) => categories.find((category) => category.id === categoryId)
);

export const selectCategoriesWithoutId = createSelector(
  [selectAllCategories, (_state, categoryId) => categoryId],
  (categories, categoryId) => categories.filter((category) => category.id !== categoryId)
);

export const findCategorySiblings = (categories: ICategory[], categoryId: string | null = null) =>
  categories.filter(({ parentId }) => parentId === categoryId);

export const selectCategorySiblings = createSelector(
  [(state) => state, (_state, categoryId) => categoryId],
  (state, categoryId) => {
    const category = selectCategoryById(state, categoryId);
    const categories = selectAllCategories(state);
    const siblings = findCategorySiblings(categories, categoryId);

    if (siblings.length > 0) return siblings;

    return findCategorySiblings(categories, category ? category.parentId : null);
  }
);

export const selectCategoryAncestors = createSelector(
  [(state) => state, (_state, categoryId) => categoryId],
  (state, categoryId) => {
    let category = selectCategoryById(state, categoryId);
    let ancestors = category ? [category] : [];

    do {
      category = category && selectCategoryById(state, category.parentId);

      if (category) {
        ancestors.unshift(category);
      }
    } while (category);

    return ancestors;
  }
);

export const selectProductByIds = (products: IProduct[] = [], productIds: string[]) =>
  products.filter(({ id }) => productIds.includes(id));
