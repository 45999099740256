import cs from 'classnames';

import styles from './Skeleton.module.scss';

export type SkeletonTheme = 'light' | 'dark';

export type SkeletonProps = {
  width?: number | string;
  height?: number | string;
  className?: string;
  theme?: SkeletonTheme;
};

export const Skeleton = ({ className, width, height, theme = 'light' }: SkeletonProps) => (
  <div className={cs(styles.line, styles[theme], className)} style={{ width, height }}></div>
);
