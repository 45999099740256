import { FC } from 'react';
import { Hint, IconName, InlineSpace } from '@smart-kasa/ui';
import { Colors } from 'constants/colors';

import { ReceiptState, ReceiptType } from 'services/api/core/receipts/enums';

import { ReceiptStatusWrapper } from './ReceiptStatusWrapper';
import { ReceiptStatusIcon } from './ReceiptStatusIcon';
import { ReceiptStatusTitle } from './ReceiptStatusTitle';

type StatusInfo = { title: string; color: Colors; icon?: IconName };

interface IReceiptStatus {
  title?: string;
  type: ReceiptType;
  state: ReceiptState;
}

export const RECEIPT_TYPE_MAPPING: Record<ReceiptType, StatusInfo> = {
  [ReceiptType.sale]: { title: 'Продаж', color: 'green', icon: 'check' },
  [ReceiptType.serviceInput]: { title: 'Службове внесення', color: 'cyan', icon: 'arrowLeft' },
  [ReceiptType.serviceOutput]: { title: 'Службова видача', color: 'blue', icon: 'arrowRight' },
  [ReceiptType.refund]: { title: 'Повернення', color: 'yellow', icon: 'undo' },
  [ReceiptType.reversal]: { title: 'Відміна', color: 'grey', icon: 'undoSecondary' },
  [ReceiptType.offlineStart]: { title: 'Початок офлайн сесії', color: 'grey', icon: 'sync' },
  [ReceiptType.offlineFinish]: { title: 'Закінчення офлайн сесії', color: 'grey', icon: 'sync' },
};

export const RECEIPT_STATE_MAPPING: Record<ReceiptState, StatusInfo> = {
  [ReceiptState.pending]: { title: 'Потребує оплати', color: 'grey', icon: 'close' },
  [ReceiptState.paid]: { title: 'Продаж', color: 'green', icon: 'check' },
  [ReceiptState.refunded]: { title: 'Повернення', color: 'yellow', icon: 'undo' },
  [ReceiptState.archived]: { title: 'Відкладений чек', color: 'violet', icon: 'details' },
};

export const ReceiptStatus: FC<IReceiptStatus> = ({ title, type, state }) => {
  const status =
    type === ReceiptType.sale ? RECEIPT_STATE_MAPPING[state] : RECEIPT_TYPE_MAPPING[type];

  return (
    <ReceiptStatusWrapper>
      <ReceiptStatusIcon icon={status.icon} color={status.color} />
      <InlineSpace />
      <ReceiptStatusTitle>
        {status.title}
        {title && <Hint margin={0}>{title}</Hint>}
      </ReceiptStatusTitle>
    </ReceiptStatusWrapper>
  );
};
