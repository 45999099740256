import { Route, Routes } from 'react-router-dom';

import { ImportCatalogList } from './routes/ImportCatalogList';
import { ImportCatalogCreate } from './routes/ImportCatalogCreate';
import { ImportCatalogShow } from './routes/ImportCatalogShow';
import { ImportStockList } from './routes/ImportStockList';
import { ImportStockCreate } from './routes/ImportStockCreate';
import { ImportStockShow } from './routes/ImportStockShow';

export const Import = () => (
  <Routes>
    <Route path="catalog/*">
      <Route index element={<ImportCatalogList />} />
      <Route path="create" element={<ImportCatalogCreate />} />
      <Route path=":id" element={<ImportCatalogShow />} />
    </Route>
    <Route path="stock/*">
      <Route index element={<ImportStockList />} />
      <Route path="create" element={<ImportStockCreate />} />
      <Route path=":id" element={<ImportStockShow />} />
    </Route>
  </Routes>
);
