import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Page } from '@smart-kasa/ui';

import { InlineLink } from 'components/InlineLink';
import { useProfile } from 'features/profile/hooks/useProfile';
import { useFindInvoiceQuery } from 'services/api/core/invoices/api';

import { Bill } from '../../components/Bill';

export const BillingInvoice = () => {
  const { id } = useParams();
  const { data: invoice } = useFindInvoiceQuery(String(id));
  const { profile } = useProfile();
  const navigate = useNavigate();

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          <FormattedMessage id="billing.invoices.title" defaultMessage="Історія платежів" />
        </InlineLink>
      }
    >
      {invoice && <Bill invoice={invoice?.data} payer={profile} />}
    </Page>
  );
};
