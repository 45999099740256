import { useCallback, useContext } from 'react';

import useToast from 'hooks/useToast';
import { WayForPayContext } from 'context/WayForPayContext';
import { BillingContext } from 'context/BillingContext';

import { useCreditBalanceMutation } from 'services/api/core/wallet/api';
import { BillingChargeForm } from './BillingChargeForm';

export const BillingCharge = () => {
  const { refresh } = useContext(BillingContext);
  const { isSuccessful } = useContext(WayForPayContext);
  const { toast } = useToast();
  const [credit] = useCreditBalanceMutation();

  const handleCharge = useCallback(
    async (fd) => {
      const { data: invoice } = await credit(fd.amount).unwrap();

      const wayforpay = new window.Wayforpay();
      wayforpay.run(
        invoice?.wayforpay,
        () => {
          toast.success('Платіж успішно проведено');
          refresh();
        },
        () => {
          toast.error('Не вдалось провести платіж');
        },
        () => {
          toast.info('Платіж передано в обробку. Після успішної сплати баланс буде оновлено');
        }
      );
    },
    [credit, refresh, toast]
  );

  return (
    <BillingChargeForm
      defaultValue={190}
      paymentOptions={[190, 380, 570, 1140, 2280]}
      onSubmit={handleCharge}
      disabled={!isSuccessful}
    />
  );
};
