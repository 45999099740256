import { FC } from 'react';
import { ButtonIcon, Col, Icon, Row } from '@smart-kasa/ui';
import { IShop } from 'services/api/core/shop/types';

import { Field } from 'components';

interface IProductStockRow {
  name: string;
  index: number;
  shops?: IShop[];
  onRemove?: (index: number) => void;
}

export const ProductStockRow: FC<IProductStockRow> = ({ name, index, shops = [], onRemove }) => (
  <Row gutter={10}>
    <Col span={5}>
      <Field.Switch
        name={`${name}.countingEnabled`}
        label="Відслідковувати залишки?"
        direction="row-reverse"
        justifyContent="flex-end"
        alignItems="center"
      />
    </Col>
    <Col span={12}>
      <Field.Select
        name={`${name}.shopId`}
        placeholder="Оберіть торгову точку"
        required
        options={shops}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
      />
    </Col>
    <Col span={5}>
      <Field.Input name={`${name}.count`} placeholder="Кількість" required />
    </Col>
    <Col span={3}>
      <ButtonIcon onClick={() => onRemove && onRemove(index)}>
        <Icon name="trash" size="x-medium" color="danger" />
      </ButtonIcon>
    </Col>
  </Row>
);
