import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TabsNavigation, TabsNavigationItem } from '@smart-kasa/ui';

interface IBillingNavigationTab {
  path: string;
  title: string;
}

export const BillingNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const navigation: IBillingNavigationTab[] = [
    {
      path: '/billing/plans',
      title: formatMessage({ id: 'navigation.billing.plans', defaultMessage: 'Тарифи' }),
    },
    {
      path: '/billing/balance',
      title: formatMessage({ id: 'navigation.billing.balance', defaultMessage: 'Баланс' }),
    },
    {
      path: '/billing/cards',
      title: formatMessage({ id: 'navigation.billing.cards', defaultMessage: 'Спосіб оплати' }),
    },
    {
      path: '/billing/history',
      title: formatMessage({
        id: 'navigation.billing.history',
        defaultMessage: 'Історія платежів',
      }),
    },
  ];

  return (
    <TabsNavigation>
      {navigation.map(({ path, title }) => (
        <TabsNavigationItem
          key={title}
          onClick={() => navigate(path)}
          isActive={location.pathname === path}
        >
          {title}
        </TabsNavigationItem>
      ))}
    </TabsNavigation>
  );
};
