import { FC, ReactNode } from 'react';
import { Title } from '@smart-kasa/ui';

import { Field } from 'components';

import styles from './TransactionFilter.module.scss';

export interface ITransactionFilterField {
  name: string;
  value: any;
  title: string;
  icon?: ReactNode;
}

export const TransactionFilterField: FC<ITransactionFilterField> = ({ icon, title, ...rest }) => (
  <div className={styles.field}>
    <div className={styles.fieldLeft}>
      {icon && <div className={styles.fieldIcon}>{icon}</div>}
      <Title variant="h4" className={styles.fieldTitle}>
        {title}
      </Title>
    </div>

    <Field.SwitchArray className={styles.switch} {...rest} />
  </div>
);
