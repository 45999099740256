import { Tag } from '@smart-kasa/ui';

import { Colors } from 'constants/colors';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';

const STATE_MAPPING: Record<BillingSubscriptionState, { color: Colors; name: string }> = {
  [BillingSubscriptionState.WAITING]: { color: 'cyan', name: 'Очікує активації' },
  [BillingSubscriptionState.CANCELED]: { color: 'grey', name: 'Завершена' },
  [BillingSubscriptionState.ACTIVE]: { color: 'orange', name: 'Активна' },
};

type ServiceSubscriptionStatusProps = {
  state: BillingSubscriptionState;
};

export const ServiceSubscriptionStatus = ({ state }: ServiceSubscriptionStatusProps) => (
  <Tag color={STATE_MAPPING[state].color}>{STATE_MAPPING[state].name}</Tag>
);
