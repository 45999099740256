import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Page, Preloader } from '@smart-kasa/ui';

import { InlineRouteLink } from 'components';
import useToast from 'hooks/useToast';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/useAppStore';
import {
  useDeleteShopPictureMutation,
  useFindShopQuery,
  useUpdateShopMutation,
} from 'services/api/core/shop/api';
import type { IShop } from 'services/api/core/shop/types';

import { findShopById } from 'features/shops/slice';
import { ShopForm } from 'features/shops/components/ShopForm';
import { ShopErrorSchema } from 'features/shops/components/ShopForm/ShopSchema';
import { ShopStaffForm } from 'features/shops/components/ShopStaffForm';

interface IShopsQuery {
  tab?: number;
}

export const ShopEdit = () => {
  const { shopId } = useParams();
  const { query } = useQuery<IShopsQuery>();
  const { isLoading } = useFindShopQuery(Number(shopId));
  const shop = useAppSelector((state) => findShopById(state, Number(shopId)));
  const activeTab = Number(query.tab) || 0;

  const [update] = useUpdateShopMutation();
  const [destroyAttachment] = useDeleteShopPictureMutation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = useCallback(
    (shop) =>
      update(shop as IShop)
        .unwrap()
        .then(() => {
          toast.success('Збережено');

          navigate(`/shops/${shop.id}`);
        })
        .catch((err) => {
          return ShopErrorSchema.get(err);
        }),
    [update, navigate, toast]
  );

  if (!shop || isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineRouteLink icon="arrowLeft" to={`/shops/${shop.id}`}>
          {shop.title}
        </InlineRouteLink>
      }
    >
      <Tabs
        active={activeTab}
        tabs={[
          {
            id: 'info',
            title: 'Основна інформація',
            content: (
              <ShopForm
                initialValues={shop}
                onDismiss={() => navigate('/')}
                onRemoveAttachment={() => destroyAttachment(Number(shopId))}
                onSubmit={handleSubmit}
              />
            ),
          },
          { id: 'staff', title: 'Працівники', content: <ShopStaffForm shop={shop} /> },
        ]}
      />
    </Page>
  );
};
