import { useAppSelector } from 'hooks';
import { useGetShopsQuery } from 'services/api/core/shop/api';
import type { IShop } from 'services/api/core/shop/types';

import { selectAllShops } from '../slice';

type UseShopsResult = {
  shops: IShop[];
  refresh: () => void;
};

export const useShops = (): UseShopsResult => {
  const { refetch } = useGetShopsQuery();
  const shops = useAppSelector((state) => selectAllShops(state));

  return { shops, refresh: refetch };
};
