import { FC, ReactNode } from 'react';
import { Card, Col, Row, Title, UAH } from '@smart-kasa/ui';

import styles from './StatsCards.module.scss';

export type IStatsCardsRow = {
  title: string;
  value?: number;
  money: boolean;
};

export interface IStatsCardsStat {
  data: IStatsCardsRow[];
}

export interface IStatsCard {
  title: ReactNode;
  className?: string;
  children: ReactNode;
}

const StatsCard = ({ title, className, children }: IStatsCard) => (
  <Card className={className}>
    <Title variant="h2">{title}</Title>

    <div className={styles.content}>{children}</div>
  </Card>
);

const StatsCards: FC<IStatsCardsStat> = ({ data }) => (
  <Row gutter={10} className={styles.container}>
    {data &&
      data.map(({ title, value = 0, money }) => (
        <Col key={title} span={24 / data.length}>
          <StatsCard title={title}>
            {money ? <UAH decreaseHundredths value={value} /> : value}
          </StatsCard>
        </Col>
      ))}
  </Row>
);

export { StatsCard, StatsCards };
