import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { Button } from '@smart-kasa/ui';

import { Field } from 'components';

interface IBillingChargeForm {
  defaultValue: number;
  paymentOptions: number[];
  disabled?: boolean;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const BillingChargeForm = ({
  defaultValue,
  paymentOptions,
  disabled = false,
  onSubmit,
}: IBillingChargeForm) => {
  const { formatNumber } = useIntl();
  const options = paymentOptions.map((value, index) => ({
    key: index,
    value,
    label: formatNumber(value, {
      style: 'currency',
      currency: 'UAH',
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 0,
    }),
  }));

  return (
    <Form initialValues={{ amount: defaultValue }} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field.ButtonSet name="amount" options={options} />
          <Button type="submit" width="wide" color="primary" disabled={disabled}>
            Поповнити
          </Button>
        </form>
      )}
    </Form>
  );
};
