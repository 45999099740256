import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DataTable, Hint, IDataTableColumn, Paragraph, Strong, UAH } from '@smart-kasa/ui';

import { CardHolder } from 'components';
import { TAX_GROUP_MAPPING } from 'constants/taxGroup';
import type { IReceipt, IReceiptItem } from 'services/api/core/receipts/types';
import { ReceiptState } from 'services/api/core/receipts/enums';
import type { IPaymentTransaction } from 'services/api/core/transactions/types';
import { TransactionPaymentMethod } from 'features/transactions/components/TransactionPaymentMethod';

import { ReceiptStatus } from '../ReceiptStatus';

export const ReceiptTable = ({ data, ...rest }) => {
  const navigate = useNavigate();
  const openReceipt = (receipt: IReceipt) => {
    if (receipt.state === ReceiptState.archived) {
      return navigate(`/receipts/${receipt.id}/edit`);
    }

    window.open(`https://receipt.smartkasa.ua/${receipt.id}`, '_blank', 'noreferrer');
  };

  const columns: IDataTableColumn<IReceipt>[] = [
    {
      key: 'type',
      title: 'Статус',
      sort: true,
      render: (type, { state, title, description }) => (
        <Fragment>
          <ReceiptStatus title={title} type={type} state={state} />
          <Paragraph>{description && <Hint>{description}</Hint>}</Paragraph>
        </Fragment>
      ),
    },
    {
      key: 'fiscalNumber',
      sort: true,
      title: 'ФН',
      render: (fiscalNumber) => fiscalNumber || '-',
    },
    {
      key: 'totalAmount',
      sort: true,
      title: 'Сума',
      render: (totalAmount) => <UAH value={totalAmount} />,
    },
    {
      key: 'discountPercentage',
      sort: true,
      title: 'Знижка',
      render: (discountPercentage, { discountAmount }) => (
        <Fragment>
          {discountPercentage}% (<UAH value={discountAmount} />)
        </Fragment>
      ),
    },
    {
      title: 'Оплата',
      key: 'paymentTransactions',
      render: (paymentTransactions?: IPaymentTransaction[]) =>
        paymentTransactions?.map((transaction) => (
          <TransactionPaymentMethod
            key={transaction.id}
            type={transaction.transactionTypeId}
            name={transaction.pan && <CardHolder value={transaction.pan} />}
          />
        )),
    },
    {
      title: 'Дата',
      key: 'createdAt',
      sort: true,
      render: (createdAt) => (
        <Hint>
          <Paragraph>
            <Strong>
              <FormattedDate value={createdAt} dateStyle="short" />
            </Strong>
          </Paragraph>
          <Paragraph>
            <Strong>
              <FormattedDate value={createdAt} timeStyle="short" />
            </Strong>
          </Paragraph>
        </Hint>
      ),
    },
  ];

  const itemColumns: IDataTableColumn<IReceiptItem>[] = [
    {
      key: 'name',
      title: 'Назва товару',
    },
    {
      key: 'quantity',
      title: 'Кількість',
      render: (quantity, { unitTypeName, price }) => (
        <Fragment>
          {quantity} {unitTypeName} x <UAH value={price} />
        </Fragment>
      ),
    },
    {
      key: 'taxGroupId',
      title: 'Гр. оподаткування',
      render: (taxGroupId) => TAX_GROUP_MAPPING[taxGroupId]?.label || '-',
    },
    {
      key: 'amount',
      title: 'Ціна',
      render: (amount) => <UAH value={amount} />,
    },
  ];

  return (
    <DataTable
      rowIdKey="id"
      data={data}
      columns={columns}
      emptyText="Чеки відсутні чи недоступні"
      onRowClick={openReceipt}
      nested
      renderNested={({ data: receipt }) => (
        <DataTable rowIdKey="id" data={receipt.items} columns={itemColumns} head={false} />
      )}
      {...rest}
    />
  );
};
