import { Switch } from '@smart-kasa/ui';

import Field from '../Field/Field';

export const SwitchFieldArray = ({ name, value, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <Switch
        {...input}
        {...rest}
        {...props}
        checked={input.value && input.value.includes(value)}
        onChange={() => {
          const fieldValue = input.value || [];

          if (fieldValue.includes(value)) {
            const nextValue = fieldValue.filter((val: string) => val !== value);

            input.onChange(nextValue);
          } else {
            const nextValue = fieldValue.concat(value);

            input.onChange(nextValue);
          }
        }}
      />
    )}
  </Field>
);
