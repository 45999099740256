import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PageHeader, Card } from '@smart-kasa/ui';

import { BillingNavigation } from '../BillingNavigation';

const BillingLayout = () => {
  return (
    <Fragment>
      <PageHeader>
        <FormattedMessage id="navigation.billing" defaultMessage="Білінг" />
      </PageHeader>

      <BillingNavigation />

      <Card>
        <Outlet />
      </Card>
    </Fragment>
  );
};

export { BillingLayout };
