import React, { HTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cs from 'classnames';
import { Icon, IconName } from '@smart-kasa/ui';

import styles from './InlineLink.module.scss';

interface IInlineLinkIconProps {
  icon?: IconName;
}
interface IInlineLinkProps extends HTMLAttributes<HTMLElement>, IInlineLinkIconProps {}
interface IInlineRouteLinkProps extends IInlineLinkIconProps, LinkProps {}

export const InlineLink: React.FC<IInlineLinkProps> = ({ icon, className, children, ...props }) => (
  <a className={cs(styles.link, className)} {...props}>
    {icon && <Icon name={icon} className={styles.icon} size="medium" />}
    {children}
  </a>
);

export const InlineRouteLink: React.FC<IInlineRouteLinkProps> = ({
  icon,
  className,
  children,
  ...props
}) => (
  <Link className={cs(styles.link, className)} {...props}>
    {icon && <Icon name={icon} className={styles.icon} size="medium" />}
    {children}
  </Link>
);
