import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';
import type { IShift, ShiftsFilterParams } from './types';

export const shiftApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getShifts: builder.query<CoreResponse<IShift[]>, Pagination<IShift> & ShiftsFilterParams>({
      query: (params?) => ({ url: 'pos/shifts', params }),
    }),
    findShift: builder.query<CoreResponse<IShift>, string>({
      query: (id) => ({ url: `/pos/shifts/${id}` }),
    }),
  }),
});

export const { useGetShiftsQuery, useFindShiftQuery } = shiftApi;
