import { ReactNode } from 'react';
import { Icon } from '@smart-kasa/ui';

import { ExternalLink } from 'components';

import styles from './Massenger.module.scss';

export type MassengerProps = {
  url: string;
  type: 'viber' | 'telegram';
  children?: ReactNode;
};

export const Massenger = ({ url, type, children }: MassengerProps) => (
  <ExternalLink to={url} className={styles.messanger}>
    <Icon name={type} size="x-large" />
    {children}
  </ExternalLink>
);
