import { FC } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Col, Row } from '@smart-kasa/ui';

import { RangePicker } from 'components';
import { dateToISO8601String } from 'utils/date';
import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

import styles from './SalesFilter.module.scss';

export interface ISalesFilterForm {
  initialValues: any;
  onChange: (values) => void;
}

export const SalesFilterForm: FC<ISalesFilterForm> = ({ initialValues, onChange }) => {
  const { shops } = useShops();
  const { terminals } = useTerminals();

  return (
    <Form onSubmit={onChange} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <div className={styles.form}>
          <FormSpy subscription={{ values: true }} onChange={() => handleSubmit()} />

          <Row gutter={10}>
            <Col span={8}>
              <ShopSelectField
                name="shopId"
                placeholder="Оберіть торгову точку"
                options={shops}
                isClearable
                value={Number(values.shopId)}
                className={styles.select}
              />
            </Col>
            <Col span={8}>
              <TerminalSelectField
                name="terminalId"
                placeholder="Оберіть термінал"
                options={terminals}
                isClearable
                value={Number(values.terminalId)}
                className={styles.select}
              />
            </Col>
            <Col span={8}>
              <RangePicker
                from={values.dateStart ? values.dateStart : new Date()}
                to={values.dateEnd ? values.dateEnd : new Date()}
                onChangeDateStart={(date) => form.change('dateStart', dateToISO8601String(date))}
                onChangeDateEnd={(date) => form.change('dateEnd', dateToISO8601String(date))}
              />
            </Col>
          </Row>
        </div>
      )}
    </Form>
  );
};
