import { Route, Routes } from 'react-router';

import { NoMatch } from 'components';
import { AuthLayout } from 'layouts/AuthLayout';
import { AppLayout } from 'layouts/AppLayout';
import { AuthRoute } from './AuthRoute';
import { PrivateRoute } from './PrivateRoute';
import { RestrictedRoute } from './RestrictedRoute';

import { Auth } from 'features/auth';
import { Terminals } from 'features/terminals';
import { Profile } from 'features/profile';
import { Support } from 'features/support';
import { Notifications } from 'features/notifications';
import { Shops } from 'features/shops';
import { Billing } from 'features/billing';
import { Employees } from 'features/employees';
import { Roles } from 'features/roles';
import { Inventory } from 'features/inventory';
import { Shifts } from 'features/shifts';
import { Receipts } from 'features/receipts';
import { Stats } from 'features/stats';
import { Transactions } from 'features/transactions';
import { Reports } from 'features/reports';
import { Market } from 'features/market';

const Router = () => (
  <Routes>
    <Route
      element={
        <AuthRoute>
          <AuthLayout />
        </AuthRoute>
      }
    >
      <Route path="auth/*" element={<Auth />} />
    </Route>

    <Route
      element={
        <PrivateRoute>
          <AppLayout />
        </PrivateRoute>
      }
    >
      <Route path="/*" element={<Shops />} />
      <Route path="shops/*" element={<Shops />} />
      <Route path="terminals/*" element={<Terminals />} />
      <Route path="profile/*" element={<Profile />} />
      <Route path="faq/*" element={<Support />} />
      <Route path="notifications" element={<Notifications />} />
      <Route path="billing/*" element={<Billing />} />

      <Route element={<RestrictedRoute />}>
        <Route path="employees/*" element={<Employees />} />
        <Route path="roles/*" element={<Roles />} />
        <Route path="inventory/*" element={<Inventory />} />
        <Route path="shifts/*" element={<Shifts />} />
        <Route path="receipts/*" element={<Receipts />} />
        <Route path="stats/*" element={<Stats />} />
        <Route path="transactions/*" element={<Transactions />} />
        <Route path="reports/*" element={<Reports />} />
        <Route path="market/*" element={<Market />} />
      </Route>
    </Route>
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export default Router;
