import { Fragment, useCallback, useContext } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  PageHeader,
  Card,
  Row,
  Col,
  Pagination,
  Tag,
  InlineSpace,
  DropDown,
  Icon,
  ButtonIcon,
  Preloader,
} from '@smart-kasa/ui';

import { BlankView, InlineLink } from 'components';
import { useQueryPagination, useQuery } from 'hooks';
import { RROContext } from 'context/RROContext';
import { convertToCSV } from 'utils/convertToCSV';
import { useFindShiftQuery } from 'services/api/core/shifts/api';
import { useGetReceiptsQuery } from 'services/api/core/receipts/api';
import { useGetReportsQuery } from 'services/api/core/reports/api';
import { ReceiptTable } from 'features/receipts/components/ReceiptTable';
import { Report } from 'features/reports/components/Report';

export const ShiftShow = () => {
  const { id } = useParams();
  const { query } = useQuery();
  const navigate = useNavigate();
  const { onPageChange, onSortChange } = useQueryPagination();
  const { prroApi } = useContext(RROContext);

  const { data: reports, isLoading: isReportLoading } = useGetReportsQuery({ shiftId: id });
  const { data: receipts, isLoading: isReceiptLoading } = useGetReceiptsQuery({
    ...query,
    shiftId: id,
  });
  const { shift } = useFindShiftQuery(String(id), {
    selectFromResult: ({ data: response }) => ({ shift: response?.data }),
  });

  const getFiscalDocuments = useCallback(async () => {
    if (!shift) return;

    const response = await prroApi.documents.list({
      NumFiscal: shift.registrarFiscalNumber,
      OpenShiftFiscalNum: shift.fiscalNumber,
    });

    const csv = convertToCSV(response.data.Documents);
    const blob = new Blob([csv], { type: 'text/csv' });

    saveAs(blob, `documents-${shift.id}.csv`);
  }, [prroApi, shift]);

  if (!shift) return <Preloader />;

  return (
    <Fragment>
      <PageHeader
        actions={
          shift.fiscalNumber && (
            <Row gutter={10}>
              <Tag color="violet">Фіскальна зміна №{shift.fiscalNumber}</Tag>
              <InlineSpace />

              <DropDown>
                <ButtonIcon>
                  <Icon name="settings" size="large" />
                </ButtonIcon>
                <DropDown.Menu>
                  <DropDown.Item icon="template" onClick={getFiscalDocuments}>
                    Експортувати список фіскальних документів з серверу ДПС
                  </DropDown.Item>
                </DropDown.Menu>
              </DropDown>
            </Row>
          )
        }
      >
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Зміна від <FormattedDate value={shift.openedAt} dateStyle="short" timeStyle="short" />
        </InlineLink>
      </PageHeader>

      <Row gutter={10}>
        <Col span={16}>
          <Card>
            <ReceiptTable
              data={receipts?.data}
              isLoading={isReceiptLoading}
              sortMode={query.sortMode}
              sortColumn={query.sortColumn}
              onSortChange={onSortChange}
            />
            <Pagination
              totalItems={receipts?.meta?.totalCount}
              currentPage={receipts?.meta?.currentPage}
              perPage={receipts?.meta?.limitValue}
              onPageChange={onPageChange}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            {isReportLoading ? (
              <Preloader />
            ) : reports?.data.length ? (
              reports?.data.map((report, index) => <Report key={index} report={report} />)
            ) : (
              <BlankView>Звіт недоступний</BlankView>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
