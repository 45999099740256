import { ReactNode } from 'react';
import cs from 'classnames';
import { Icon } from '@smart-kasa/ui';

import { CardIssuer } from 'components/CardIssuer/CardIssuer';
import { IBillingCard } from 'services/api/core/wallet/types';

import styles from './BillingCreditCard.module.scss';

interface IBillingCreditCard {
  card: IBillingCard;
  className?: string;
  actions?: (card: IBillingCard) => ReactNode;
}

export const BillingCreditCard = ({ card, className, actions }: IBillingCreditCard) => {
  return (
    <div className={cs(styles.card, className, { [styles.primary]: card.isPrimary })}>
      {card.isPrimary && (
        <div className={styles.badge}>
          <Icon name="check" size="small" />
        </div>
      )}

      <div className={styles.image}>
        <CardIssuer pan={card.maskedPan} />
      </div>
      <div className={styles.info}>
        <div className={styles.type}>{card.cardType}</div>
        <p>{card.maskedPan}</p>
        <p>{card.email}</p>
      </div>

      <div className={styles.actions}>{actions && actions(card)}</div>
    </div>
  );
};
