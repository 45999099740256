import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { IBillingService, IStoreService, StoreServicesQuery } from './types';

export const serviceApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<CoreResponse<IStoreService[]>, StoreServicesQuery>({
      query: (params) => ({ url: '/store/services', params }),
    }),
    getPaidServices: builder.query<CoreResponse<IBillingService[]>, StoreServicesQuery>({
      query: (params) => ({ url: '/billing/services', params }),
    }),
    activateService: builder.mutation<CoreResponse<IBillingService>, number>({
      query: (serviceId) => ({
        url: '/billing/services',
        method: 'POST',
        body: { service: { serviceId } },
      }),
    }),
  }),
});

export const { useGetServicesQuery, useGetPaidServicesQuery, useActivateServiceMutation } =
  serviceApi;
