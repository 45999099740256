import { BillingInvoiceType } from 'services/api/core/invoices/enums';

export const INVOICE_TITLE: Record<BillingInvoiceType, string> = {
  [BillingInvoiceType.default]: 'Рахунок',
  [BillingInvoiceType.subscription]: 'Підписка',
  [BillingInvoiceType.regular]: 'Регулярний платіж',
  [BillingInvoiceType.verify]: 'Верифікація картки',
  [BillingInvoiceType.credit]: 'Поповнення',
  [BillingInvoiceType.debit]: 'Списання',
};
