import { IAMPolicyActionType, IAMPolicyGroupType } from 'services/api/core/roles/enums';

export const POLICY_GROUP_MESSAGES: Record<
  IAMPolicyGroupType,
  { title: string; description?: string }
> = {
  [IAMPolicyGroupType.UNDEFINED]: {
    title: 'За замовчуванням',
    description: 'Перелік прав доступу',
  },
  [IAMPolicyGroupType.SELLING]: {
    title: 'Продаж',
    description: 'Можливість продавати товар через касу',
  },
  [IAMPolicyGroupType.RETURN_PRODUCT]: {
    title: 'Повернення',
    description: 'Можливість повертати проданий товар через касу',
  },
  [IAMPolicyGroupType.KASA]: {
    title: 'Робота з касою',
    description: 'Можливість повертати проданий товар через касу',
  },
  [IAMPolicyGroupType.INVENTORY]: {
    title: 'Редагування бази товарів',
    description: 'Можливість редагувати товари, вказувати ціну, залишок і наявність',
  },
  [IAMPolicyGroupType.RSN]: {
    title: 'Управління персоналом',
    description:
      'Можливість додавати та редагувати персонал, налаштовувати посади та дозволи доступу',
  },
  [IAMPolicyGroupType.KASA_REPORTS]: {
    title: 'Формування касових звітів',
    description: 'Введіть подарунковий промокод, щоб отримати знижку',
  },
  [IAMPolicyGroupType.OPERATION_REPORTS]: {
    title: 'Доступ до управлінських звітів',
    description: 'Введіть подарунковий промокод, щоб отримати знижку',
  },
  [IAMPolicyGroupType.LOGS]: {
    title: 'Журнали',
    description: 'Введіть подарунковий промокод, щоб отримати знижку',
  },
  [IAMPolicyGroupType.TERMINALS]: {
    title: 'Налаштування каси',
    description: 'Введіть подарунковий промокод, щоб отримати знижку',
  },
  [IAMPolicyGroupType.ALCOHOL_REPORTS]: {
    title: 'Звіт по алкоголю',
    description: 'Введіть подарунковий промокод, щоб отримати знижку',
  },
  [IAMPolicyGroupType.SYNC_DATA]: {
    title: 'Обмін даними',
    description: 'Введіть подарунковий промокод, щоб отримати знижку',
  },
};

export const POLICY_PERMISSIONS_MESSAGES: Record<IAMPolicyActionType, string> = {
  [IAMPolicyActionType['selling:base']]: 'Продаж',
  [IAMPolicyActionType['selling:custom_price']]: 'Продаж за вільною ціною',
  [IAMPolicyActionType['selling:custom_discount']]: 'Зміна знижки на товар',
  [IAMPolicyActionType['return_product:with_receipt']]: 'Повернення на підставі (з рахунком)',
  [IAMPolicyActionType['return_product:without_receipt']]: 'Повернення без підстави (без рахунку)',
  [IAMPolicyActionType['kasa:cash_credit']]: 'Внесення готівки',
  [IAMPolicyActionType['kasa:cash_debit']]: 'Вилучення готівки',
  [IAMPolicyActionType['inventory:create_product']]: 'Додати товар в базу',
  [IAMPolicyActionType['inventory:create_product_confirm']]:
    'Додати товар в базу (з підтвердженням)',
  [IAMPolicyActionType['inventory:edit_product']]: 'Редагувати товар',
  [IAMPolicyActionType['inventory:edit_product_confirm']]: 'Редагувати товар (з підтвердженням)',
  [IAMPolicyActionType['inventory:delete_product']]: 'Видалити товар з бази',
  [IAMPolicyActionType['inventory:delete_product_confirm']]:
    'Видалити товар з бази (з підтвердженням)',
  [IAMPolicyActionType['inventory:confirm_changes']]: 'Підтвердження змін бази товарів',
  [IAMPolicyActionType['rsn:create_employee']]: 'Додати працівника',
  [IAMPolicyActionType['rsn:create_employee_confirm']]: 'Додати працівника (з підтвердженням)',
  [IAMPolicyActionType['rsn:edit_employee']]: 'Редагувати дані працівника',
  [IAMPolicyActionType['rsn:edit_employee_confirm']]:
    'Редагувати дані працівника (з підтвердженням)',
  [IAMPolicyActionType['rsn:change_role']]: 'Змінити функціонал посади',
  [IAMPolicyActionType['rsn:change_role_confirm']]: 'Змінити функціонал посади (з підтвердженням)',
  [IAMPolicyActionType['rsn:delete_employee']]: 'Видалити працівника',
  [IAMPolicyActionType['rsn:delete_employee_confirm']]: 'Видалити працівника (з підтвердженням)',
  [IAMPolicyActionType['rsn:confirm_changes']]: 'Підтвердження змін даних персоналу',
  [IAMPolicyActionType['kasa_reports:x_report']]: 'Сформувати Х-звіт',
  [IAMPolicyActionType['kasa_reports:z_report']]: 'Надіслати Z-звіт',
  [IAMPolicyActionType['operation_reports:sales']]: 'Звіт по продажам',
  [IAMPolicyActionType['operation_reports:transactions']]: 'Звіт по транзакціям',
  [IAMPolicyActionType['operation_reports:products']]: 'Звіт по товарам',
  [IAMPolicyActionType['operation_reports:discounts']]: 'Звіт по знижкам',
  [IAMPolicyActionType['operation_reports:return_products']]: 'Звіт по поверненням',
  [IAMPolicyActionType['operation_reports:average_receipt']]: 'Середній рахунок',
  [IAMPolicyActionType['operation_reports:terminals']]: 'Звіт по терміналам',
  [IAMPolicyActionType['logs:transactions']]: 'Журнал операцій',
  [IAMPolicyActionType['logs:documents']]: 'Журнал документів',
  [IAMPolicyActionType['terminals:appearance']]: 'Редагування вигляду чеку',
  [IAMPolicyActionType['terminals:appearance_confirm']]:
    'Редагування вигляду чеку (з підтвердженням)',
  [IAMPolicyActionType['alcohol_reports:base']]: 'Звіт по алкоголю',
  [IAMPolicyActionType['sync_data:base']]: 'Обмін даними',
};
