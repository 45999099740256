import React, { FC, ReactNode } from 'react';
import cs from 'classnames';
import styles from './BlankView.module.scss';

interface IBlankView {
  children?: ReactNode;
  className?: string;
}

export const BlankView: FC<IBlankView> = ({ className, children }) => {
  return (
    <div className={cs(styles.content, className)}>
      <div className={styles.text}>{children}</div>
    </div>
  );
};

BlankView.defaultProps = {
  children: 'Розділ пустий',
};
