import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { generatePinCode } from 'components/Form/PinField';
import { useCreateEmployeeMutation } from 'services/api/core/employees/api';
import type { IEmployee } from 'services/api/core/employees/types';

import { EmployeeForm } from 'features/employees/components/EmployeeForm';
import { EmployeeFormErrorSchema } from 'features/employees/components/EmployeeForm/EmployeeFormSchema';

export const EmployeeCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateEmployeeMutation();

  const initialValues: Partial<IEmployee> = useMemo(
    () => ({
      pinCode: generatePinCode(),
      joinedAt: new Date(),
    }),
    []
  );

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (employee) =>
      create(employee)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return EmployeeFormErrorSchema.get(err);
        }),
    [create, navigate]
  );

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          Новий працівник
        </InlineLink>
      }
    >
      <EmployeeForm
        initialValues={initialValues}
        onDismiss={handleDismiss}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};
