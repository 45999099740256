import React from 'react';
import { useScript } from 'hooks/useScript';

interface IWayForPayContext {
  isLoading: boolean;
  isSuccessful: boolean;
}

export const WayForPayContext = React.createContext<IWayForPayContext>({
  isLoading: true,
  isSuccessful: false,
});

export const WayForPayContextProvider: React.FC = ({ children }) => {
  const { isLoading, isSuccessful } = useScript(
    'https://secure.wayforpay.com/server/pay-widget.js',
    {
      id: 'widget-wfp-script',
    }
  );

  return (
    <WayForPayContext.Provider value={{ isLoading, isSuccessful }}>
      {children}
    </WayForPayContext.Provider>
  );
};
