import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Hint, Row, Title, UAH } from '@smart-kasa/ui';

import { BillingContext } from 'context/BillingContext';

import { BillingCharge } from '../../components/BillingCharge';
import styles from './BillingBalance.module.scss';

export const BillingBalance = () => {
  const { wallet } = useContext(BillingContext);

  return (
    <Row gutter={10}>
      <Col span={12}>
        <Title variant="h2">
          <FormattedMessage id="billing.balance.title" defaultMessage="Поточний баланс" />
        </Title>
        <Hint>
          <FormattedMessage
            id="billing.balance.description"
            defaultMessage="Для підключення тарифних планів та користування додатковими послугами"
          />
        </Hint>

        {wallet && <UAH decreaseHundredths value={wallet.total} className={styles.balance} />}
      </Col>
      <Col span={12}>
        <Title variant="h2">
          <FormattedMessage id="billing.credit.title" defaultMessage="Поповнення балансу" />
        </Title>
        <Hint>
          <FormattedMessage
            id="billing.credit.description"
            defaultMessage="Оберіть суму для поповнення"
          />
        </Hint>
        <BillingCharge />
      </Col>
    </Row>
  );
};
