import { Select } from '@smart-kasa/ui';

import { Field } from 'components';
import { withFinalForm } from 'components/Field/withFinalForm';
import { SelectFieldProps } from 'components/Form/SelectField';
import type { ITerminal } from 'services/api/core/pos/types';

import { TerminalSelectOption } from './TerminalSelectOption';

export const TerminalSelect = ({ name, label, onChange, ...props }: SelectFieldProps) => {
  const search = ({ data: terminal }: { data: ITerminal }, term: string) => {
    const query = [terminal.id, terminal.name, terminal.serialNumber].join();

    return query.toLowerCase().includes(term.toLowerCase());
  };

  return (
    <Field name={name}>
      {({ input, ...rest }) => (
        <Select
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          formatOptionLabel={(option) => <TerminalSelectOption terminal={option} />}
          filterOption={search}
          {...input}
          {...rest}
          {...props}
          onChange={(value, option) => {
            input.onChange(value);

            onChange && onChange(value, option);
          }}
        />
      )}
    </Field>
  );
};

export const TerminalSelectField = withFinalForm(TerminalSelect);
