import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const ReceiptItemFormSchema = yup.object().shape({
  name: yup.string().required("Назва товару обов'язкове для заповнення поле"),
  price: yup
    .number()
    .typeError("Ціна продажу обов'язкове для заповнення поле")
    .moreThan(0, 'Ціна не може бути нульовою')
    .required("Ціна продажу обов'язкове для заповнення поле"),
  quantity: yup
    .number()
    .required("Кількість обов'язкова для заповнення")
    .moreThan(0, 'Кількість не може бути нульовою'),
  amount: yup.number().required("Загальна ціна обов'язкова для заповнення"),
});

export const ReceiptFormSchema = yup.object().shape({
  title: yup.string().required("Назва обов'язкова для заповнення"),
  items: yup
    .array()
    .min(1, 'Принаймні один товар повинен бути доданим в чек')
    .of(ReceiptItemFormSchema),
  totalAmount: yup
    .number()
    .moreThan(0, 'Ціна не може бути нульовою')
    .required("Ціна продажу обов'язкове для заповнення поле"),
});

export const ReceiptFormErrorSchema = schemaError({
  default: 'Невірний чек',
});
