import { Hint } from '@smart-kasa/ui';

import type { IShop } from 'services/api/core/shop/types';

import styles from './ShopSelect.module.scss';

export type ShopSelectOptionProps = {
  shop: IShop;
};

export const ShopSelectOption = ({ shop }: ShopSelectOptionProps) => (
  <div className={styles.option}>
    <div className={styles.label}>
      #{shop.id} - {shop.title}
    </div>
    {shop.address && (
      <Hint margin={0} className={styles.description}>
        {shop.address.content}
      </Hint>
    )}
  </div>
);
