import { setIn } from 'final-form';
import { SchemaOf } from 'yup';

const transformValidationSchema = (validationSchema: SchemaOf<any>) => async (values: any) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (err) {
    // @ts-ignore
    const errors = err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export { transformValidationSchema };
