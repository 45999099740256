import { Route, Routes } from 'react-router-dom';

import { TerminalCreate } from 'features/terminals/routes/TerminalCreate';
import { TerminalEdit } from 'features/terminals/routes/TerminalEdit';

import { ShopList } from './routes/ShopList';
import { ShopCreate } from './routes/ShopCreate';
import { ShopShow } from './routes/ShopShow';
import { ShopEdit } from './routes/ShopEdit';
import { ShopDelete } from './routes/ShopDelete';

export const Shops = () => (
  <Routes>
    <Route element={<ShopList />}>
      <Route index element={null} />
      <Route path=":shopId/delete" element={<ShopDelete />} />
    </Route>
    <Route path="create" element={<ShopCreate />} />
    <Route path=":shopId/*" element={<ShopShow />}>
      <Route path="terminals/create" element={<TerminalCreate />} />
      <Route path="terminals/:id/edit" element={<TerminalEdit />} />
    </Route>
    <Route path=":shopId/edit" element={<ShopEdit />} />
  </Routes>
);
