import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Preloader } from '@smart-kasa/ui';

import { useAppSelector } from 'hooks';
import { InlineLink } from 'components';
import { useGetCategoriesQuery, useUpdateCategoryMutation } from 'services/api/core/inventory/api';
import { selectCategoryById } from 'features/inventory/slice';

import { CategoryForm } from '../../components/CategoryForm';

export const CategoryEdit = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useGetCategoriesQuery();
  const category = useAppSelector((state) => selectCategoryById(state, id));
  const [update] = useUpdateCategoryMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => navigate(-1));
    },
    [update, navigate]
  );

  if (!category || isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          Налаштування каталогу: {category.title}
        </InlineLink>
      }
    >
      <CategoryForm initialValues={category} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Page>
  );
};
