import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Preloader } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { useFindStockQuery, useUpdateStockMutation } from 'services/api/core/inventory/api';

import { StockForm } from '../../components/StockForm';
import { StockFormErrorSchema } from '../../components/StockForm/StockFormSchema';
import { StockHistory } from '../../components/StockHistory';

export const StockEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: card, isLoading } = useFindStockQuery(Number(id));
  const [update] = useUpdateStockMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      update({ ...values, id })
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return StockFormErrorSchema.get(err);
        });
    },
    [id, update, navigate]
  );

  if (!card || isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          Облік товарів
        </InlineLink>
      }
    >
      <StockForm initialValues={card?.data} onCancel={handleDismiss} onSubmit={handleSubmit} />
      {card?.data.transitions && <StockHistory transitions={card?.data.transitions} />}
    </Page>
  );
};
