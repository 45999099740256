import { FC, Fragment, ReactNode } from 'react';
import { Hint, Icon, Strong, Tooltip } from '@smart-kasa/ui';

import { useAppSelector } from 'hooks';
import { Picture } from 'components';
import { COLORS } from 'constants/colors';
import { selectCategoryById } from 'features/inventory/slice';
import type { IProduct } from 'services/api/core/inventory/types';

import styles from './ProductItem.module.scss';

interface IProductProps {
  product: IProduct;
}

const ProductStocksTooltip: FC<{ product: IProduct; children?: ReactNode }> = ({
  product,
  children,
}) => {
  if (Object.keys(product.stock).length === 0) {
    return <Fragment>{children}</Fragment>;
  }

  const stocks = Object.keys(product.stock)
    .map((key) => [key, product.stock[key]].join(': '))
    .join('\r\n');

  return (
    <Tooltip width={275} text={stocks}>
      <div className={styles.stock}>{children}</div>
    </Tooltip>
  );
};

const ProductFavoriteIcon = () => (
  <div className={styles.markers}>
    <Icon name="star" size="medium" color="primary" />
  </div>
);

export const ProductItem: FC<IProductProps> = ({ product }) => {
  const category = useAppSelector((state) => selectCategoryById(state, product.categoryId));
  const color = COLORS[(category && category.colorId) || 0];

  return (
    <div className={styles.product}>
      <Picture src={product.picture} icon="camera" fill={color} className={styles.picture} />

      <div className={styles.info}>
        <ProductStocksTooltip product={product}>
          <Strong>
            {product.title}
            {product.isFavorite && <ProductFavoriteIcon />}
          </Strong>
        </ProductStocksTooltip>
        <Hint margin={2}>{product.number}</Hint>
      </div>
    </div>
  );
};
