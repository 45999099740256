import * as yup from 'yup';

import { schemaPhone } from 'utils/sharedSchemas';

export const FeedbackFormSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  content: yup.string().required(),
  phoneNumber: schemaPhone,
});
