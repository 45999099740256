import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Page, Preloader, Tabs } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import useConfirm from 'hooks/useConfirm';
import {
  useFindProductQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDeleteProductPhotoMutation,
} from 'services/api/core/inventory/api';

import { ProductForm } from '../../components/ProductForm';
import { ProductFormErrorSchema } from '../../components/ProductForm/ProductFormSchema';
import { ProductStockForm } from '../../components/ProductStockForm';
import { ProductComponentForm } from '../../components/ProductComponentForm';

export const ProductEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { confirm } = useConfirm();
  const { data: product, isLoading, refetch } = useFindProductQuery(String(id));

  const [update] = useUpdateProductMutation();
  const [destroy] = useDeleteProductMutation();
  const [destroyPhoto] = useDeleteProductPhotoMutation();

  const handleRemoveAttachment = useCallback(() => {
    destroyPhoto(String(id));
  }, [id, destroyPhoto]);

  const handleUpdate = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return ProductFormErrorSchema.get(err);
        });
    },
    [update, navigate]
  );

  const handleDelete = useCallback(
    async (id) => {
      const isConfirmed = await confirm('Ви дійсно бажаєте видалити товар?');

      if (isConfirmed)
        destroy(id)
          .unwrap()
          .then(() => navigate(-1));
    },
    [confirm, destroy, navigate]
  );

  if (!product || isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Редагування товару/послуги: {product?.data.title}
        </InlineLink>
      }
    >
      <Tabs
        isRenderContent={false}
        tabs={[
          {
            id: 'info',
            title: 'Картка товару/послуги',
            content: (
              <ProductForm
                initialValues={product?.data}
                onSubmit={handleUpdate}
                onCancel={() => navigate(-1)}
                onRemoveAttachment={handleRemoveAttachment}
              >
                <Button color="danger" onClick={() => handleDelete(id)}>
                  Видалити
                </Button>
              </ProductForm>
            ),
          },
          {
            id: 'stock',
            title: 'Залишки',
            content: <ProductStockForm product={product?.data} onChange={refetch} />,
          },
          {
            id: 'components',
            title: 'Склад/інгредієнти',
            content: <ProductComponentForm product={product?.data} />,
          },
        ]}
      />
    </Page>
  );
};
