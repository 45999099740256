import { Fragment, useContext } from 'react';

import { Skeleton } from 'components';
import { BillingContext } from 'context/BillingContext';

import { Balance } from '../Balance';
import { Subscription } from '../Subscription';
import styles from './BillingInfo.module.scss';

export const BillingInfo = () => {
  const { subscription, wallet, isLoading } = useContext(BillingContext);

  return (
    <div className={styles.billing}>
      {isLoading ? (
        <Fragment>
          <Skeleton width={105} height={10} theme="dark" />
          <Skeleton width={180} height={10} theme="dark" />
          <Skeleton width={130} height={10} theme="dark" />
        </Fragment>
      ) : (
        <Fragment>
          {wallet && <Balance wallet={wallet} />}
          {subscription?.isActive && <Subscription subscription={subscription} />}
        </Fragment>
      )}
    </div>
  );
};
