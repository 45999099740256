import cs from 'classnames';
import cardImg from 'assets/payment_card.svg';
import styles from './BillingCreditCard.module.scss';

export const BillingCreditCardAdd = ({ className, ...rest }) => (
  <div className={cs(styles.card, styles.add, className)} {...rest}>
    <div className={styles.image}>
      <img src={cardImg} alt="" />
    </div>
    <div className={styles.type}>Додайте платіжну картку</div>
  </div>
);
