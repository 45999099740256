import { createSelector } from '@reduxjs/toolkit';

import { posApi } from 'services/api/core/pos/api';

export const selectTerminalsResult = posApi.endpoints.getTerminals.select();

export const selectAllTerminals = createSelector(
  selectTerminalsResult,
  (result) => result?.data?.data ?? []
);

export const selectTerminalById = createSelector(
  [selectAllTerminals, (_state, terminalId) => terminalId],
  (terminals, terminalId) => terminals?.find((category) => category.id === terminalId)
);

export const filterTerminals = createSelector(
  [selectAllTerminals, (_state, term) => term],
  (terminals, term) => {
    return terminals.filter(({ id, shopId, name, serialNumber }) =>
      [id, shopId, name, serialNumber].join().toLowerCase().includes(term.toLowerCase())
    );
  }
);
