import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const SubgroupFormSchema = yup.object().shape({
  title: yup.string().required(),
});

export const SubgroupFormErrorSchema = schemaError({
  default: 'Невірна підгрупа',
});
