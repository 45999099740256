import { coreApi } from '../api';
import type { CoreResponse } from 'types';
import type { GoodsFilterQuery, GoodsStatsResult } from './types';

export const statsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalesReport: builder.query<CoreResponse<GoodsStatsResult>, GoodsFilterQuery>({
      query: (params) => ({
        url: '/stats/product_daily_sales/alter',
        params,
        cache: 'no-cache',
      }),
    }),
    getSubgroupTaxesReport: builder.query<any[], any>({
      query: (params) => ({ url: '/stats/product_subgroup_sales/taxes', params }),
    }),
  }),
});

export const { useGetSalesReportQuery, useGetSubgroupTaxesReportQuery } = statsApi;
