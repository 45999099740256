import { RadioSwitch } from '@smart-kasa/ui';

import Field from '../Field/Field';

export const RadioSwitchField = ({ name, options, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <RadioSwitch
        {...input}
        {...rest}
        {...props}
        options={options}
        value={input.value}
        onChange={(value) => input.onChange(value)}
      />
    )}
  </Field>
);
