import * as yup from 'yup';

import { TAX_GROUP } from 'constants/taxGroup';
import { CLASSIFIER_TYPE } from 'constants/classifierType';

export const ReceiptItemFormSchema = yup.object().shape({
  name: yup.string().required("Назва товару обов'язкове для заповнення поле"),
  price: yup
    .number()
    .typeError("Ціна продажу обов'язкове для заповнення поле")
    .moreThan(0, 'Ціна не може бути нульовою')
    .required("Ціна продажу обов'язкове для заповнення поле"),
  quantity: yup
    .number()
    .required("Кількість обов'язкова для заповнення")
    .moreThan(0, 'Кількість не може бути нульовою'),
  taxGroupId: yup.number().required("Група оподаткування обов'язкове для заповнення поле"),
  classifierTypeId: yup.number().when('taxGroupId', {
    is: (value) =>
      [
        TAX_GROUP.AKTSYZ_5,
        TAX_GROUP.AKTSYZ_5_PDV_20,
        TAX_GROUP.AKTSYZ_5_PDV_0,
        TAX_GROUP.AKTSYZ_0_PDV_20,
      ].includes(Number(value)),
    then: yup.number().moreThan(0, "Тип класифікатора обов'язкове для заповнення поле"),
  }),
  classifierCode: yup
    .string()
    .when('classifierTypeId', {
      is: (value) => Number(value) !== CLASSIFIER_TYPE.NONE,
      then: yup.string().required("Код для класифікації обов'язкове для заповнення поле"),
    })
    .when('classifierTypeId', {
      is: (value) => Number(value) === CLASSIFIER_TYPE.DKPP,
      then: yup
        .string()
        .matches(
          /([\d]{2}\.[\d]{2}\.[\d]{2}-[\d]{2}(\.[\d]{1,2})*)|([\d]{2}\.[\d]{2}\.[\d]{2}(-[\d]{1,2})*)|([\d]{2}\.[\d]{2}(\.[\d]{1,2})*)/,
          {
            excludeEmptyString: true,
            message: 'Код ДКПП повинен мати формат XX.XX.XX-XX.XX',
          }
        ),
    })
    .when('classifierTypeId', {
      is: (value) => Number(value) === CLASSIFIER_TYPE.UKTZED,
      then: yup
        .string()
        .matches(/^\d+$/, { excludeEmptyString: true, message: 'Код УКТЗЕД має бути числом' }),
    }),
});
