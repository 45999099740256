import cs from 'classnames';
import { Icon, IconName } from '@smart-kasa/ui';

import { Colors } from 'constants/colors';

import styles from './ReceiptStatus.module.scss';

interface IReceiptStatusIcon {
  icon?: IconName;
  color?: Colors;
}

export const ReceiptStatusIcon = ({ icon, color }: IReceiptStatusIcon) => (
  <div className={cs(styles.icon, color && styles[color])}>
    {icon && <Icon name={icon} size="medium" />}
  </div>
);
