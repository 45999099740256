export enum BillingPlanState {
  PENDING = 0,
  ACTIVE = 1,
  DELETED = 2,
}

export enum BillingSubscriptionState {
  WAITING = 0,
  ACTIVE = 1,
  CANCELED = 2,
}
