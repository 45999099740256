import cs from 'classnames';
import { Skeleton } from 'components';

import styles from './BillingCreditCard.module.scss';

type BillingCreditCardPreloaderProps = React.HTMLAttributes<HTMLDivElement>;

export const BillingCreditCardPreloader = ({
  className,
  ...rest
}: BillingCreditCardPreloaderProps) => (
  <div className={cs(styles.card, className)} {...rest}>
    <div className={styles.image}>
      <Skeleton width={32} height={32} />
    </div>

    <div className={styles.info}>
      <div className={styles.type}>
        <Skeleton />
      </div>
      <Skeleton width="30%" height={12} />
      <Skeleton width="65%" height={12} />
    </div>
  </div>
);
