import { Collapse, CollapseContent, CollapseHeader } from '@smart-kasa/ui';

import type { ISupportQuestion } from 'services/api/core/support/types';

import styles from './SupportQuestion.module.scss';

export const SupportQuestion = ({ question }: { question: ISupportQuestion }) => (
  <Collapse theme="transparent" key={question.title} className={styles.collapse}>
    <CollapseHeader className={styles.collapseHeader}>{question.title}</CollapseHeader>
    <CollapseContent className={styles.collapseContent}>{question.description}</CollapseContent>
  </Collapse>
);
