import { Row, Col, Title, Hint, Card, Button } from '@smart-kasa/ui';

import { Field } from 'components';

import styles from './ProfileInfo.module.scss';

interface IProfileInfo {
  disabled?: boolean;
  onRemoveAttachment?: () => void;
}

export const ProfileInfo = ({ disabled, onRemoveAttachment }: IProfileInfo) => (
  <Card>
    <Title variant="h2">Основна інформація</Title>
    <Hint>Дані користувача:</Hint>

    <Row offset="between" className={styles.container}>
      <Col span={7}>
        <Field.FileUploader
          className={styles.uploader}
          icon="camera"
          name="avatar"
          accept=".png, .jpg, .jpeg"
          onRemove={onRemoveAttachment}
        />
      </Col>
      <Col span={17}>
        <Field.Input required label="ПІБ" name="name" />

        <Row gutter={10}>
          <Col span={12}>
            <Field.Phone required label="Телефон" name="phoneNumber" />
          </Col>
          <Col span={12}>
            <Field.Input required label="Email" name="email" />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Button type="submit" color="primary" width="full" disabled={disabled}>
              Зберегти
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </Card>
);
