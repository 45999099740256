import { FC, createContext } from 'react';

interface IConfig {
  basename: string;
  apiBaseURL: string;
  apiKey: string;
  digitalSignatureProxyUrl?: string;
  fiscalProxyUrl?: string;
  locale: string;
}

export interface IConfigContext {
  config: IConfig;
}

export const ConfigContext = createContext<IConfigContext>({
  config: {
    basename: '',
    apiBaseURL: '',
    apiKey: '',
    digitalSignatureProxyUrl: '',
    fiscalProxyUrl: '',
    locale: 'uk',
  },
});

export const ConfigContextProvider: FC<{ config: IConfig }> = ({ config, children }) => {
  return <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>;
};
