import React, { ReactNode } from 'react';

import { useGetProfileQuery } from 'services/api/core/iam/api';
import type { IAMProfile } from 'services/api/core/iam/types';

interface ProfileContextProps {
  profile?: IAMProfile;
  isLoading: boolean;
  refresh: () => void;
}

export const ProfileContext = React.createContext<ProfileContextProps>({} as any);

export const ProfileContextProvider = ({ children }: { children: ReactNode }) => {
  const { data: user, isLoading, refetch } = useGetProfileQuery();

  return (
    <ProfileContext.Provider value={{ profile: user?.data, isLoading, refresh: refetch }}>
      {children}
    </ProfileContext.Provider>
  );
};
