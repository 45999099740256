import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { ISettings } from './types';

export const settingsApi = coreApi.enhanceEndpoints({ addTagTypes: ['Settings'] }).injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<CoreResponse<ISettings>, void>({
      query: () => ({
        url: 'rsn/settings',
      }),
      providesTags: ['Settings'],
    }),
    updateSettings: builder.mutation<ISettings, Partial<ISettings>>({
      query: (settings) => ({
        url: `rsn/settings`,
        method: 'POST',
        body: { settings },
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
