import { Form } from 'react-final-form';
import { Button, Icon, FormElement, Row, Col } from '@smart-kasa/ui';

import { Field } from 'components';
import { classifierTypeOptions } from 'constants/classifierType';
import { taxGroupOptions } from 'constants/taxGroup';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useGetUnitsQuery } from 'services/api/core/units/api';
import type { IReceiptItem } from 'services/api/core/receipts/types';

import { ReceiptItemFormSchema } from './ReceiptItemFormSchema';

interface IReceiptItemForm {
  initialValues?: Partial<IReceiptItem>;
  onCancel?: () => void;
  onSubmit: (fd: Partial<IReceiptItem>) => void;
}

export const ReceiptItemForm = ({ initialValues, onCancel, onSubmit }: IReceiptItemForm) => {
  const { data: units } = useGetUnitsQuery();

  return (
    <Form<Partial<IReceiptItem>>
      validate={transformValidationSchema(ReceiptItemFormSchema)}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field.Input name="name" label="Назва товару" />

          <Row gutter={10}>
            <Col span={6}>
              <Field.Price placeholder="0" name="price" label="Ціна продажу" />
            </Col>
            <Col span={5}>
              <Field.Spinner name="quantity" min={0.001} precision={3} label="Кількість" />
            </Col>
            <Col span={12}>
              <Field.Select
                name="unitTypeId"
                label="Одиниці виміру"
                isClearable
                placeholder="Оберіть зі списку"
                options={units?.data}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.code}
              />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Field.ButtonSet
                name="classifierTypeId"
                label="Тип класифікатора"
                options={classifierTypeOptions}
              />
            </Col>
            <Col span={12}>
              <Field.Input name="classifierCode" label="Код для класифікації" />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Field.Select
                name="taxGroupId"
                label="Група оподаткування"
                placeholder="Оберіть зі списку"
                options={taxGroupOptions}
              />
            </Col>
            <Col span={12}>
              <Field.Input name="exciseLabel" label="Акцизна марка" />
            </Col>
          </Row>

          <FormElement direction="row" justifyContent="flex-end">
            <Button variant="text" color="primary" onClick={onCancel}>
              Скасувати
            </Button>
            <Button
              width="narrow"
              color="primary"
              type="submit"
              rightIcon={<Icon name="arrowRight" size="small" />}
            >
              Додати
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
