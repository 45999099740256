import { ReactNode } from 'react';

import { ProfileContextProvider } from 'context/ProfileContext';
import { UnitTypesContextProvider } from 'context/UnitTypesContext';
import { BillingContextProvider } from 'context/BillingContext';

export const PrivateContext = ({ children }: { children: ReactNode }) => (
  <ProfileContextProvider>
    <UnitTypesContextProvider>
      <BillingContextProvider>{children}</BillingContextProvider>
    </UnitTypesContextProvider>
  </ProfileContextProvider>
);
