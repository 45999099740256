import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Input, Page, Preloader, Row } from '@smart-kasa/ui';

import { useAppSelector } from 'hooks/useAppStore';
import { useGetTerminalsQuery } from 'services/api/core/pos/api';

import { filterTerminals } from 'features/terminals/slice';
import { TerminalItem } from 'features/terminals/components/TerminalItem';
import { TerminalAdd } from 'features/terminals/components/TerminalAdd';
import { TerminalOrder } from 'features/terminals/components/TerminalOrder';
import styles from './TerminalList.module.scss';

export const TerminalList = () => {
  const navigate = useNavigate();
  const [term, setTerm] = useState<string>('');
  const { isLoading } = useGetTerminalsQuery();
  const terminals = useAppSelector((state) => filterTerminals(state, term));

  return (
    <Page
      title="Обладнання"
      actions={<Input onChange={(event) => setTerm(event.target.value)} placeholder="Пошук" />}
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <Row className={styles.list}>
          {terminals.map((terminal) => (
            <TerminalItem
              key={terminal.id}
              terminal={terminal}
              onRemove={() => navigate(`${terminal.id}/delete`)}
              onClick={() => navigate(`${terminal.id}`)}
            />
          ))}

          <TerminalAdd />
          <TerminalOrder />
        </Row>
      )}
      <Outlet />
    </Page>
  );
};
