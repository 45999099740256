import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { IAMIntegration } from './types';

export const integrationsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getChannels: builder.query<CoreResponse<IAMIntegration[]>, void>({
      query: () => ({ url: 'iam/integrations' }),
    }),
    connectChannel: builder.mutation<void, Pick<IAMIntegration, 'id'> & Partial<IAMIntegration>>({
      query: ({ id, ...integration }) => ({
        url: `iam/integrations/${id}`,
        method: 'PUT',
        body: { integration },
      }),
    }),
    deleteChannel: builder.mutation<void, number>({
      query: (id) => ({
        url: `iam/integrations/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetChannelsQuery, useConnectChannelMutation, useDeleteChannelMutation } =
  integrationsApi;
