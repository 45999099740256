import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, InlineSpace, Row } from '@smart-kasa/ui';

import { useAppSelector } from 'hooks';
import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useShops, ShopSelectField } from 'features/shops';
import { useGetCategoriesQuery } from 'services/api/core/inventory/api';
import { selectCategoriesWithoutId } from 'features/inventory/slice';
import type { ICategory } from 'services/api/core/inventory/types';

import { CategoryFormSchema } from './CategoryFormSchema';
import styles from './CategoryForm.module.scss';

interface ICategoryForm {
  initialValues: Partial<ICategory>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const CategoryForm: FC<ICategoryForm> = ({ initialValues, onCancel, onSubmit }) => {
  const { shops } = useShops();
  const { isLoading } = useGetCategoriesQuery();
  const categories = useAppSelector((state) => selectCategoriesWithoutId(state, initialValues.id));

  return (
    <Form
      onSubmit={onSubmit}
      validate={transformValidationSchema(CategoryFormSchema)}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={12}>
              <Field.ColorSelect name="colorId" label="Стиль теки" placeholder="Колір" />
              <Field.Input name="title" className={styles.offset} label="Назва теки" required />
              <Field.Select
                name="parentId"
                label="Батьківський каталог"
                placeholder="Оберіть зі списку"
                isLoading={isLoading}
                options={categories}
                isClearable
                getOptionLabel={(option) => option?.title}
                getOptionValue={(option) => option?.id}
              />
            </Col>
            <Col span={12}>
              {!values.parentId && (
                <ShopSelectField
                  name="shopIds"
                  label="Торгові точки"
                  placeholder="Оберіть зі списку"
                  options={shops}
                  isClearable={false}
                  isMulti
                />
              )}
            </Col>
          </Row>

          <div className={styles.actions}>
            <Button color="sub-primary" onClick={onCancel}>
              Скасувати
            </Button>
            <InlineSpace />
            <Button color="primary" type="submit">
              Зберегти
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
