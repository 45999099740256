import { Fragment, useMemo } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  DataTable,
  Hint,
  Icon,
  IDataTableColumn,
  Inline,
  InlineSpace,
  Page,
  Pagination,
  Strong,
  UAH,
} from '@smart-kasa/ui';

import { ButtonIconLink, ButtonLink } from 'components';
import { useQueryPagination, useQuery } from 'hooks';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';
import { useDeleteStockMutation, useGetStocksQuery } from 'services/api/core/inventory/api';
import { useLazyExportStocksQuery } from 'services/api/core/export/api';
import type { IStockCard } from 'services/api/core/inventory/types';

import { StockFilter } from '../../components/StockFilter';

export const StockList = () => {
  const navigate = useNavigate();
  const { query, push: setQuery } = useQuery();
  const { confirm } = useConfirm();
  const { onPageChange, onSortChange } = useQueryPagination();
  const { isShowing, showModal, hideModal } = useModal();
  const params = useMemo(
    () => ({
      sortMode: 'asc',
      sortColumn: 'count',
      ...query,
    }),
    [query]
  );
  const { data: cards, isLoading } = useGetStocksQuery(params);
  const [download] = useLazyExportStocksQuery();
  const [destroy] = useDeleteStockMutation();

  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити запис?');
    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IStockCard>[] = [
    {
      key: 'product',
      sort: 'productTitle',
      title: 'Назва товару або послуги',
      render: (product) =>
        product && (
          <Fragment>
            <Strong>{product.title}</Strong> (<UAH value={product.price} />)
            <Hint margin={0}>{product.number}</Hint>
          </Fragment>
        ),
    },
    {
      key: 'shop',
      sort: 'shopTitle',
      title: 'Торгова точка',
      render: (shop) => shop?.title,
    },
    {
      key: 'product',
      title: 'Ціна продажу',
      render: (product) => product && <UAH value={product.price} />,
    },
    {
      key: 'product',
      title: 'Ціна закупки',
      render: (product) => {
        if (product && !product.procurementPrice) return '-';

        return <UAH value={product.procurementPrice} />;
      },
    },
    {
      key: 'product',
      title: 'Од. виміру',
      render: (product) => product.unitTypeName,
    },
    {
      key: 'count',
      sort: true,
      title: 'Кількість',
    },
    {
      key: 'updatedAt',
      sort: true,
      title: 'Дата останніх змін',
      render: (updatedAt) => (
        <FormattedDate value={updatedAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      render: (id: number) => (
        <Inline>
          <ButtonIconLink
            to={`/inventory/stock/${id}/edit`}
            onClick={(event) => event.stopPropagation()}
          >
            <Icon size="large" name="edit" />
          </ButtonIconLink>
          <InlineSpace />
          <ButtonIcon
            onClick={(event) => {
              handleDelete(id);
              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Inline>
      ),
    },
  ];

  return (
    <Page
      title="Облік товарів"
      actions={
        <Fragment>
          <Button rightIcon={<Icon name="template" />} onClick={() => download(params)}>
            Експорт
          </Button>
          <InlineSpace />
          <ButtonLink
            to="/inventory/stock/create"
            color="primary"
            rightIcon={<Icon name="add" size="small" />}
          >
            Додати
          </ButtonLink>
          <InlineSpace />
          <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
            Фільтр
          </Button>
        </Fragment>
      }
    >
      <DataTable
        data={cards?.data}
        columns={columns}
        rowIdKey="id"
        isLoading={isLoading}
        emptyText="Інвентарні картки відсутні"
        onRowClick={(card) => navigate(`/inventory/stock/${card.id}/edit`)}
        sortMode={query.sortMode}
        sortColumn={query.sortColumn}
        onSortChange={onSortChange}
      />

      <Pagination
        totalItems={cards?.meta?.totalCount}
        currentPage={cards?.meta?.currentPage}
        perPage={cards?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <StockFilter show={isShowing} onClose={hideModal} initialValues={query} onSubmit={setQuery} />
    </Page>
  );
};
