import { useAppSelector } from 'hooks';
import { useGetTerminalsQuery } from 'services/api/core/pos/api';
import type { ITerminal } from 'services/api/core/pos/types';

import { selectAllTerminals } from '../slice';

type UseTerminalsResult = {
  terminals: ITerminal[];
  refresh: () => void;
};

export const useTerminals = (): UseTerminalsResult => {
  const { refetch } = useGetTerminalsQuery();
  const terminals = useAppSelector((state) => selectAllTerminals(state));

  return { terminals, refresh: refetch };
};
