import { FC } from 'react';
import { Button, Drawer } from '@smart-kasa/ui';
import { Form } from 'react-final-form';

import { Field } from 'components';
import { useShops } from 'features/shops/hooks/useShops';
import { ShopSelectField } from 'features/shops';

export interface IProductFilterForm {
  show: boolean;
  initialValues: any;
  onClose: () => void;
  onSubmit: (values) => void;
}

export const ProductFilterForm: FC<IProductFilterForm> = ({
  initialValues,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { shops } = useShops();

  const handleSubmit = (fd) => {
    onSubmit(fd);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <Drawer
          onClose={onClose}
          title="Фільтр"
          footer={
            <Button onClick={handleSubmit} width="full" color="primary">
              Застосувати
            </Button>
          }
          {...rest}
        >
          <Field.Input name="query" label="Пошук" placeholder="Введіть текст для пошуку" />
          <ShopSelectField
            name="shopId"
            label="Торгова точка"
            placeholder="Оберіть торгову точку"
            options={shops}
            isClearable
            value={Number(values.shopId)}
          />
          <Field.Switch
            name="countingEnabled"
            label="Показувати тільки ті товари по яким ведеться облік"
          />
        </Drawer>
      )}
    </Form>
  );
};
