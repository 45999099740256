import { Link } from 'react-router-dom';
import { Card, CardTitle } from '@smart-kasa/ui';

import { BlankView } from 'components';
import type { IPaymentTransaction } from 'services/api/core/transactions/types';
import { Transaction } from 'features/transactions/components/Transaction';

import styles from './SalesTransactions.module.scss';

interface ISalesTransactions {
  transactions: IPaymentTransaction[];
}

export const SalesTransactions = ({ transactions }: ISalesTransactions) => (
  <Card>
    <CardTitle
      actions={
        <Link className={styles.link} to="/transactions">
          Всі транзакції
        </Link>
      }
    >
      Останні транзакції
    </CardTitle>

    {transactions.length ? (
      transactions.map((transaction) => (
        <Transaction key={transaction.id} transaction={transaction} />
      ))
    ) : (
      <BlankView>Історія транзакій пуста</BlankView>
    )}
  </Card>
);
