import { FC, useMemo, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Button, InlineSpace, Preloader } from '@smart-kasa/ui';

import { transformValidationSchema } from 'utils/transformValidationSchema';
import type { IShop } from 'services/api/core/shop/types';
import type { IEmployee } from 'services/api/core/employees/types';
import { useGetEmployeesQuery } from 'services/api/core/employees/api';
import {
  useCreateStaffMutation,
  useDeleteStaffMutation,
  useGetStaffQuery,
} from 'services/api/core/staff/api';

import { ShopStaffErrorSchema, ShopStaffFormSchema } from './ShopStaffFormSchema';
import { ShopStaffRow } from './ShopStaffRow';

export interface IShopStaff {
  shop: IShop;
}

export const ShopStaffForm: FC<IShopStaff> = ({ shop }) => {
  const { data: employees } = useGetEmployeesQuery({ skipPagination: true });
  const { data: connectedEmployees, refetch, isLoading } = useGetStaffQuery(shop.id);
  const [create] = useCreateStaffMutation();
  const [destroy] = useDeleteStaffMutation();

  const staff = useMemo(
    () => connectedEmployees?.data.map((item) => ({ ...item, employeeId: item.id })),
    [connectedEmployees]
  );

  const handleSubmit = useCallback(
    ({ staff }) => {
      const requests = staff
        .filter(({ id }) => !Boolean(id))
        .map((staff) => create({ shopId: shop.id, ...staff }));

      return Promise.all([...requests])
        .then(() => refetch())
        .catch((err) => ShopStaffErrorSchema.get(err));
    },
    [shop, create, refetch]
  );

  const handleRemove = useCallback(
    (employee: IEmployee) => employee.id && destroy({ id: employee.id, shopId: shop.id }),
    [shop, destroy]
  );

  if (isLoading) return <Preloader />;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={transformValidationSchema(ShopStaffFormSchema)}
      mutators={{ ...arrayMutators }}
      initialValues={{ staff }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray
            name="staff"
            component={ShopStaffRow}
            employees={employees?.data}
            onRemove={handleRemove}
          />
          <InlineSpace direction="vertical" size={30} />
          <Button type="submit" color="primary" width="wide">
            Зберегти
          </Button>
        </form>
      )}
    />
  );
};
