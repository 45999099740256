import { coreApi } from '../api';
import type { CoreResponse } from 'types';
import type { IVerification, PhoneVerificationPayload } from './types';

export const verificationApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getOTP: builder.mutation<CoreResponse<IVerification>, PhoneVerificationPayload>({
      query: ({ token, value }) => ({
        url: 'verifications/phone_numbers',
        method: 'POST',
        body: { verification: { token, phoneNumber: value } },
      }),
    }),
    validateOTP: builder.mutation<CoreResponse<IVerification>, PhoneVerificationPayload>({
      query: ({ token, value }) => ({
        url: `/verifications/phone_numbers/${token}`,
        method: 'PUT',
        body: { verification: { value } },
      }),
    }),
  }),
});

export const { useGetOTPMutation, useValidateOTPMutation } = verificationApi;
