import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button } from '@smart-kasa/ui';

import { Field } from 'components';
import type { ISettings } from 'services/api/core/settings/types';
import { transformValidationSchema } from 'utils/transformValidationSchema';

import { NotificationSettingsFormSchema } from './NotificationSettingsFormSchema';

interface INotificationSettingsForm {
  initialValues: Partial<ISettings>;
  onSubmit: (fd) => void;
}

export const NotificationSettingsForm: FC<INotificationSettingsForm> = ({
  initialValues,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      validate={transformValidationSchema(NotificationSettingsFormSchema)}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field.Switch
            name="reportNotificationEnabled"
            label={formatMessage({
              id: 'notifications.settings.enabled',
              defaultMessage: 'Відправляти звіти при закритті зміни?',
            })}
          />
          <Field.Tags
            name="reportNotificationRecipients"
            label={formatMessage({
              id: 'notifications.settings.recipients',
              defaultMessage: 'Отримувачі звітності (список електронних скриньок)',
            })}
          />

          <Button width="wide" color="primary" type="submit">
            {formatMessage({ id: 'buttons.save', defaultMessage: 'Зберегти' })}
          </Button>
        </form>
      )}
    </Form>
  );
};
