import * as yup from 'yup';
import { schemaPassword, schemaPhone } from 'utils/sharedSchemas';

export const AuthRecoveryPhoneSchema = yup.object().shape({
  phoneNumber: schemaPhone.required("Телефон обов'язкове для заповнення поле"),
});

export const AuthRecoveryPasswordSchema = yup.object().shape({
  newPassword: schemaPassword,
  newPasswordConfirmation: yup
    .string()
    .required("Пароль обов'язкове для заповнення поле")
    .oneOf([yup.ref('newPassword'), null]),
});

export const AuthRecoveryCodeSchema = yup.object().shape({
  code: yup.string().required(),
});
