import { FC } from 'react';
import { UAH } from '@smart-kasa/ui';

import { IWallet } from 'services/api/core/wallet/types';

import styles from './Balance.module.scss';

interface IBalanceProps {
  wallet: IWallet;
  children?: never;
}

export const Balance: FC<IBalanceProps> = ({ wallet }) => (
  <div className={styles.balance}>
    Баланс: <UAH value={wallet.total} className={styles.amount} />
  </div>
);
