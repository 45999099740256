import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, PageHeader, Preloader } from '@smart-kasa/ui';

import { useFindSubgroupQuery, useUpdateSubgroupMutation } from 'services/api/core/inventory/api';

import { SubgroupForm } from '../../components/SubgroupForm';
import { SubgroupFormErrorSchema } from '../../components/SubgroupForm/SubgroupFormSchema';
import styles from './SubgroupEdit.module.scss';

export const SubgroupEdit = () => {
  const { id } = useParams();
  const { data: subgroup, isLoading } = useFindSubgroupQuery(Number(id));
  const [update] = useUpdateSubgroupMutation();
  const navigate = useNavigate();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      update({ ...values, id })
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return SubgroupFormErrorSchema.get(err);
        });
    },
    [id, update, navigate]
  );

  return (
    <Modal className={styles.modal} onClose={handleDismiss}>
      <PageHeader>Редагування товарної підгрупи</PageHeader>

      {!subgroup || isLoading ? (
        <Preloader />
      ) : (
        <SubgroupForm
          initialValues={subgroup?.data}
          onCancel={handleDismiss}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};
