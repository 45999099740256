import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Icon, PageHeader, Preloader } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { useFindReceiptQuery, useUpdateReceiptMutation } from 'services/api/core/receipts/api';
import { useLazyExportReceiptsQuery } from 'services/api/core/export/api';

import { ReceiptFormErrorSchema } from 'features/receipts/components/ReceiptForm/ReceiptFormSchema';
import { ReceiptForm } from 'features/receipts/components/ReceiptForm';

export const ReceiptEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: receipt, isLoading } = useFindReceiptQuery(String(id));
  const [update] = useUpdateReceiptMutation();
  const [download] = useLazyExportReceiptsQuery();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      update({ ...values, id })
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return ReceiptFormErrorSchema.get(err);
        });
    },
    [id, update, navigate]
  );

  if (!receipt || isLoading) return <Preloader />;

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button rightIcon={<Icon name="template" />} onClick={() => download({ id })}>
            Експорт
          </Button>
        }
      >
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Відкладені чеки
        </InlineLink>
      </PageHeader>
      <ReceiptForm initialValues={receipt?.data} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
