import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonText, InlineSpace } from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import type { ISubgroup } from 'services/api/core/inventory/types';

import { SubgroupFormSchema } from './SubgroupFormSchema';
import styles from './SubgroupForm.module.scss';

interface ISubgroupForm {
  initialValues: Partial<ISubgroup>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const SubgroupForm: FC<ISubgroupForm> = ({ initialValues, onCancel, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    validate={transformValidationSchema(SubgroupFormSchema)}
    initialValues={initialValues}
  >
    {({ handleSubmit, values }) => (
      <form onSubmit={handleSubmit}>
        <Field.Input name="title" label="Назва підгрупи" required className={styles.input} />

        <div className={styles.actions}>
          <ButtonText onClick={onCancel}>Скасувати</ButtonText>
          <InlineSpace />
          <Button width="auto" color="primary" type="submit">
            Створити
          </Button>
        </div>
      </form>
    )}
  </Form>
);
