import { coreApi } from '../api';
import { CoreResponse } from 'types/api';

import type { IFeedbackMessage } from './types';

export const feedbackApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    createFeedback: builder.mutation<CoreResponse<IFeedbackMessage>, Partial<IFeedbackMessage>>({
      query: (body) => ({
        url: '/feedbacks/messages',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useCreateFeedbackMutation } = feedbackApi;
