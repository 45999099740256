import { createSelector } from '@reduxjs/toolkit';

import { shopApi } from 'services/api/core/shop/api';

export const selectAllShops = createSelector(
  shopApi.endpoints.getShops.select(),
  (result) => result?.data?.data ?? []
);

export const findShopById = (state, id) =>
  shopApi.endpoints.findShop.select(id)(state)?.data?.data ?? null;

export const selectShopById = createSelector(
  [selectAllShops, (_state, shopId) => shopId],
  (shops, shopId) => shops?.find(({ id }) => id === shopId)
);

export const filterShops = createSelector([selectAllShops, (_state, term) => term], (shops, term) =>
  shops.filter(({ title }) => title.toLowerCase().includes(term.toLowerCase()))
);
