import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { DataTable, IDataTableColumn, UAH, Icon, Button } from '@smart-kasa/ui';

import { CardHolder } from 'components';
import type { IPaymentTransaction } from 'services/api/core/transactions/types';
import { TransactionPaymentType, TransactionType } from 'services/api/core/transactions/enums';

import { PaymentType } from '../PaymentType';
import { TransactionPaymentMethod } from '../TransactionPaymentMethod';
import styles from './TransactionTable.module.scss';

export const TransactionTable = ({ data, ...rest }) => {
  const openReceipt = (transaction: IPaymentTransaction) =>
    window.open(`https://receipt.smartkasa.ua/${transaction.receiptId}`, '_blank', 'noreferrer');

  const columns: IDataTableColumn<IPaymentTransaction>[] = [
    {
      key: 'rrn',
      title: 'Номер транзакції',
      render: (rrn: string, { paymentTypeId }) => (
        <Fragment>
          <PaymentType status={paymentTypeId} />
          <div className={styles.number}>{rrn}</div>
        </Fragment>
      ),
    },
    {
      key: 'timestamp',
      title: 'Дата транзакції',
      render: (timestamp: Date) => (
        <FormattedDate value={timestamp} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'transactionTypeId',
      title: 'Тип транзакції',
      render: (transactionTypeId: TransactionType, { payload }) => (
        <TransactionPaymentMethod
          type={transactionTypeId}
          name={payload && payload.maskedPan && <CardHolder value={payload.maskedPan} />}
        />
      ),
    },
    {
      title: 'Сума',
      key: 'amount',
      render: (amount, data: IPaymentTransaction) => (
        <UAH
          value={
            amount *
            (data.paymentTypeId === TransactionPaymentType.refund ||
            data.paymentTypeId === TransactionPaymentType.reversal
              ? -1
              : 1)
          }
          colored
        />
      ),
    },
    {
      key: 'id',
      render: () => <Button rightIcon={<Icon name="receipt" size="x-large" />}>Чек</Button>,
    },
  ];

  return (
    <DataTable
      rowIdKey="id"
      data={data}
      columns={columns}
      emptyText="Історія транзакій пуста"
      onRowClick={openReceipt}
      {...rest}
    />
  );
};
