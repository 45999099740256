import Highcharts, { GradientColorObject } from 'highcharts';

export const gradientFillColor = (color): GradientColorObject => ({
  linearGradient: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 1,
  },
  stops: [
    [0, new Highcharts.Color(color).setOpacity(0.3).get('rgba') as string],
    [1, new Highcharts.Color(color).setOpacity(0).get('rgba') as string],
  ],
});
