import { Fragment, useContext } from 'react';
import { PageHeader, Button, Card, Preloader, CardTitle, Title } from '@smart-kasa/ui';

import { useGetFaqCategoriesQuery, useGetFaqQuestionsQuery } from 'services/api/core/support/api';
import { FeedbackContext } from 'features/support/context/FeedbackContext';
import { SupportQuestionList } from 'features/support/components/SupportQuestionList';
import { SupportCategoryList } from 'features/support/components/SupportCategoryList';

import SupportHeader from 'assets/support_header.png';
import styles from './FaqList.module.scss';

export const FaqList = () => {
  const { showModal } = useContext(FeedbackContext);
  const { data: categories, isLoading: isCategoriesLoading } = useGetFaqCategoriesQuery();
  const { data: questions, isLoading: isQuestionsLoading } = useGetFaqQuestionsQuery({
    popular: true,
  });

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button color="primary" onClick={showModal}>
            Надіслати запит
          </Button>
        }
      >
        Підтримка
      </PageHeader>

      <Fragment>
        <Card className={styles.categories}>
          <div className={styles.header} style={{ backgroundImage: `url(${SupportHeader})` }}>
            <Title variant="h2" className={styles.title}>
              Підтримка Смарт Каса
            </Title>
          </div>

          {isCategoriesLoading ? (
            <Preloader />
          ) : (
            categories && <SupportCategoryList categories={categories.data} />
          )}
        </Card>

        <Card>
          <CardTitle className={styles.questionsTitle}>Популярні запитання</CardTitle>

          {isQuestionsLoading ? (
            <Preloader />
          ) : (
            questions && <SupportQuestionList questions={questions?.data} />
          )}
        </Card>
      </Fragment>
    </Fragment>
  );
};
