import { Button, Hint, Strong } from '@smart-kasa/ui';

import { Picture } from 'components';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';
import type { IBillingService } from 'services/api/core/services/types';

import { ServiceSubscriptionStatus } from '../ServiceSubscriptionStatus';
import styles from './ServiceSubscription.module.scss';

interface IServiceSubscription {
  subscription: IBillingService;
  children?: never;
}

export const ServiceSubscription = ({ subscription }: IServiceSubscription) => {
  const { service, payload } = subscription;
  const purchase = () => {
    const wayforpay = new window.Wayforpay();

    wayforpay.invoice(payload?.invoiceUrl);
  };

  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <Picture icon="shopApps" size="L" src={service?.picture} />
        <div className={styles.info}>
          <Strong>{service?.title}</Strong>
          <div className={styles.status}>
            <ServiceSubscriptionStatus state={subscription.state} />
          </div>
          <Hint>{service?.description}</Hint>
        </div>
      </div>

      {subscription.state === BillingSubscriptionState.WAITING && (
        <Button color="danger" onClick={purchase}>
          Оплатити
        </Button>
      )}
    </div>
  );
};
