import { Fragment } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { ButtonText, Icon, InlineSpace } from '@smart-kasa/ui';

import type { IStockCard } from 'services/api/core/inventory/types';
import type { IShop } from 'services/api/core/shop/types';

import { ProductStockRow } from './ProductStockRow';

interface IProductStockCardForm extends FieldArrayRenderProps<Partial<IStockCard>, any> {
  shops?: IShop[];
  initialCard?: Partial<IStockCard>;
  onRemove?: (card) => void;
}

export const ProductStockCardForm = ({
  fields,
  initialCard = {},
  shops = [],
  onRemove,
}: IProductStockCardForm) => {
  const handleAppend = () => fields.push(initialCard);
  const handleRemove = (index) => {
    const card = fields.value[index];
    fields.remove(index);

    card.id && onRemove && onRemove(card.id);
  };

  return (
    <Fragment>
      {fields.map((name, index) => (
        <ProductStockRow
          key={`${name}:${index}`}
          name={name}
          index={index}
          shops={shops}
          onRemove={handleRemove}
        />
      ))}

      <ButtonText onClick={handleAppend}>
        <Icon name="plus" color="primary" size="small" />
        <InlineSpace />
        Додати інвентарну картку
      </ButtonText>
    </Fragment>
  );
};
