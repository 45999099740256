import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import {
  ButtonIcon,
  DataTable,
  Hint,
  IDataTableColumn,
  IDataTableProps,
  Icon,
  InlineSpace,
  ProgressBar,
  Strong,
} from '@smart-kasa/ui';

import type { IImportDocument } from 'services/api/core/inventory/types';

import { ImportDocumentStatus } from '../ImportDocumentStatus';
import styles from './ImportTable.module.scss';

export interface IImportTable
  extends Omit<IDataTableProps<IImportDocument, 'id'>, 'rowIdKey' | 'columns'> {}

export const ImportTable: FC<IImportTable> = (props) => {
  const columns: IDataTableColumn<IImportDocument>[] = [
    {
      key: 'filename',
      title: 'Назва',
      width: '30%',
      render: (filename, { state, createdAt }) => (
        <div className={styles.state}>
          <ImportDocumentStatus state={state} />

          <div className={styles.name}>
            {filename}

            <Hint margin={0}>
              <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
            </Hint>
          </div>
        </div>
      ),
    },
    {
      key: 'totalCount',
      title: 'Прогрес завантаження',
      width: '40%',
      render: (_value, { totalCount = 0, createdCount = 0, updatedCount = 0 }) => {
        const percent = (((createdCount + updatedCount) * 100) / totalCount || 0).toFixed(2);

        return <ProgressBar percent={percent} />;
      },
    },
    {
      key: 'createdCount',
      title: 'Статистика',
      width: '25%',
      render: (_value, { productsCount = 0, createdCount = 0, updatedCount = 0 }) => (
        <div className={styles.rows}>
          <div className={styles.counter}>
            Рядків: <Strong>{productsCount}</Strong>
          </div>
          <InlineSpace />
          <div className={styles.counter}>
            Створено: <Strong>{createdCount}</Strong>
          </div>
          <InlineSpace />
          <div className={styles.counter}>
            Оновлено: <Strong>{updatedCount}</Strong>
          </div>
        </div>
      ),
    },
    {
      key: 'id',
      render: (id: number) => (
        <ButtonIcon className={styles.button}>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return <DataTable rowIdKey="id" columns={columns} {...props} />;
};
