import { FC } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Col, Row } from '@smart-kasa/ui';

import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

import styles from './ReceiptArchiveFilter.module.scss';

export interface IReceiptArchiveFilterForm {
  initialValues: any;
  onChange: (values) => void;
}

export const ReceiptArchiveFilterForm: FC<IReceiptArchiveFilterForm> = ({
  initialValues,
  onChange,
}) => {
  const { shops } = useShops();
  const { terminals } = useTerminals();

  return (
    <Form onSubmit={onChange} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <div>
          <FormSpy subscription={{ values: true }} onChange={() => handleSubmit()} />

          <Row gutter={10}>
            <Col span={12}>
              <ShopSelectField
                name="shopId"
                label="Торгова точка"
                placeholder="Оберіть торгову точку"
                options={shops}
                isClearable
                value={Number(values.shopId)}
                className={styles.field}
              />
            </Col>
            <Col span={12}>
              <TerminalSelectField
                name="terminalId"
                label="Термінал"
                placeholder="Оберіть термінал"
                options={terminals}
                isClearable
                value={Number(values.terminalId)}
                className={styles.field}
              />
            </Col>
          </Row>
        </div>
      )}
    </Form>
  );
};
