import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Preloader } from '@smart-kasa/ui';

import { useFindRoleQuery, useUpdateRoleMutation } from 'services/api/core/roles/api';
import { IAMPolicyEffectType } from 'services/api/core/roles/enums';

import { IRoleFormData, RoleForm } from 'features/roles/components/RoleForm';
import { InlineLink } from 'components';

export const RoleEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: role, isLoading } = useFindRoleQuery(Number(id));
  const [update] = useUpdateRoleMutation();

  const data = useMemo(
    () => ({
      name: role?.data.name,
      actionTypeIds: role?.data.polices.flatMap((policy) =>
        policy.actionTypeIds.flatMap((id) => id)
      ),
    }),
    [role]
  );

  const handleSubmit = useCallback(
    ({ name, actionTypeIds }: IRoleFormData) => {
      update({
        id: Number(id),
        name,
        polices: [{ effectTypeId: IAMPolicyEffectType.ALLOW, actionTypeIds }],
      })
        .unwrap()
        .then(() => navigate(-1));
    },
    [id, update, navigate]
  );

  if (!role || isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          {role?.data?.name}
        </InlineLink>
      }
    >
      <RoleForm initialValues={data} onSubmit={handleSubmit} />
    </Page>
  );
};
