import { FC } from 'react';
import cs from 'classnames';
import { Icon } from '@smart-kasa/ui';

import { TransactionPaymentType } from 'services/api/core/transactions/enums';

import styles from './PaymentType.module.scss';

interface ITransactionsStatus {
  status: TransactionPaymentType;
  size?: 'small' | 'medium';
}

interface ITransactionsStatusIconRenderProps {
  size?: ITransactionsStatus['size'];
}

const THEME_BY_TYPE: Record<TransactionPaymentType, string> = {
  [TransactionPaymentType.pay]: 'pay',
  [TransactionPaymentType.refund]: 'refund',
  [TransactionPaymentType.reversal]: 'reversal',
};

const ICON_BY_TYPE: Record<TransactionPaymentType, FC<ITransactionsStatusIconRenderProps>> = {
  [TransactionPaymentType.pay]: ({ size }) => <Icon name="check" size={size} />,
  [TransactionPaymentType.refund]: ({ size }) => <Icon name="undo" size={size} />,
  [TransactionPaymentType.reversal]: ({ size }) => <Icon name="close" size={size} />,
};

export const PaymentType: FC<ITransactionsStatus> = ({ status, size = 'small' }) => (
  <div className={cs(styles.icon, styles[THEME_BY_TYPE[status]], styles[size])}>
    {ICON_BY_TYPE[status] && ICON_BY_TYPE[status]({ size })}
  </div>
);
