import * as yup from 'yup';
import { schemaError } from 'utils/schemaError';
import { schemaPassword, schemaPhone } from 'utils/sharedSchemas';

export const AuthLoginSchema = yup.object().shape({
  phoneNumber: schemaPhone,
  password: schemaPassword,
});

// TODO: Translate
export const AuthLoginErrorSchema = schemaError({
  default: 'Невірний номер телефону чи пароль',
});
