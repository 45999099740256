import { FC, Fragment, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Button,
  ButtonIcon,
  ButtonText,
  Col,
  Icon,
  InlineSpace,
  Preloader,
  Row,
} from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import {
  useGetProductComponentsQuery,
  useLazyGetProductsQuery,
  useUpdateProductComponentsMutation,
} from 'services/api/core/inventory/api';
import type { IProduct } from 'services/api/core/inventory/types';

import { ProductComponentFormSchema } from './ProductComponentFormSchema';
import styles from './ProductComponentForm.module.scss';

interface IProductComponentForm {
  product: IProduct;
}

export const ProductComponentForm: FC<IProductComponentForm> = ({ product }) => {
  const { toast } = useToast();
  const { data: item, isLoading } = useGetProductComponentsQuery(product.id);
  const [update] = useUpdateProductComponentsMutation();
  const [fetchProducts] = useLazyGetProductsQuery();

  const handleSubmit = useCallback(
    ({ components }) => {
      update({ id: product.id, components })
        .unwrap()
        .then(() => toast.success('Збережено'))
        .catch(() => toast.error('Не вдалось оновити склад'));
    },
    [product, update, toast]
  );

  const loadProducts = async (term: string) => {
    const { data } = await fetchProducts({ query: term }).unwrap();

    return data;
  };

  if (isLoading) return <Preloader />;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={transformValidationSchema(ProductComponentFormSchema)}
      mutators={{ ...arrayMutators }}
      initialValues={{ components: item?.data?.components }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name="components">
            {({ fields }) => (
              <Fragment>
                {fields.map((name, index) => (
                  <Row gutter={10} key={name}>
                    <Col span={12}>
                      <Field.AsyncSelect
                        name={`${name}.item`}
                        placeholder="Пошук товару"
                        required
                        loadOptions={loadProducts}
                        getOptionLabel={({ title, unitTypeName }) => `${title} / ${unitTypeName}`}
                        getOptionValue={({ id }) => id}
                        onChange={(option) => {
                          fields.update(index, {
                            ...fields.value[index],
                            item: option,
                            itemId: option.id,
                          });
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <Field.Input name={`${name}.quantity`} placeholder="Кількість" required />
                    </Col>
                    <Col span={3}>
                      <ButtonIcon onClick={() => fields.remove(index)}>
                        <Icon name="trash" size="x-medium" color="danger" />
                      </ButtonIcon>
                    </Col>
                  </Row>
                ))}

                <ButtonText onClick={() => fields.push({})}>
                  <Icon name="plus" color="primary" size="small" />
                  <InlineSpace />
                  Додати компонент
                </ButtonText>
              </Fragment>
            )}
          </FieldArray>

          <div className={styles.actions}>
            <Button type="submit" className={styles.button} color="primary">
              Зберегти
            </Button>
          </div>
        </form>
      )}
    />
  );
};
