import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import cs from 'classnames';
import { Layout, Row } from '@smart-kasa/ui';

import { Logo, Navigation } from 'components';
import { BillingContext } from 'context/BillingContext';

import { CurrentUser } from './components/CurrentUser';
import { BillingInfo } from './components/BillingInfo';
import { Support } from './components/Support';
import styles from './AppLayout.module.scss';

export const AppLayout = () => {
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState(false);
  const { subscription } = useContext(BillingContext);

  useEffect(() => {
    setIsOpened(false);
  }, [pathname]);

  return (
    <Layout className={styles.wrapper}>
      <Layout.Sider className={cs(styles.sidebar, { [styles.opened]: isOpened })}>
        <Logo theme="light" className={styles.logo} />
        <Navigation
          className={styles.menu}
          resticted={!subscription?.isActive}
          isOpened={isOpened}
          onOpenedChange={setIsOpened}
        />
        <BillingInfo />
      </Layout.Sider>

      <Layout className={styles.container}>
        <Layout.Header className={styles.header}>
          <Row>
            <Logo className={styles.headerLogo} theme="dark" />
          </Row>
          <Row>
            <Support />
            <CurrentUser />
          </Row>
        </Layout.Header>
        <Layout.Content className={styles.content}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
