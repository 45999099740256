import { ReactNode } from 'react';
import { Hint, Paragraph, Strong, UAH } from '@smart-kasa/ui';

import { Picture } from 'components';
import type { IStoreService } from 'services/api/core/services/types';

import styles from './ServiceCard.module.scss';

interface IServiceCard {
  service: IStoreService;
  actions?: (service: IStoreService) => ReactNode;
  children?: never;
}

export const ServiceCard = ({ service, actions }: IServiceCard) => (
  <div className={styles.card}>
    <div className={styles.container}>
      <Picture icon="shopApps" size="L" src={service.picture} />
      <div className={styles.info}>
        <Strong>{service.title}</Strong>
        <Paragraph>
          <UAH value={service.price} />
        </Paragraph>
        <Hint>{service.description}</Hint>
      </div>
    </div>

    {actions && actions(service)}
  </div>
);
