import { Card, CardTitle, InlineSpace, PageHeader } from '@smart-kasa/ui';

import { WayForPayContextProvider } from 'context/WayForPayContext';

import { ServiceList } from './components/ServiceList';
import { ServiceSubscriptionList } from './components/ServiceSubscriptionList';

export const Market = () => (
  <WayForPayContextProvider>
    <PageHeader>Смарт маркет</PageHeader>

    <Card>
      <ServiceList />
    </Card>

    <InlineSpace direction="vertical" size={20} />

    <CardTitle>Історія активацій</CardTitle>
    <Card>
      <ServiceSubscriptionList />
    </Card>
  </WayForPayContextProvider>
);
