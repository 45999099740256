import { createApi } from '@reduxjs/toolkit/query/react';

import { downloadBaseQuery } from 'services/queries/downloadBaseQuery';

import type { ProductListQuery, StockListQuery } from '../inventory/types';
import type { ReceiptListQuery } from '../receipts/types';
import type { PaymentTransactionListQuery } from '../transactions/types';
import type { GoodsFilterQuery } from '../stats/types';
import type { TaxesReportListQuery } from '../reports/types';

export const exportApi = createApi({
  reducerPath: 'exportApi',
  baseQuery: downloadBaseQuery,
  endpoints: (builder) => ({
    exportProducts: builder.query<any, ProductListQuery>({
      query: (params) => ({
        url: `/inventory/products.xlsx`,
        params,
        cache: 'no-cache',
      }),
    }),
    exportStocks: builder.query<any, StockListQuery>({
      query: (params) => ({
        url: `/inventory/cards.xlsx`,
        params,
        cache: 'no-cache',
      }),
    }),
    exportReceipts: builder.query<any, ReceiptListQuery>({
      query: (params) => ({
        url: `/pos/receipts.xlsx`,
        params,
        cache: 'no-cache',
      }),
    }),
    exportTransactions: builder.query<any, PaymentTransactionListQuery>({
      query: (params) => ({
        url: `/reports/transactions.xlsx`,
        params,
        cache: 'no-cache',
      }),
    }),
    exportSales: builder.query<any, GoodsFilterQuery>({
      query: (params) => ({
        url: `/stats/product_daily_sales/alter.xlsx`,
        params,
        cache: 'no-cache',
      }),
    }),
    exportReports: builder.query<any, TaxesReportListQuery>({
      query: (params) => ({
        url: `/reports/z-reports.xlsx`,
        params,
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyExportProductsQuery,
  useLazyExportStocksQuery,
  useLazyExportReceiptsQuery,
  useLazyExportTransactionsQuery,
  useLazyExportSalesQuery,
  useLazyExportReportsQuery,
} = exportApi;
