import { useCallback, useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { ButtonText } from '@smart-kasa/ui';

import { Field } from 'components';
import { SessionContext } from 'context/SessionContext';
import { AuthForm } from 'features/auth/components/AuthForm';
import {
  AuthRegistrationCodeSchema,
  AuthRegistrationEmailErrorSchema,
  AuthRegistrationEmailSchema,
  AuthRegistrationErrorSchema,
  AuthRegistrationNameSchema,
  AuthRegistrationPasswordSchema,
  AuthRegistrationPhoneSchema,
} from './AuthRegistrationSchema';
import { AuthRegistrationPhone } from './AuthRegistrationPhone';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import styles from './AuthRegistration.module.scss';
import { useGetOTPMutation, useValidateOTPMutation } from 'services/api/core/verifications/api';
import {
  useSignUpMutation,
  useValidateEmailMutation,
  useValidatePhoneMutation,
} from 'services/api/core/auth/api';

enum AUTH_LOGIN_STEP {
  phone,
  confirmPhone,
  password,
  name,
  email,
}

const SCHEMA_BY_STEP = {
  [AUTH_LOGIN_STEP.phone]: AuthRegistrationPhoneSchema,
  [AUTH_LOGIN_STEP.confirmPhone]: AuthRegistrationCodeSchema,
  [AUTH_LOGIN_STEP.name]: AuthRegistrationNameSchema,
  [AUTH_LOGIN_STEP.password]: AuthRegistrationPasswordSchema,
  [AUTH_LOGIN_STEP.email]: AuthRegistrationEmailSchema,
};

export type AuthRegistrationForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
  email: string;
  code: number;
};

export const AuthRegistration = () => {
  const { login } = useContext(SessionContext);
  const navigate = useNavigate();
  const [step, setStep] = useState<AUTH_LOGIN_STEP>(AUTH_LOGIN_STEP.phone);
  const [publicToken, setPublicToken] = useState('');
  const [recaptchaLoaded, setRecaptchaLoaded] = useState<boolean>(false);
  const reCaptchaRef = useRef<ReCAPTCHA>();

  const [sendVerificationCode] = useGetOTPMutation();
  const [validateVerificationCode] = useValidateOTPMutation();
  const [validatePhone] = useValidatePhoneMutation();
  const [validateEmail] = useValidateEmailMutation();
  const [signUp] = useSignUpMutation();

  const sendCode = useCallback(
    async (phone: string) => {
      setStep(AUTH_LOGIN_STEP.confirmPhone);

      try {
        const reCaptchaToken = await reCaptchaRef.current.executeAsync();
        const { data } = await sendVerificationCode({
          token: reCaptchaToken,
          value: phone,
        }).unwrap();

        setPublicToken(data.publicToken);

        reCaptchaRef.current.reset();
      } catch (err) {
        const errObject = AuthRegistrationErrorSchema.get(err);

        return { phoneNumber: errObject['value'] };
      }
    },
    [sendVerificationCode]
  );

  const submit = useCallback(
    async (fd: AuthRegistrationForm) => {
      const user = {
        ...fd,
        name: fd.firstName + ' ' + fd.lastName,
        lastName: undefined,
        firstName: undefined,
      };

      try {
        await signUp(user).unwrap();
      } catch (err) {
        const errObject = AuthRegistrationErrorSchema.get(err);
        return { phoneNumber: errObject['value'] };
      }
    },
    [signUp]
  );

  const currentSchema = SCHEMA_BY_STEP[step];

  const validateCode = useCallback(
    (code: number) => validateVerificationCode({ token: publicToken, value: code }),
    [publicToken, validateVerificationCode]
  );

  const handleSubmit = useCallback(
    async (fd: AuthRegistrationForm) => {
      if (step === AUTH_LOGIN_STEP.phone) {
        try {
          await validatePhone(fd.phoneNumber).unwrap();
          sendCode(fd.phoneNumber);
        } catch (err) {
          const errObject = AuthRegistrationErrorSchema.get(err);
          return { phoneNumber: errObject['value'] };
        }
      }

      if (step === AUTH_LOGIN_STEP.confirmPhone) {
        try {
          await validateCode(fd.code);
          setStep(AUTH_LOGIN_STEP.password);
        } catch (err) {
          const errObject = AuthRegistrationErrorSchema.get(err);
          return { code: errObject['value'] };
        }
      }

      if (step === AUTH_LOGIN_STEP.password) {
        setStep(AUTH_LOGIN_STEP.name);
      }

      if (step === AUTH_LOGIN_STEP.name) {
        setStep(AUTH_LOGIN_STEP.email);
      }

      if (step === AUTH_LOGIN_STEP.email) {
        try {
          await validateEmail(fd.email);
          await submit(fd);
          await login(fd);
        } catch (err) {
          const errObject = AuthRegistrationEmailErrorSchema.get(err);

          return { email: errObject['value'] };
        }
      }
    },
    [step, sendCode, validatePhone, validateCode, validateEmail, submit, login]
  );

  return (
    <AuthForm
      initialValues={{} as AuthRegistrationForm}
      onSubmit={handleSubmit}
      validate={transformValidationSchema(currentSchema)}
      title={step === AUTH_LOGIN_STEP.confirmPhone ? 'Підтвердження номеру' : 'Реєстрація'}
      disabled={!recaptchaLoaded}
      description={
        step === AUTH_LOGIN_STEP.name &&
        'Дякуємо за Ваш інтерес до Смарт Каси! Давайте знайомитись ближче :)'
      }
      footer={
        <ButtonText onClick={() => navigate('/auth/sign-in')} className={styles.link}>
          <FormattedMessage id="auth.buttons.already-registered" />
        </ButtonText>
      }
    >
      {step === AUTH_LOGIN_STEP.phone && (
        <Field.Phone label="Введіть Ваш номер телефону" name="phoneNumber" placeholder="+380" />
      )}

      {step === AUTH_LOGIN_STEP.confirmPhone && (
        <AuthRegistrationPhone
          onDismiss={() => setStep(AUTH_LOGIN_STEP.phone)}
          onRetry={sendCode}
        />
      )}

      {step === AUTH_LOGIN_STEP.password && (
        <>
          <Field.Password label="Введіть Ваш пароль" name="password" />
          <Field.Password label="Повторіть пароль" name="passwordConfirmation" />
        </>
      )}

      {step === AUTH_LOGIN_STEP.name && (
        <>
          <Field.Input label="Ім'я" name="firstName" />
          <Field.Input label="Прізвище" name="lastName" />
        </>
      )}

      {step === AUTH_LOGIN_STEP.email && (
        <Field.Input label="Вкажіть Ваш контактний e-mail" name="email" />
      )}

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        badge="bottomleft"
        size="invisible"
        ref={reCaptchaRef}
        asyncScriptOnLoad={() => setRecaptchaLoaded(true)}
      />
    </AuthForm>
  );
};
