import { useAppSelector } from 'hooks';
import { useGetCategoriesQuery } from 'services/api/core/inventory/api';
import type { ICategory } from 'services/api/core/inventory/types';

import { selectAllCategories } from '../slice';

type UseInventoryCategoriesResult = {
  categories: ICategory[];
  refresh: () => void;
};

export const useInventoryCategories = (): UseInventoryCategoriesResult => {
  const { refetch } = useGetCategoriesQuery();
  const categories = useAppSelector((state) => selectAllCategories(state));

  return { categories, refresh: refetch };
};
