import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, InlineSpace, Title } from '@smart-kasa/ui';

import { Bot } from '../Bot';
import styles from './BotList.module.scss';

const { REACT_APP_TELEGRAM_BOT, REACT_APP_VIBER_BOT } = process.env;

export const BotList = () => (
  <Fragment>
    <Title variant="h3">
      <FormattedMessage id="integrations.platforms" defaultMessage="Платформи:" />
    </Title>

    <div className={styles.list}>
      {REACT_APP_TELEGRAM_BOT && (
        <Bot url={REACT_APP_TELEGRAM_BOT}>
          <Icon name="telegram" />
          <InlineSpace size={5} />
          <FormattedMessage
            id="integrations.connect.telegram"
            defaultMessage="Підключити Telegram-бот"
          />
        </Bot>
      )}

      {REACT_APP_VIBER_BOT && (
        <Bot url={REACT_APP_VIBER_BOT}>
          <Icon name="viber" />
          <InlineSpace size={5} />
          <FormattedMessage id="integrations.connect.viber" defaultMessage="Підключити Viber-бот" />
        </Bot>
      )}
    </div>
  </Fragment>
);
