import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { ITerminal } from './types';

export const posApi = coreApi.enhanceEndpoints({ addTagTypes: ['Terminal'] }).injectEndpoints({
  endpoints: (builder) => ({
    getTerminals: builder.query<CoreResponse<ITerminal[]>, void>({
      query: () => ({ url: '/pos/terminals?skip_pagination=true' }),
      providesTags: ['Terminal'],
    }),
    createTerminal: builder.mutation<CoreResponse<ITerminal>, Partial<ITerminal>>({
      query: (terminal) => ({
        url: '/pos/terminals',
        method: 'POST',
        body: { terminal },
      }),
      invalidatesTags: ['Terminal'],
    }),
    updateTerminal: builder.mutation<void, Pick<ITerminal, 'id'> & Partial<ITerminal>>({
      query: ({ id, ...terminal }) => ({
        url: `/pos/terminals/${id}`,
        method: 'PUT',
        body: { terminal },
      }),
      invalidatesTags: ['Terminal'],
    }),
    deleteTerminal: builder.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/pos/terminals/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Terminal'],
    }),
  }),
});

export const {
  useGetTerminalsQuery,
  useCreateTerminalMutation,
  useUpdateTerminalMutation,
  useDeleteTerminalMutation,
} = posApi;
