import { Badge, Button } from '@smart-kasa/ui';

import { ExternalLink } from 'components';

import Device from 'assets/device_order.png';
import styles from './TerminalOrder.module.scss';

export const TerminalOrder = () => (
  <div className={styles.item}>
    <img src={Device} alt="Придбати Смарт Касу" className={styles.image} />
    <div className={styles.title}>
      Смарт Каса <Badge>NEW</Badge>
    </div>
    <div className={styles.description}>
      Для діючих клієнтів ми маємо постійні вигідні пропозиції на придбання додаткової Смарт Каси
    </div>
    <ExternalLink to="https://www.smartkasa.ua/sale">
      <Button width="full" color="accent">
        Придбати
      </Button>
    </ExternalLink>
  </div>
);
