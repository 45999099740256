import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonIcon, IButtonIconProps } from '@smart-kasa/ui';

interface IButtonIconLinkProps extends IButtonIconProps {
  to: string;
}

export const ButtonIconLink: React.FC<IButtonIconLinkProps> = ({ onClick, to, ...props }) => {
  const navigate = useNavigate();

  return (
    <ButtonIcon
      {...props}
      onClick={(event) => {
        onClick && onClick(event);

        navigate(to);
      }}
    />
  );
};
