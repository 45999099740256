import { CoreResponse } from 'types/api';

import { coreApi } from '../api';
import type { IUnitType } from './types';

export const unitsApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['InventoryUnits'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUnits: builder.query<CoreResponse<IUnitType[]>, void>({
        query: () => ({ url: 'inventory/unit_types' }),
        providesTags: ['InventoryUnits'],
      }),
    }),
  });

export const { useGetUnitsQuery } = unitsApi;
