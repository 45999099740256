import { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import locale from './locale';
import { gradientFillColor } from './utils';

Highcharts.setOptions({
  lang: locale,
});

const createOptions = ({ title, width, height = 350, series }): Highcharts.Options => ({
  colors: ['#57B7DA', '#FE8257', '#29cd6b', '#6D2AD8'],
  accessibility: {
    enabled: false,
  },
  chart: {
    type: 'area',
    spacing: [5, 5, 5, 5],
    width,
    height,
    zoomType: 'x',
    resetZoomButton: {
      position: {
        x: 0,
        y: 0,
      },
      theme: {
        fill: '#fff',
        stroke: '#57B7DA',
        r: 3,
        style: {
          fontWeight: '500',
        },
        states: {
          hover: {
            fill: '#57B7DA',
            style: {
              color: 'white',
            },
          },
        },
      },
    },
    style: {
      fontFamily: 'Rubik, sans-serif',
    },
  },
  title: {
    text: title,
    align: 'left',
    widthAdjust: -300,
    margin: 0,
    y: 25,
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: true,
    useHTML: true,
    align: 'right',
    verticalAlign: 'top',
    borderColor: '#f7f7f7',
    borderRadius: 9,
    borderWidth: 2,
    padding: 15,
    y: -18,
    margin: 30,
    itemStyle: {
      color: '#868a97',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '500',
      textOverflow: 'ellipsis',
    },
  },
  tooltip: {
    enabled: true,
    shared: true,
    valueDecimals: 2,
    backgroundColor: '#ffffff',
    borderColor: '#f7f7f7',
    borderRadius: 10,
    borderWidth: 1,
    padding: 15,
    shadow: {
      width: 3,
      offsetY: 1,
      color: '#000000',
      opacity: 0.025,
    },
  },
  xAxis: {
    type: 'datetime',
    crosshair: true,
    dateTimeLabelFormats: {
      day: '%e %b',
    },
    title: {
      text: '',
    },
    labels: {
      style: {
        fontSize: '12px',
        color: '#8a8e9b',
      },
    },
    lineWidth: 1,
    lineColor: '#eeeff0',
    tickColor: '#eeeff0',
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        fontSize: '12px',
        color: '#8a8e9b',
      },
    },
    gridLineWidth: 0,
    lineColor: '#eeeff0',
    gridLineColor: '#ebecee',
    tickColor: '#f7f7f7',
    lineWidth: 1,
    allowDecimals: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: true,
        lineColor: '#ffffff',
        lineWidth: 2,
        width: 3,
        radius: 3,
      },
    },
    area: {
      fillColor: gradientFillColor('#6D2AD8'),
    },
  },
  series,
});

export interface IAreaChart {
  title?: string;
  width?: number;
  height?: number;
  series: any;
}

export const AreaChart: FC<IAreaChart> = ({ title, width, height, series }) => {
  const options = createOptions({ title, width, height, series });

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
