import { FC, Fragment, forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, IDataTableProps, InlineSpace } from '@smart-kasa/ui';

import type { IProduct } from 'services/api/core/inventory/types';
import { selectProductByIds } from 'features/inventory/slice';

import { ProductTable } from '../ProductTable';
import {
  ProductsBulkDelete,
  ProductsBulkFavorite,
  ProductsBulkFreePrice,
  ProductsBulkMove,
} from '../ProductBulkActions';
import styles from './ProductList.module.scss';

export interface IProductList
  extends Omit<IDataTableProps<IProduct, 'id'>, 'rowIdKey' | 'data' | 'columns'> {
  ref?: any;
  products?: IProduct[];
}

export const ProductList: FC<IProductList> = forwardRef<HTMLDivElement, IProductList>(
  ({ products, isLoading, ...rest }, ref) => {
    const [selected, setSelected] = useState<string[]>([]);
    const navigate = useNavigate();
    const selectedProducts = selectProductByIds(products, selected);
    const onBulkComplete = () => setSelected([]);

    return (
      <Card ref={ref} className={styles.products}>
        <ProductTable
          {...rest}
          isLoading={isLoading}
          selected={selected}
          data={products}
          rowSelection
          onSelectedChange={setSelected}
          onRowClick={(product: IProduct) => navigate(`/inventory/products/${product.id}/edit`)}
          bulkActions={
            <Fragment>
              <ProductsBulkMove products={selectedProducts} onComplete={onBulkComplete} />
              <InlineSpace />
              <ProductsBulkFreePrice products={selectedProducts} onComplete={onBulkComplete} />
              <InlineSpace />
              <ProductsBulkFavorite products={selectedProducts} onComplete={onBulkComplete} />
              <InlineSpace />
              <ProductsBulkDelete products={selectedProducts} onComplete={onBulkComplete} />
            </Fragment>
          }
        />
      </Card>
    );
  }
);
