export enum BillingInvoiceType {
  default = 0,
  subscription = 1,
  regular = 2,
  verify = 10,
  credit = 11,
  debit = 12,
}

export enum BillingInvoiceState {
  waiting = 0,
  paid = 1,
  past_due = 2,
  cancelled = 3,
  draft = 10,
}
