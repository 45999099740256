import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

const staffItem = yup.object().shape({
  employeeId: yup.number().required(),
});

export const ShopStaffFormSchema = yup.object().shape({
  staff: yup.array().of(staffItem),
});

export const ShopStaffErrorSchema = schemaError({
  default: 'Невірний працівник',
  employeeId: {
    default: 'Працівника не знайдено',
    taken: 'Працівник вже доданий',
  },
});
