import { coreApi } from '../api';
import type { IBillingPlan, IBillingSubscription } from './types';
import type { CoreResponse } from 'types/api';

export const billingApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getBillingPlans: builder.query<CoreResponse<IBillingPlan[]>, void>({
      query: () => ({ url: 'store/service_plans' }),
    }),
    currentBillingPlan: builder.query<CoreResponse<IBillingSubscription>, void>({
      query: () => ({ url: 'billing/service_plan' }),
    }),
    activateBillingPlan: builder.mutation<CoreResponse<IBillingSubscription>, number>({
      query: (planId) => ({
        url: 'billing/service_plan',
        method: 'POST',
        body: { plan: { planId } },
      }),
    }),
  }),
});

export const {
  useGetBillingPlansQuery,
  useCurrentBillingPlanQuery,
  useActivateBillingPlanMutation,
} = billingApi;
