import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type {
  IReport,
  ISummaryReport,
  ITaxesReport,
  ReportsListQuery,
  TaxesReportListQuery,
  TaxesReportQuery,
} from './types';

export const reportApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<CoreResponse<IReport[]>, ReportsListQuery>({
      query: (params?) => ({ url: '/reports', params }),
    }),
    getTaxesReport: builder.query<CoreResponse<ITaxesReport[]>, TaxesReportListQuery>({
      query: (params?) => ({ url: '/reports/z-reports', params }),
    }),
    getSummaryReport: builder.query<CoreResponse<ISummaryReport>, TaxesReportQuery>({
      query: (params?) => ({ url: '/reports/z-reports/total', params }),
    }),
  }),
});

export const { useGetReportsQuery, useGetSummaryReportQuery, useGetTaxesReportQuery } = reportApi;
