import { Select } from '@smart-kasa/ui';

import { Field } from 'components';
import { withFinalForm } from 'components/Field/withFinalForm';
import { SelectFieldProps } from 'components/Form/SelectField';
import type { IShop } from 'services/api/core/shop/types';

import { ShopSelectOption } from './ShopSelectOption';

export const ShopSelect = ({ name, label, onChange, ...props }: SelectFieldProps) => {
  const search = ({ data: shop }: { data: IShop }, term: string) => {
    const query = [shop.id, shop.title, shop.address?.content].join();

    return query.toLowerCase().includes(term.toLowerCase());
  };

  return (
    <Field name={name}>
      {({ input, ...rest }) => (
        <Select
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
          formatOptionLabel={(option) => <ShopSelectOption shop={option} />}
          filterOption={search}
          {...input}
          {...rest}
          {...props}
          onChange={(value, option) => {
            input.onChange(value);

            onChange && onChange(value, option);
          }}
        />
      )}
    </Field>
  );
};

export const ShopSelectField = withFinalForm(ShopSelect);
