import { FC, Fragment, ReactNode } from 'react';
import { Form } from 'react-final-form';
import {
  Row,
  Col,
  Strong,
  Hint,
  Collapse,
  CollapseHeader,
  CollapseContent,
  ButtonText,
  InlineSpace,
  Button,
} from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { taxGroupOptions } from 'constants/taxGroup';
import { classifierTypeOptions } from 'constants/classifierType';
import { useGetUnitsQuery } from 'services/api/core/units/api';
import {
  useGetCategoriesQuery,
  useGetSubgroupsQuery,
  useLazySearchCoreProductsQuery,
} from 'services/api/core/inventory/api';
import type { IProduct } from 'services/api/core/inventory/types';

import { ProductFormSchema } from './ProductFormSchema';
import styles from './ProductForm.module.scss';

interface IProductForm {
  initialValues: Partial<IProduct>;
  onCancel?: () => void;
  onRemoveAttachment?: () => void;
  onSubmit: (fd) => void;
  children?: ReactNode;
}

export const ProductForm: FC<IProductForm> = ({
  initialValues,
  onCancel,
  onRemoveAttachment,
  onSubmit,
  children,
}) => {
  const { data: categories } = useGetCategoriesQuery();
  const { data: subgroups } = useGetSubgroupsQuery();
  const { data: units } = useGetUnitsQuery();
  const [search, { data: suggestions, isFetching }] = useLazySearchCoreProductsQuery();

  return (
    <Form<Partial<IProduct>>
      validate={transformValidationSchema(ProductFormSchema)}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={12}>
              <Row gutter={10}>
                <Col span={8}>
                  <Field.FileUploader
                    name="picture"
                    accept=".png, .jpg, .jpeg"
                    icon="camera"
                    className={styles.uploader}
                    onRemove={onRemoveAttachment}
                  />
                </Col>
                <Col span={16}>
                  <Field.AutoSuggest
                    name="number"
                    label="Штрихкод"
                    placeholder="Штрихкод"
                    isLoading={isFetching}
                    suggestions={suggestions?.data || []}
                    onSuggestionsFetchRequested={({ value }) => search(value)}
                    onSuggestionsClearRequested={() => {}}
                    onSuggestionSelected={(_: any, { suggestion }) => {
                      form.batch(() => {
                        form.change('number', suggestion.number);
                        form.change('title', suggestion.title);
                        form.change('alterTitle', suggestion.alterTitle);
                        form.change('alterNumber', suggestion.alterNumber);
                      });
                    }}
                    getSuggestionValue={(suggestion: IProduct) => suggestion.number}
                    renderSuggestion={(suggestion: IProduct) => (
                      <Fragment>
                        {suggestion.number} / {suggestion.title}
                      </Fragment>
                    )}
                  />
                  <Hint>
                    Для автоматичного заповнення даних вкажіть штрихкод товару. Ви також можете
                    заповнити це поле, підключивши сканер.
                  </Hint>
                </Col>
              </Row>
              <Field.Input name="title" label="Назва товару" placeholder="Назва товару" />
              <Field.Input
                name="alterTitle"
                label="Коротка назва товару"
                placeholder="Коротка назва товару"
              />
              <Row gutter={10}>
                <Col span={12} className={styles.cost}>
                  <Field.Price placeholder="0" name="price" label="Ціна продажу" />
                </Col>
                <Col span={12}>
                  <Field.Price placeholder="0" name="procurementPrice" label="Ціна закупки" />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={10}>
                <Col span={12}>
                  <Field.Input name="remoteId" label="Код" placeholder="Код" />
                </Col>
                <Col span={12}>
                  <Field.Input name="alterNumber" label="Артикул" placeholder="Артикул" />
                </Col>
              </Row>
              <Field.Select
                name="categoryId"
                label="Каталог"
                placeholder="Оберіть зі списку"
                options={categories?.data}
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
              />
              <Field.Select
                name="unitTypeId"
                label="Одиниці виміру"
                isClearable
                placeholder="Оберіть зі списку"
                options={units?.data}
                getOptionLabel={(option) => option?.shortName}
                getOptionValue={(option) => option?.code}
              />
              <Field.TextArea
                name="description"
                label="Опис"
                placeholder="Опис товару"
                className={styles.textareaField}
              />
              <Field.Switch name="isFreePrice" label="Дозволити продаж за вільною ціною" />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <Collapse theme="transparent" active={true}>
                <CollapseHeader>
                  <Strong>Група оподаткування</Strong>
                  <Hint>
                    Необхідно вказати інформацію про класифікацію продукції/послуги та її групу
                    оподаткування
                  </Hint>
                </CollapseHeader>

                <CollapseContent>
                  <Row gutter={10} offset="between">
                    <Col span={12}>
                      <Field.Select
                        name="taxGroupId"
                        label="Група оподаткування"
                        placeholder="Оберіть зі списку"
                        options={taxGroupOptions}
                      />
                      <Field.Select
                        name="subgroupId"
                        label="Товарна підгрупа"
                        placeholder="Оберіть зі списку"
                        options={subgroups?.data}
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                      />
                    </Col>
                    <Col span={12}>
                      <Field.Select
                        name="classifierTypeId"
                        label="Тип класифікатора"
                        placeholder="Оберіть зі списку"
                        options={classifierTypeOptions}
                      />
                      <Field.Input name="classifierCode" label="Код для класифікації" />
                    </Col>
                  </Row>
                </CollapseContent>
              </Collapse>
            </Col>
          </Row>

          <div className={styles.container}>
            {children}

            <div className={styles.actions}>
              <ButtonText onClick={onCancel}>Скасувати</ButtonText>
              <InlineSpace />
              <Button color="primary" type="submit">
                Зберегти
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
