import React, { ReactNode } from 'react';
import styles from './ErrorView.module.scss';
import img from './img.svg';
import { Button } from '@smart-kasa/ui';

interface IErrorView {
  children?: ReactNode;
  isShowIcon?: boolean;
  isShowReload?: boolean;
}

export const ErrorView = ({
  children = 'Хьюстон, у нас проблема',
  isShowIcon = true,
  isShowReload = true,
}: IErrorView) => {
  return (
    <div className={styles.content}>
      {isShowIcon && <img src={img} alt="" />}
      <div className={styles.text}>{children}</div>
      {isShowReload && (
        <Button color="primary" className={styles.button} onClick={() => window.location.reload()}>
          Перезавантажити
        </Button>
      )}
    </div>
  );
};
