import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';
import { schemaAddress, schemaPhone } from 'utils/sharedSchemas';

export const ShopSchema = yup.object().shape({
  title: yup.string().required(),
  address: schemaAddress,
  taxpayerName: yup.string().required(),
  taxpayerNumber: yup.string().nullable().required(),
  phoneNumber: schemaPhone.nullable(),
  email: yup.string().email().nullable(),
});

export const ShopErrorSchema = schemaError({
  default: 'Виникла помилка',
});
