import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  ButtonIcon,
  DataTable,
  IDataTableColumn,
  Icon,
  Pagination,
  Strong,
  Tag,
  UAH,
} from '@smart-kasa/ui';

import { useQueryPagination } from 'hooks/useQueryPagination';
import { useQuery } from 'hooks/useQuery';
import { useGetInvoicesQuery } from 'services/api/core/invoices/api';
import { IBillingInvoice } from 'services/api/core/invoices/types';

import { BILLING_INVOICE_STATUS } from 'features/billing/constants/invoiceStatus';
import { INVOICE_TITLE } from 'features/billing/constants/invoiceTitle';

export const BillingHistory = () => {
  const { query } = useQuery();
  const navigate = useNavigate();
  const { formatMessage, formatDate } = useIntl();
  const { onPageChange, onSortChange } = useQueryPagination();
  const { data: invoices, isLoading } = useGetInvoicesQuery({
    sortMode: 'desc',
    sortColumn: 'id',
    ...query,
  });

  const columns: IDataTableColumn<IBillingInvoice>[] = [
    {
      key: 'number',
      title: formatMessage({ id: 'billing.invoice.id', defaultMessage: 'Номер' }),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'billing.invoice.state', defaultMessage: 'Статус' }),
      render: (state) => (
        <Tag color={BILLING_INVOICE_STATUS[state].color}>{BILLING_INVOICE_STATUS[state].title}</Tag>
      ),
    },
    {
      key: 'invoiceTypeId',
      title: formatMessage({ id: 'billing.invoice.type', defaultMessage: 'Тип' }),
      sort: true,
      render: (invoiceTypeId) => INVOICE_TITLE[invoiceTypeId],
    },
    {
      key: 'amount',
      title: formatMessage({ id: 'billing.invoice.amount', defaultMessage: 'Сума транзакції' }),
      render: (amount) => (
        <Strong>
          <UAH value={amount} />
        </Strong>
      ),
    },
    {
      key: 'paidAt',
      title: formatMessage({ id: 'billing.invoice.paid-at', defaultMessage: 'Дата сплати' }),
      sort: true,
      render: (paidAt) =>
        paidAt ? formatDate(paidAt, { dateStyle: 'short', timeStyle: 'short' }) : '-',
    },
    {
      key: 'id',
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Fragment>
      <DataTable
        rowIdKey="id"
        data={invoices?.data}
        columns={columns}
        emptyText={formatMessage({
          id: 'billing.invoices.empty',
          defaultMessage: 'Історія платежів відсутня',
        })}
        onRowClick={(invoice: IBillingInvoice) => navigate(`/billing/invoice/${invoice.id}`)}
        isLoading={isLoading}
        sortMode={query.sortMode}
        sortColumn={query.sortColumn}
        onSortChange={onSortChange}
      />

      <Pagination
        totalItems={invoices?.meta?.totalCount}
        currentPage={invoices?.meta?.currentPage}
        perPage={invoices?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
