import { useCallback, useContext } from 'react';
import { DropDown, Row } from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { WayForPayContext } from 'context/WayForPayContext';

import type { IBillingCard } from 'services/api/core/wallet/types';
import {
  useGetCreditCardsQuery,
  useLazyAddCreditCardQuery,
  useUpdateCreditCardMutation,
  useDeleteCreditCardMutation,
} from 'services/api/core/wallet/api';
import {
  BillingCreditCard,
  BillingCreditCardAdd,
  BillingCreditCardPreloader,
} from 'features/billing/components/BillingCreditCard';
import { BillingInvoiceAdd } from 'features/billing/components/BillingInvoiceAdd';
import styles from './BillingCreditCards.module.scss';

export const BillingCreditCards = () => {
  const { data: cards, isLoading } = useGetCreditCardsQuery();
  const { isSuccessful } = useContext(WayForPayContext);
  const { toast } = useToast();

  const [createCard] = useLazyAddCreditCardQuery();
  const [updateCard] = useUpdateCreditCardMutation();
  const [deleteCard] = useDeleteCreditCardMutation();

  const handleAdd = useCallback(async () => {
    const { data: invoice } = await createCard();
    const wayforpay = new window.Wayforpay();

    wayforpay.run(
      invoice?.data?.wayforpay,
      () => toast.success('Картку верифіковано'),
      () => toast.error('Не вдалось верифікувати картку'),
      () =>
        toast.info(
          "Додання платіжної картки обробляється. Після успішної верифікації картка з'явиться в розділі 'Оплата'"
        )
    );
  }, [createCard, toast]);

  return (
    <Row className={styles.list}>
      {isLoading ? (
        <BillingCreditCardPreloader className={styles.card} />
      ) : (
        <>
          {cards?.data.map((card) => (
            <BillingCreditCard
              key={card.id}
              card={card}
              className={styles.card}
              actions={(card: IBillingCard) => (
                <DropDown>
                  <div className={styles.actions}>...</div>
                  <DropDown.Menu>
                    <DropDown.Item onClick={() => updateCard({ id: card.id, isPrimary: true })}>
                      Зробити основною
                    </DropDown.Item>
                    <DropDown.Item onClick={() => deleteCard(card.id)}>Видалити</DropDown.Item>
                  </DropDown.Menu>
                </DropDown>
              )}
            />
          ))}

          {isSuccessful && <BillingCreditCardAdd className={styles.card} onClick={handleAdd} />}

          <BillingInvoiceAdd
            className={styles.card}
            href="https://smartkasa.ua/billing/"
            target="_blank"
            rel="noreferrer"
          />
        </>
      )}
    </Row>
  );
};
