import cs from 'classnames';
import { DataTable, IDataTableColumn, Strong, UAH } from '@smart-kasa/ui';

import type { IProduct } from 'services/api/core/inventory/types';

import { ProductItem } from '../ProductItem';
import styles from './ProductTable.module.scss';

export const ProductTable = ({ isLoading, selected, bulkActions, ...rest }) => {
  const columns: IDataTableColumn<IProduct>[] = [
    {
      key: 'title',
      sort: !selected.length,
      title: selected.length > 0 && bulkActions ? bulkActions : 'Назва',
      render: (_, product) => <ProductItem product={product} />,
    },
    {
      key: 'alterNumber',
      title: 'Артикул',
      sort: true,
    },
    {
      key: 'unitTypeName',
      sort: true,
      title: 'Од.',
    },
    {
      key: 'price',
      sort: true,
      title: 'Ціна за од.',
      render: (value) => (
        <Strong>
          <UAH value={value} />
        </Strong>
      ),
    },
  ];

  return (
    <DataTable<IProduct, 'id'>
      rowIdKey="id"
      columns={columns}
      selected={selected}
      className={cs(styles.table, { [styles.loading]: isLoading })}
      emptyText={!isLoading && 'Товари та послуги відсутні'}
      {...rest}
    />
  );
};
