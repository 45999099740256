import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Preloader } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import {
  useFindEmployeeQuery,
  useUpdateEmployeeMutation,
  useDeleteEmployeeAvatarMutation,
} from 'services/api/core/employees/api';

import { EmployeeFormErrorSchema } from 'features/employees/components/EmployeeForm/EmployeeFormSchema';
import { EmployeeForm } from 'features/employees/components/EmployeeForm';

export const EmployeeEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [update] = useUpdateEmployeeMutation();
  const [destroyAttachment] = useDeleteEmployeeAvatarMutation();
  const { employee, isLoading } = useFindEmployeeQuery(Number(id), {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, employee: response?.data }),
  });

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    async (employee) =>
      update({ ...employee, birthday: new Date(employee.birthday) })
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return EmployeeFormErrorSchema.get(err);
        }),
    [update, navigate]
  );

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          {employee?.firstName} {employee?.lastName}
        </InlineLink>
      }
    >
      {!employee || isLoading ? (
        <Preloader />
      ) : (
        <EmployeeForm
          initialValues={employee}
          onDismiss={handleDismiss}
          onRemoveAttachment={() => destroyAttachment(Number(id))}
          onSubmit={handleSubmit}
        />
      )}
    </Page>
  );
};
