import { Fragment, useState } from 'react';
import { useField } from 'react-final-form';
import useInterval from 'react-use/lib/useInterval';
import { ButtonText } from '@smart-kasa/ui';

import { Field } from 'components';
import styles from './AuthRegistration.module.scss';

interface IAuthRegistrationPhone {
  onRetry: (phone: string) => void;
  onDismiss?: () => void;
}

const INITIAL_TIMER = 60;

export const AuthRegistrationPhone = ({ onDismiss, onRetry }: IAuthRegistrationPhone) => {
  const {
    input: { value: phone },
  } = useField('phoneNumber');

  const [isShowTimer, setIsShowTimer] = useState(true);
  const [timer, setTimer] = useState(INITIAL_TIMER);

  useInterval(
    () => {
      if (timer === 0) {
        setIsShowTimer(false);
        setTimer(INITIAL_TIMER);
      } else {
        setTimer(timer - 1);
      }
    },
    isShowTimer ? 1000 : null
  );

  return (
    <Fragment>
      <div className={styles.field}>
        <Field.Input
          label={`Введіть код, відправлений на ${phone}`}
          name="code"
          placeholder="XXXXXX"
        />

        {isShowTimer && (
          <div className={styles.timer}>Повторно відправити код можна буде через {timer} сек.</div>
        )}
      </div>

      <div className={styles.link}>
        <ButtonText
          onClick={() => {
            setIsShowTimer(true);
            onDismiss && onDismiss();
          }}
        >
          Ввести інший номер
        </ButtonText>
      </div>

      {!isShowTimer && <ButtonText onClick={() => onRetry(phone)}>Отримати новий код</ButtonText>}
    </Fragment>
  );
};
