import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TabsNavigation, TabsNavigationItem } from '@smart-kasa/ui';

interface IReportNavigationTab {
  path: string;
  title: string;
}

export const ReportNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const navigation: IReportNavigationTab[] = [
    {
      path: '/reports',
      title: formatMessage({ id: 'navigation.reports.list', defaultMessage: 'Список звітів' }),
    },
    {
      path: '/reports/summary',
      title: formatMessage({ id: 'navigation.reports.summary', defaultMessage: 'Зведений звіт' }),
    },
  ];

  return (
    <TabsNavigation>
      {navigation.map(({ path, title }) => (
        <TabsNavigationItem
          key={title}
          onClick={() => navigate(path)}
          isActive={location.pathname === path}
        >
          {title}
        </TabsNavigationItem>
      ))}
    </TabsNavigation>
  );
};
