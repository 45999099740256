import { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ButtonText, InlineSpace } from '@smart-kasa/ui';

import { Field } from 'components';
import { SessionContext } from 'context/SessionContext';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { AuthForm } from 'features/auth/components/AuthForm';
import { AuthLoginErrorSchema, AuthLoginSchema } from './AuthLoginSchema';

export type AuthLoginForm = {
  phoneNumber: string;
  password: string;
};

export const AuthLogin = () => {
  const { login } = useContext(SessionContext);
  const navigate = useNavigate();
  const handleSubmit = async (values: AuthLoginForm) => {
    return await login(values)
      .unwrap()
      .catch((err) => AuthLoginErrorSchema.get(err));
  };

  return (
    <AuthForm
      title="Вхід"
      initialValues={{} as AuthLoginForm}
      onSubmit={handleSubmit}
      validate={transformValidationSchema(AuthLoginSchema)}
      footer={
        <Fragment>
          <ButtonText onClick={() => navigate('/auth/sign-up')}>
            <FormattedMessage id="auth.buttons.create-account" />
          </ButtonText>
          <InlineSpace direction="vertical" />
          <ButtonText onClick={() => navigate('/auth/password-recovery')}>
            <FormattedMessage id="auth.buttons.forgot-password" />
          </ButtonText>
        </Fragment>
      }
    >
      <Field.Phone label="Введіть Ваш номер телефону" name="phoneNumber" placeholder="+380" />
      <Field.Password label="Введіть Ваш пароль" name="password" />
    </AuthForm>
  );
};
