import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type {
  IAMChangePassword,
  IAMChangePinCode,
  IAMProfile,
  IAMProfileUpdateFormData,
} from './types';

export const iamApi = coreApi.enhanceEndpoints({ addTagTypes: ['User'] }).injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<CoreResponse<IAMProfile>, void>({
      query: () => ({ url: 'iam/profile' }),
      providesTags: ['User'],
    }),
    updateProfile: builder.mutation<CoreResponse<IAMProfile>, IAMProfileUpdateFormData>({
      query: (profile) => ({
        url: '/iam/profile',
        method: 'PUT',
        body: { profile },
      }),
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation<CoreResponse<IAMProfile>, IAMChangePassword>({
      query: (profile) => ({
        url: '/iam/password',
        method: 'PUT',
        body: { profile },
      }),
      invalidatesTags: ['User'],
    }),
    changePinCode: builder.mutation<CoreResponse<IAMProfile>, IAMChangePinCode>({
      query: (profile) => ({
        url: '/iam/profile/pin_code',
        method: 'PUT',
        body: { profile },
      }),
      invalidatesTags: ['User'],
    }),
    deleteProfilePhoto: builder.mutation<{ success: boolean; id: number }, void>({
      query: () => ({
        url: '/iam/profile/avatar',
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useChangePinCodeMutation,
  useDeleteProfilePhotoMutation,
} = iamApi;
