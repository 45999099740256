import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const ImportCatalogSchema = yup.object().shape({
  categoryId: yup.string().required(),
  attachment: yup.mixed().required(),
});

export const ImportCatalogErrorSchema = schemaError({
  default: 'Невірний імпорт',
  categoryId: {
    default: 'Категорія не вибрана.',
  },
});
