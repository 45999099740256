import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  DataTable,
  IDataTableColumn,
  Pagination,
  Tag,
  Icon,
  Hint,
  Button,
  Page,
  ButtonIcon,
} from '@smart-kasa/ui';

import { useQueryPagination, useQuery } from 'hooks';
import useModal from 'hooks/useModal';
import { useGetShiftsQuery } from 'services/api/core/shifts/api';
import { ShiftState } from 'services/api/core/shifts/enums';
import type { IShift } from 'services/api/core/shifts/types';

import { ShiftFilter } from 'features/shifts/components/ShiftFilter';
import { ShiftStatus } from 'features/shifts/components/ShiftStatus';

export const ShiftList = () => {
  const navigate = useNavigate();
  const { query, push: setQuery } = useQuery();
  const { onPageChange, onSortChange } = useQueryPagination();
  const { isShowing, showModal, hideModal } = useModal();
  const { data: shifts, isLoading } = useGetShiftsQuery({
    sortColumn: 'opened_at',
    sortMode: 'desc',
    ...query,
  });

  const columns: IDataTableColumn<IShift>[] = [
    {
      key: 'registrarFiscalNumber',
      sort: true,
      title: 'ФН документу/пРРО',
      render: (registrarFiscalNumber, { fiscalNumber }) => (
        <Fragment>
          {fiscalNumber ? (
            <Fragment>
              <Tag color="violet">Фіскальна зміна</Tag>
              <Hint>
                {fiscalNumber} / {registrarFiscalNumber}
              </Hint>
            </Fragment>
          ) : (
            <Tag color="green">Торгова зміна</Tag>
          )}
        </Fragment>
      ),
    },
    {
      key: 'openedAt',
      sort: true,
      title: 'Зміну відкрито',
      render: (openedAt, { openedBy }) => (
        <Fragment>
          {openedBy}

          <Hint margin={0}>
            {openedAt && <FormattedDate value={openedAt} dateStyle="short" timeStyle="short" />}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'closedAt',
      sort: true,
      title: 'Зміну закрито',
      render: (closedAt, { closedBy }) => (
        <Fragment>
          {closedBy || '-'}

          <Hint margin={0}>
            {closedAt && <FormattedDate value={closedAt} dateStyle="short" timeStyle="short" />}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'state',
      sort: true,
      title: 'Статус',
      render: (state: ShiftState) => <ShiftStatus state={state} />,
    },
    {
      key: 'deviceUid',
      sort: 'terminalId',
      title: 'Ідентифікатор пристрою',
    },
    {
      key: 'id',
      render: (id: number) => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Page
      title="Зміни"
      actions={
        <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
          Фільтр
        </Button>
      }
    >
      <DataTable
        rowIdKey="id"
        data={shifts?.data}
        columns={columns}
        isLoading={isLoading}
        sortMode={query.sortMode}
        sortColumn={query.sortColumn}
        onSortChange={onSortChange}
        onRowClick={(shift) => navigate(`/shifts/${shift.id}`)}
      />
      <Pagination
        totalItems={shifts?.meta?.totalCount}
        currentPage={shifts?.meta?.currentPage}
        perPage={shifts?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <ShiftFilter show={isShowing} onClose={hideModal} initialValues={query} onSubmit={setQuery} />
    </Page>
  );
};
