import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ButtonText } from '@smart-kasa/ui';

import { Field } from 'components';
import { AuthForm } from 'features/auth/components/AuthForm';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import {
  AuthRecoveryCodeSchema,
  AuthRecoveryPasswordSchema,
  AuthRecoveryPhoneSchema,
} from './AuthRecoverySchema';
import { AuthRegistrationPhone } from '../AuthRegistration/AuthRegistrationPhone';
import styles from './AuthRecovery.module.scss';
import {
  useRequestPasswordChangeMutation,
  useResetPasswordMutation,
} from 'services/api/core/auth/api';

enum AUTH_RECOVERY_STEP {
  phone,
  confirmPhone,
  password,
}

const SCHEMA_BY_STEP = {
  [AUTH_RECOVERY_STEP.phone]: AuthRecoveryPhoneSchema,
  [AUTH_RECOVERY_STEP.confirmPhone]: AuthRecoveryCodeSchema,
  [AUTH_RECOVERY_STEP.password]: AuthRecoveryPasswordSchema,
};

export type AuthRecoveryForm = {
  phoneNumber: string;
  newPassword: string;
  code: string;
  newPasswordConfirmation: string;
};

export const AuthRecovery = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<AUTH_RECOVERY_STEP>(AUTH_RECOVERY_STEP.phone);
  const [requestPasswordChange] = useRequestPasswordChangeMutation();
  const [resetPassword] = useResetPasswordMutation();

  const [publicToken, setPublicToken] = useState<string>('');

  const sendCode = useCallback(
    async (phone: string) => {
      try {
        const { data } = await requestPasswordChange(phone).unwrap();

        setPublicToken(data.publicToken);
        setStep(AUTH_RECOVERY_STEP.confirmPhone);
      } catch (err) {
        return {
          phoneNumber:
            'Такий номер не зареєстровано. Перевірте коректніть вводу або зареєструйте особистий кабінет.',
        };
      }
    },
    [requestPasswordChange]
  );

  const handleResetPassword = useCallback(
    async ({ phoneNumber, ...fd }: AuthRecoveryForm) => {
      try {
        await resetPassword({ token: publicToken, ...fd });

        navigate('/auth/sign-in');
      } catch (err) {
        return {
          newPassword:
            'Перевірте коректність вводу або зареєструйте особистий кабінет просто зараз.',
        };
      }
    },
    [publicToken, resetPassword, navigate]
  );

  const currentSchema = SCHEMA_BY_STEP[step];

  const handleSubmit = useCallback(
    async (fd: AuthRecoveryForm) => {
      if (step === AUTH_RECOVERY_STEP.phone) {
        try {
          return await sendCode(fd.phoneNumber);
        } catch (err) {
          return {
            phoneNumber:
              'Номер не знайдено. Перевірте коректність вводу або зареєструйте особистий кабінет просто зараз.',
          };
        }
      }

      if (step === AUTH_RECOVERY_STEP.confirmPhone) {
        setStep(AUTH_RECOVERY_STEP.password);
      }

      if (step === AUTH_RECOVERY_STEP.password) {
        return await handleResetPassword(fd);
      }
    },
    [step, sendCode, handleResetPassword]
  );

  return (
    <AuthForm
      title="Відновлення пароля"
      initialValues={{} as AuthRecoveryForm}
      onSubmit={handleSubmit}
      validate={transformValidationSchema(currentSchema)}
      footer={
        <ButtonText onClick={() => navigate('/auth/sign-in')} className={styles.link}>
          <FormattedMessage id="auth.buttons.cancel" />
        </ButtonText>
      }
    >
      {step === AUTH_RECOVERY_STEP.phone && (
        <Field.Phone label="Введіть Ваш номер телефону" name="phoneNumber" placeholder="+380" />
      )}

      {step === AUTH_RECOVERY_STEP.confirmPhone && (
        <AuthRegistrationPhone
          onDismiss={() => setStep(AUTH_RECOVERY_STEP.phone)}
          onRetry={sendCode}
        />
      )}

      {step === AUTH_RECOVERY_STEP.password && (
        <>
          <Field.Password label="Введіть Ваш пароль" name="newPassword" />
          <Field.Password label="Повторіть пароль" name="newPasswordConfirmation" />
        </>
      )}
    </AuthForm>
  );
};
