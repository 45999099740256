import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog, Strong } from '@smart-kasa/ui';

import { useDeleteSubgroupMutation } from 'services/api/core/inventory/api';

export const SubgroupDelete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [destroy] = useDeleteSubgroupMutation();

  const handleDelete = useCallback(() => {
    destroy(Number(id))
      .unwrap()
      .then(() => navigate(-1));
  }, [id, destroy, navigate]);

  return (
    <ConfirmDialog onClose={() => navigate(-1)} onConfirm={handleDelete}>
      <Strong>Ви дійсно бажаєте видалити підгрупу?</Strong>
    </ConfirmDialog>
  );
};
