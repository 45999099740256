import { FormattedDate } from 'react-intl';
import { Hint, Paragraph, Strong } from '@smart-kasa/ui';

import { IBillingSubscription } from 'services/api/core/billing/types';

import styles from './Subscription.module.scss';

export type SubscriptionProps = {
  subscription: IBillingSubscription;
  children?: never;
};

export const Subscription = ({ subscription }: SubscriptionProps) => (
  <div className={styles.subscription}>
    <Paragraph>
      Тарифний план: <Strong>{subscription.plan?.title}</Strong>
    </Paragraph>
    <Hint margin={0} className={styles.date}>
      Активний до: <FormattedDate value={subscription.activeUntil} dateStyle="short" />
    </Hint>
  </div>
);
