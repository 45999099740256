import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';
import { Page, DataTable, IDataTableColumn, Icon, InlineSpace } from '@smart-kasa/ui';

import { ButtonLink, ButtonIconLink } from 'components';
import { useGetSubgroupsQuery } from 'services/api/core/inventory/api';
import type { ISubgroup } from 'services/api/core/inventory/types';

export const SubgroupList = () => {
  const navigate = useNavigate();
  const { data: subgroups, isLoading } = useGetSubgroupsQuery();

  const columns: IDataTableColumn<ISubgroup>[] = [
    {
      key: 'id',
      title: 'Номер',
    },
    {
      key: 'title',
      title: 'Назва',
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (createdAt) => (
        <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'updatedAt',
      title: 'Дата останніх змін',
      render: (updatedAt) => (
        <FormattedDate value={updatedAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      render: (id: number) => (
        <Fragment>
          <ButtonIconLink
            to={`/inventory/subgroups/${id}/edit`}
            onClick={(event) => event.stopPropagation()}
          >
            <Icon size="large" name="edit" />
          </ButtonIconLink>
          <InlineSpace />
          <ButtonIconLink
            to={`/inventory/subgroups/${id}/delete`}
            onClick={(event) => event.stopPropagation()}
          >
            <Icon size="large" name="trash" />
          </ButtonIconLink>
        </Fragment>
      ),
    },
  ];

  return (
    <Page
      title="Товарні підгрупи"
      actions={
        <ButtonLink to="create" color="primary" rightIcon={<Icon name="add" size="small" />}>
          Додати
        </ButtonLink>
      }
    >
      <DataTable
        rowIdKey="id"
        data={subgroups?.data}
        columns={columns}
        isLoading={isLoading}
        emptyText="Товарні підгрупи відсутні"
        onRowClick={(subgroup) => navigate(`/inventory/subgroups/${subgroup.id}/edit`)}
      />

      <Outlet />
    </Page>
  );
};
