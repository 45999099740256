import { coreApi } from '../api';
import type { CoreResponse } from 'types';
import type {
  IPaymentTransaction,
  IPaymentTransactionStats,
  IReportPaymentTransactions,
  PaymentTransactionListQuery,
} from './types';

export const transactionApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<
      CoreResponse<IPaymentTransaction[]>,
      PaymentTransactionListQuery
    >({
      query: (params) => ({ url: '/reports/transactions', params }),
    }),
    findTransaction: builder.query<CoreResponse<IPaymentTransaction>, string>({
      query: (id) => ({ url: `/pos/payment_transactions/${id}` }),
    }),
    getTransactionSales: builder.query<
      CoreResponse<IReportPaymentTransactions>,
      PaymentTransactionListQuery
    >({
      query: (params) => ({ url: '/reports/transactions/charts', params }),
    }),
    getTransactionStats: builder.query<CoreResponse<IPaymentTransactionStats>, void>({
      query: () => ({ url: '/reports/transactions/stats' }),
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useFindTransactionQuery,
  useGetTransactionSalesQuery,
  useGetTransactionStatsQuery,
} = transactionApi;
