import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const ImportStockSchema = yup.object().shape({
  attachment: yup.mixed().required(),
});

export const ImportStockErrorSchema = schemaError({
  default: 'Невірний імпорт',
});
