import * as yup from 'yup';

export const CategoryFormSchema = yup.object().shape({
  title: yup.string().required(),
  shopIds: yup
    .array()
    .of(yup.string())
    .when('parentId', (value, schema) =>
      value
        ? schema
        : schema.min(1, "Необхідно обрати торгові точки до яких буде підв'язано каталог")
    ),
});
