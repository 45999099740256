import { Input, PriceInput, NumberInput, Textarea, SpinnerInput, TagsInput } from '@smart-kasa/ui';

import { PhoneInput } from 'components/PhoneInput';
import { PasswordInput } from 'components/PasswordInput';

import FormField from './Field';
import { withFinalForm } from './/withFinalForm';
import { SelectField } from '../Form/SelectField';
import { AsyncSelectField } from '../Form/AsyncSelectField';
import { ColorSelectField } from '../Form/ColorSelectField';
import { RadioSwitchField } from '../Form/RadioSwitchField';
import { PinField } from '../Form/PinField';
import { AutoSuggestField } from '../Form/AutoSuggestField';
import { DatepickerField } from '../Form/DatepickerField';
import { FileUploaderField } from '../Form/FileUploaderField';
import { SwitchField } from '../Form/SwitchField';
import { CheckboxField } from '../Form/CheckboxField';
import { SwitchFieldArray } from '../Form/SwitchFieldArray';
import { FileField } from '../Form/FileField';

export type FieldType = any;

export const Field = FormField as FieldType;

Field.Input = withFinalForm(Input);
Field.TextArea = withFinalForm(Textarea);
Field.Checkbox = withFinalForm(CheckboxField);
Field.Password = withFinalForm(PasswordInput);
Field.Phone = withFinalForm(PhoneInput);
Field.Price = withFinalForm(PriceInput);
Field.Tags = withFinalForm(TagsInput);
Field.Spinner = withFinalForm(SpinnerInput);
Field.Number = withFinalForm(NumberInput);
Field.Select = withFinalForm(SelectField);
Field.AsyncSelect = withFinalForm(AsyncSelectField);
Field.ColorSelect = withFinalForm(ColorSelectField);
Field.ButtonSet = withFinalForm(RadioSwitchField);
Field.AutoSuggest = withFinalForm(AutoSuggestField);
Field.PinCode = withFinalForm(PinField);
Field.Datepicker = withFinalForm(DatepickerField);
Field.FileUploader = withFinalForm(FileUploaderField);
Field.Switch = withFinalForm(SwitchField);
Field.SwitchArray = withFinalForm(SwitchFieldArray);
Field.File = withFinalForm(FileField);
