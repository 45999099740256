import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';

import { SessionContext } from 'context/SessionContext';
import { PrivateContext } from './PrivateContext';

export type PrivateRouteProps = {
  children?: any;
};

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isLogged } = useContext(SessionContext);
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
  }

  return <PrivateContext>{children}</PrivateContext>;
};
