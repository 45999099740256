import { Fragment, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CardTitle,
  Col,
  InlineSpace,
  PageHeader,
  Paragraph,
  Preloader,
  Row,
} from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { useGetSettingsQuery, useUpdateSettingsMutation } from 'services/api/core/settings/api';

import { NotificationSettingsForm } from './components/NotificationSettingsForm';
import { ChannelList } from './components/ChannelList';
import { BotList } from './components/BotList';

export const Notifications = () => {
  const { toast } = useToast();
  const { data: settings, isLoading } = useGetSettingsQuery();
  const [update] = useUpdateSettingsMutation();

  const handleSubmit = useCallback(
    async (fd) =>
      await update(fd)
        .unwrap()
        .then(() => toast.success('Збережено'))
        .catch(() => toast.error('Не вдалось оновити налаштування')),
    [update, toast]
  );

  return (
    <Fragment>
      <PageHeader>
        <FormattedMessage id="notifications.title" defaultMessage="Налаштування сповіщень" />
      </PageHeader>

      <Row gutter={10}>
        <Col span={12}>
          <Card>
            <CardTitle>
              <FormattedMessage id="notifications.settings" defaultMessage="Сповіщення" />
            </CardTitle>

            {!settings || isLoading ? (
              <Preloader />
            ) : (
              <NotificationSettingsForm initialValues={settings?.data} onSubmit={handleSubmit} />
            )}
          </Card>
        </Col>

        <Col span={12}>
          <Card>
            <CardTitle>
              <FormattedMessage id="integrations.title" defaultMessage="Боти для сповіщень" />
            </CardTitle>
            <Paragraph>
              <FormattedMessage
                id="integrations.description"
                defaultMessage="Отримуйте сповіщення про термін дії вашого тарифного плану, дані по звітам та іншу
              корисну інформацію від Смарт Каса у ваш месенджер"
              />
            </Paragraph>
            <InlineSpace direction="vertical" size={15} />
            <BotList />
            <InlineSpace direction="vertical" size={15} />
            <ChannelList />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
