import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { ICustomer, IReceipt, ReceiptFormData, ReceiptListQuery } from './types';

export const receiptApi = coreApi.enhanceEndpoints({ addTagTypes: ['Receipt'] }).injectEndpoints({
  endpoints: (builder) => ({
    getReceipts: builder.query<CoreResponse<IReceipt[]>, ReceiptListQuery>({
      query: (params?) => ({ url: '/pos/receipts', params }),
      providesTags: ['Receipt'],
    }),
    findReceipt: builder.query<CoreResponse<IReceipt>, string>({
      query: (id) => ({ url: `/pos/receipts/${id}` }),
      providesTags: ['Receipt'],
    }),
    createReceipt: builder.mutation<CoreResponse<IReceipt>, ReceiptFormData>({
      query: (receipt) => ({
        url: '/pos/receipts',
        method: 'POST',
        body: { receipt },
      }),
      invalidatesTags: ['Receipt'],
    }),
    updateReceipt: builder.mutation<void, Pick<IReceipt, 'id'> & ReceiptFormData>({
      query: ({ id, ...receipt }) => ({
        url: `/pos/receipts/${id}`,
        method: 'PUT',
        body: { receipt },
      }),
      invalidatesTags: ['Receipt'],
    }),
    deleteReceipt: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/pos/receipts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Receipt'],
    }),
    sendReceiptToEmail: builder.mutation<
      CoreResponse<ICustomer>,
      Pick<IReceipt, 'id'> & Pick<ICustomer, 'email'>
    >({
      query: ({ id, ...customer }) => ({
        url: `/pos/receipts/${id}/deliveries`,
        method: 'POST',
        body: { customer },
      }),
      invalidatesTags: ['Receipt'],
    }),
  }),
});

export const {
  useGetReceiptsQuery,
  useFindReceiptQuery,
  useCreateReceiptMutation,
  useUpdateReceiptMutation,
  useDeleteReceiptMutation,
  useSendReceiptToEmailMutation,
} = receiptApi;
