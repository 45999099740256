import { Route, Routes } from 'react-router-dom';

import { StockList } from './routes/StockList';
import { StockCreate } from './routes/StockCreate';
import { StockEdit } from './routes/StockEdit';

export const Stocks = () => (
  <Routes>
    <Route index element={<StockList />} />
    <Route path="create" element={<StockCreate />} />
    <Route path=":id/edit" element={<StockEdit />} />
  </Routes>
);
