import { HTMLAttributes } from 'react';
import cs from 'classnames';

import styles from './ReceiptForm.module.scss';

export const ReceiptItemDelete = ({ className, ...rest }: HTMLAttributes<HTMLSpanElement>) => (
  <span className={cs(styles.delete, className)} {...rest}>
    -
  </span>
);
