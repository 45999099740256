import { Route, Routes } from 'react-router-dom';

import { WayForPayContextProvider } from 'context/WayForPayContext';

import { BillingLayout } from './components/BillingLayout';
import { BillingCreditCards } from './routes/BillingCreditCards';
import { BillingHistory } from './routes/BillingHistory';
import { BillingTariffPlans } from './routes/BillingTariffPlans';
import { BillingBalance } from './routes/BillingBalance';
import { BillingInvoice } from './routes/BillingInvoice';

export const Billing = () => (
  <WayForPayContextProvider>
    <Routes>
      <Route element={<BillingLayout />}>
        <Route path="cards" element={<BillingCreditCards />} />
        <Route path="history" element={<BillingHistory />} />
        <Route path="plans" element={<BillingTariffPlans />} />
        <Route path="balance" element={<BillingBalance />} />
      </Route>

      <Route path="invoice/:id" element={<BillingInvoice />} />
    </Routes>
  </WayForPayContextProvider>
);
