import { Fragment } from 'react';
import { Outlet, createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Row, Col, Button, Preloader, Icon } from '@smart-kasa/ui';

import { InlineRouteLink } from 'components';
import { useAppSelector } from 'hooks/useAppStore';
import { useFindShopQuery } from 'services/api/core/shop/api';
import { useGetStaffQuery } from 'services/api/core/staff/api';
import { useGetTerminalsQuery } from 'services/api/core/pos/api';

import { findShopById } from 'features/shops/slice';
import { ShopReceiptPreview } from 'features/shops/components/ShopReceiptPreview';
import { TerminalList } from 'features/shops/components/TerminalList';
import { StaffList } from 'features/shops/components/StaffList';

export const ShopShow = () => {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useFindShopQuery(Number(shopId));
  const shop = useAppSelector((state) => findShopById(state, Number(shopId)));
  const { data: staff } = useGetStaffQuery(Number(shopId));
  const { terminals } = useGetTerminalsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      terminals: data?.data.filter((terminal) => terminal.shopId === Number(shopId)),
    }),
  });

  if (!shop || isLoading) return <Preloader />;

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button
            color="primary"
            rightIcon={<Icon name="edit" size="medium" />}
            onClick={() => navigate(`/shops/${shop.id}/edit`)}
          >
            Редагувати
          </Button>
        }
      >
        <InlineRouteLink icon="arrowLeft" to={'/'}>
          {shop.title}
        </InlineRouteLink>
      </PageHeader>

      <Row gutter={10}>
        <Col span={16}>
          <TerminalList
            terminals={terminals}
            onTerminalClick={(terminal) =>
              navigate(`/shops/${shop.id}/terminals/${terminal.id}/edit`)
            }
            onTerminalAdd={() => navigate(`/shops/${shop.id}/terminals/create`)}
          />
          <StaffList
            staff={staff?.data}
            onStaffAdd={() =>
              navigate({
                pathname: `/shops/${shop.id}/edit`,
                search: createSearchParams({ tab: '1' }).toString(),
              })
            }
          />
        </Col>
        <Col span={8}>
          <ShopReceiptPreview shop={shop} />
        </Col>
      </Row>

      <Outlet />
    </Fragment>
  );
};
