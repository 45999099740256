import { FC, ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import cs from 'classnames';
import { Button, ButtonIcon, Icon } from '@smart-kasa/ui';

import type { ITerminal } from 'services/api/core/pos/types';
import { TerminalState } from 'services/api/core/pos/enums';

import Device from 'assets/device.png';
import InactiveDevice from 'assets/device_pending.png';
import { TerminalStatus } from '../TerminalStatus';
import styles from './TerminalItem.module.scss';

interface ITerminalItem {
  terminal: ITerminal;
  className?: string;
  onRemove?: () => void;
  onClick?: () => void;
}

interface ITerminalItemRow {
  title?: ReactNode;
  children?: ReactNode;
}

const DEVICE_IMAGE: Record<TerminalState, string> = {
  [TerminalState.PENDING]: InactiveDevice,
  [TerminalState.ACTIVE]: Device,
  [TerminalState.DELETED]: InactiveDevice,
};

const TerminalItemWrapper = ({ className, ...props }) => (
  <div className={cs(styles.item, className)} {...props} />
);

const TerminalItemRow: FC<ITerminalItemRow> = ({ title, children }) => (
  <div className={styles.line}>
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.text}>{children}</div>
  </div>
);

export const TerminalItem: FC<ITerminalItem> = ({ terminal, className, onRemove, onClick }) => (
  <TerminalItemWrapper className={className}>
    {terminal.state === TerminalState.PENDING && (
      <ButtonIcon variant="text" color="dark" className={styles.buttonDelete} onClick={onRemove}>
        <Icon size="small" name="close" />
      </ButtonIcon>
    )}

    <div className={styles.info}>
      <div className={styles.picture}>
        <img className={styles.image} src={DEVICE_IMAGE[terminal.state]} alt="" />
      </div>
      <div className={styles.description}>
        <TerminalItemRow title={terminal.name || `Смарт Каса #${terminal.id}`} />
        <TerminalItemRow title={<TerminalStatus state={terminal.state} />} />
        <TerminalItemRow title="Серійний номер:">{terminal.serialNumber || '-'}</TerminalItemRow>
        <TerminalItemRow title="Дата активації:">
          {terminal.activatedAt ? (
            <FormattedDate value={terminal.activatedAt} dateStyle="short" timeStyle="short" />
          ) : (
            '-'
          )}
        </TerminalItemRow>
      </div>
    </div>

    <Button className={styles.buttonShow} color="light" width="full" onClick={onClick}>
      {terminal.state === TerminalState.PENDING ? 'Активувати' : 'Керувати терміналом'}
    </Button>
  </TerminalItemWrapper>
);
