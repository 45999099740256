import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, DataTable, IDataTableColumn, Page, Pagination, Preloader } from '@smart-kasa/ui';

import { usePagination } from 'hooks';
import { InlineLink } from 'components';
import { useFindStockImportDocumentQuery } from 'services/api/core/inventory/api';
import type { IImportRow } from 'services/api/core/inventory/types';

import styles from './ImportStockShow.module.scss';

export const ImportStockShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { page, perPage, onPageChange } = usePagination();
  const { data: file, isLoading } = useFindStockImportDocumentQuery(Number(id));
  const { results } = file?.data || {};
  const columns: IDataTableColumn<IImportRow>[] = [
    {
      title: 'Рядок',
      key: 'row',
    },
    {
      title: 'Ідентифікатор товару',
      key: 'attributes',
      render: ({ productId }, { errors }) => (
        <div>
          <div>{productId}</div>
          <div className={styles.error}>{errors['productId']}</div>
        </div>
      ),
    },
    {
      title: 'Ідентифікатор торгової точки',
      key: 'attributes',
      render: ({ shopId }, { errors }) => (
        <div>
          <div>{shopId}</div>
          <div className={styles.error}>{errors['shopId']}</div>
        </div>
      ),
    },
    {
      title: 'Відслідковувати залишки?',
      key: 'attributes',
      render: ({ countingEnabled }, { errors }) => (
        <div>
          <div>{countingEnabled ? 'Так' : 'Ні'}</div>
          <div className={styles.error}>{errors['countingEnabled']}</div>
        </div>
      ),
    },
    {
      title: 'Нова кількість',
      key: 'attributes',
      render: ({ count }, { errors }) => (
        <div>
          <div>{count}</div>
          <div className={styles.error}>{errors['count']}</div>
        </div>
      ),
    },
  ];

  const getPaginatedData = (data) => {
    const startIndex = page * perPage - perPage;
    const endIndex = startIndex + perPage;

    return data.slice(startIndex, endIndex);
  };

  if (isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Документ №{id}
        </InlineLink>
      }
    >
      {results && (
        <Fragment>
          {!results.error && results.invalid.length === 0 && (
            <Alert type="accent" icon="check">
              Файл імпортовано успішно
            </Alert>
          )}

          {results.error && (
            <Alert type="danger" icon="close">
              Товари з файлу не було імпортовано. Перевірте відповідність даних згідно
              файлу-шаблона.
            </Alert>
          )}

          {results.invalid && results.invalid.length > 0 && (
            <Fragment>
              <Alert type="danger" icon="info">
                Виникли помилки під час імпорту
              </Alert>
              <DataTable<IImportRow, 'row'>
                rowIdKey="row"
                data={getPaginatedData(results.invalid)}
                columns={columns}
                className={styles.table}
              />

              <Pagination
                totalItems={results.invalid.length}
                currentPage={page}
                perPage={perPage}
                onPageChange={onPageChange}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Page>
  );
};
