import { Fragment, useMemo, useState } from 'react';
import {
  ActionButton,
  DropDown,
  Icon,
  Title,
  Tooltip,
  Strong,
  ConfirmDialog,
  Paragraph,
} from '@smart-kasa/ui';

import { useAppSelector } from 'hooks';
import useModal from 'hooks/useModal';
import { useBulkChangeProductCategoryMutation } from 'services/api/core/inventory/api';
import type { ICategory } from 'services/api/core/inventory/types';
import { selectAllCategories, findCategorySiblings } from 'features/inventory/slice';

import type { BulkOperation } from './types';
import styles from './ProductsBulkActions.module.scss';

export const ProductsBulkMove = ({ products, onComplete }: BulkOperation) => {
  const categories = useAppSelector((state) => selectAllCategories(state));
  const [category, setCategory] = useState<ICategory>();
  const { isShowing, showModal, hideModal } = useModal();
  const productIds = useMemo(() => products.map(({ id }) => id), [products]);

  const [bulkUpdate] = useBulkChangeProductCategoryMutation();

  const CategoryDropDownitem = ({ category, depth = 0 }) => {
    const children = findCategorySiblings(categories, category.id);

    return (
      <div className={styles.category}>
        <button className={styles.categoryLink} onClick={() => selectCategory(category)}>
          {depth === 0 && <Icon name="folder" size="medium" className={styles.categoryIcon} />}
          {category.title}
        </button>

        {children.map((item) => (
          <CategoryDropDownitem key={item.id} category={item} depth={depth + 1} />
        ))}
      </div>
    );
  };

  const selectCategory = (category: ICategory) => {
    setCategory(category);
    showModal();
  };

  const handleMove = () => {
    if (!category) return;

    bulkUpdate({ ids: productIds, categoryId: category.id });
    onComplete && onComplete();
  };

  return (
    <Fragment>
      <DropDown>
        <ActionButton>
          <Tooltip text="Переміщення товарів в каталог">
            <Icon name="moveFolder" />
          </Tooltip>
        </ActionButton>
        <DropDown.Menu position="left" className={styles.categories}>
          {categories.map((category) => (
            <CategoryDropDownitem key={category.id} category={category} />
          ))}
        </DropDown.Menu>
      </DropDown>

      <ConfirmDialog show={isShowing} onClose={hideModal} onConfirm={handleMove}>
        <Icon name="folderMove" color="primary" size="xxx-large" />
        <Title variant="h3">Переміщення товарів</Title>
        <Paragraph>
          Наступні товари будуть переміщенні до каталогу{' '}
          <Strong>«{category && category.title}»</Strong>
        </Paragraph>
      </ConfirmDialog>
    </Fragment>
  );
};
