import '@smart-kasa/ui/dist/esm/bundle.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

// TODO: Move global css to 'css-reset' of @smartkasa/ui package
import 'components/global.module.scss';

import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Router from 'routes/Router';
import { store, persistor } from 'redux/store';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ToastContextProvider } from 'context/ToastContext';
import { TranslationContextProvider } from 'context/TranslationContext';
import { ConfigContext } from 'context/ConfigContext';
import { ConfirmContextProvider } from 'context/ConfirmContext';
import { SessionContextProvider } from 'context/SessionContext';
import { RROContextProvider } from 'context/RROContext';
import { DigitalSignatureContextProvider } from 'components/DigitalSignature';

export const App = () => {
  const { config } = useContext(ConfigContext);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={config.basename}>
          <TranslationContextProvider lang={config.locale}>
            <ToastContextProvider>
              <SessionContextProvider>
                <ConfirmContextProvider>
                  <DigitalSignatureContextProvider>
                    <RROContextProvider>
                      <ErrorBoundary>
                        <Router />
                      </ErrorBoundary>
                    </RROContextProvider>
                  </DigitalSignatureContextProvider>
                </ConfirmContextProvider>
              </SessionContextProvider>
            </ToastContextProvider>
          </TranslationContextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};
