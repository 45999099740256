import React, { FC } from 'react';
import cs from 'classnames';
import cardImg from 'assets/card.png';
import mcImg from 'assets/mc.png';
import prostirImg from 'assets/prostir.png';
import visaImg from 'assets/visa.png';
import styles from './CardIssuer.module.scss';

interface ICardIssuer {
  pan: string;
  className?: string;
}

const ISSUER_IMAGE: Record<number, string> = {
  3: cardImg, // American Express
  4: visaImg,
  5: mcImg,
  6: cardImg, // UnionPay
  9: prostirImg,
};

export const CardIssuer: FC<ICardIssuer> = ({ pan, className }) => {
  const bin = Number(pan[0]);
  const image = ISSUER_IMAGE[bin] || cardImg;

  return <img src={image} alt="" className={cs(styles.image, className)} />;
};
