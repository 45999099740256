export enum CLASSIFIER_TYPE {
  NONE = 0,
  UKTZED = 1,
  DKPP = 2,
}

interface IClassifierTypeProps {
  key?: number;
  value: number;
  label: string;
}

export const CLASSIFIER_TYPE_MAPPING: Record<CLASSIFIER_TYPE, IClassifierTypeProps> = {
  [CLASSIFIER_TYPE.NONE]: {
    key: CLASSIFIER_TYPE.NONE,
    value: CLASSIFIER_TYPE.NONE,
    label: 'Не обрано',
  },
  [CLASSIFIER_TYPE.UKTZED]: {
    key: CLASSIFIER_TYPE.UKTZED,
    value: CLASSIFIER_TYPE.UKTZED,
    label: 'УКТЗЕД',
  },
  [CLASSIFIER_TYPE.DKPP]: {
    key: CLASSIFIER_TYPE.DKPP,
    value: CLASSIFIER_TYPE.DKPP,
    label: 'ДКПП',
  },
};

export const classifierTypeOptions = Object.values(CLASSIFIER_TYPE_MAPPING);
