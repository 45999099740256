import { useNavigate } from 'react-router-dom';
import { Button, Icon, Pagination } from '@smart-kasa/ui';

import { useQueryPagination, useQuery } from 'hooks';
import { useGetProductImportListQuery } from 'services/api/core/inventory/api';

import { ImportLayout } from '../../components/ImportLayout';
import { ImportTable } from '../../components/ImportTable';

export const ImportCatalogList = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange } = useQueryPagination();
  const { data: documents, isFetching, refetch } = useGetProductImportListQuery(query);

  return (
    <ImportLayout
      actions={
        <Button onClick={refetch} rightIcon={<Icon name="replay" />}>
          Оновити
        </Button>
      }
    >
      <ImportTable
        data={documents?.data}
        isLoading={isFetching}
        onRowClick={(item) => navigate(`/inventory/import/catalog/${item.id}`)}
      />

      <Pagination
        totalItems={documents?.meta?.totalCount}
        currentPage={documents?.meta?.currentPage}
        perPage={documents?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </ImportLayout>
  );
};
