export enum RoleState {
  ACTIVE = 0,
  DELETED = 1,
}

export enum RoleType {
  DEFAULT = 0,
  CUSTOM = 1,
  SYSTEM = 2,
  HARDCODE = 3,
}

export enum IAMPolicyEffectType {
  DENY = 0,
  MANUAL = 1,
  ALLOW = 2,
}

export enum IAMPolicyGroupType {
  UNDEFINED = 0,
  SELLING = 1,
  RETURN_PRODUCT = 2,
  KASA = 3,
  INVENTORY = 4,
  RSN = 5,
  KASA_REPORTS = 6,
  OPERATION_REPORTS = 7,
  LOGS = 8,
  TERMINALS = 9,
  ALCOHOL_REPORTS = 10,
  SYNC_DATA = 11,
}

export enum IAMPolicyActionType {
  'selling:base' = 100,
  'selling:custom_price' = 101,
  'selling:custom_discount' = 102,

  'return_product:with_receipt' = 200,
  'return_product:without_receipt' = 201,

  'kasa:cash_credit' = 300,
  'kasa:cash_debit' = 301,

  'inventory:create_product' = 400,
  'inventory:create_product_confirm' = 401,
  'inventory:edit_product' = 402,
  'inventory:edit_product_confirm' = 403,
  'inventory:delete_product' = 404,
  'inventory:delete_product_confirm' = 405,
  'inventory:confirm_changes' = 406,

  'rsn:create_employee' = 500,
  'rsn:create_employee_confirm' = 501,
  'rsn:edit_employee' = 502,
  'rsn:edit_employee_confirm' = 503,
  'rsn:change_role' = 504,
  'rsn:change_role_confirm' = 505,
  'rsn:delete_employee' = 506,
  'rsn:delete_employee_confirm' = 507,
  'rsn:confirm_changes' = 508,

  'kasa_reports:x_report' = 600,
  'kasa_reports:z_report' = 601,

  'operation_reports:sales' = 700,
  'operation_reports:transactions' = 701,
  'operation_reports:products' = 702,
  'operation_reports:discounts' = 703,
  'operation_reports:return_products' = 704,
  'operation_reports:average_receipt' = 705,
  'operation_reports:terminals' = 706,

  'logs:transactions' = 800,
  'logs:documents' = 801,

  'terminals:appearance' = 900,
  'terminals:appearance_confirm' = 901,

  'alcohol_reports:base' = 1000,
  'sync_data:base' = 1100,
}
