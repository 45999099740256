import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Hint, ConfirmDialog } from '@smart-kasa/ui';

import { useDeleteTerminalMutation } from 'services/api/core/pos/api';

export const TerminalDelete = () => {
  const { id } = useParams();
  const [destroy] = useDeleteTerminalMutation();
  const navigate = useNavigate();

  const onDestroy = useCallback(async () => {
    await destroy(Number(id));

    navigate(-1);
  }, [id, destroy, navigate]);

  return (
    <ConfirmDialog onClose={() => navigate(-1)} onConfirm={onDestroy}>
      Ви дійсно бажаєте видалити обладнання?
      <Hint>
        Якщо Ви продовжите видалення обладнання Смарт Каса, то може спричинити пошкодження чи
        часткову втрату інформації, а також може вплинути на роботу із фіскальним сервером Державної
        податкової служби України.
      </Hint>
    </ConfirmDialog>
  );
};
