import createDecorator from 'final-form-calculate';

import { DiscountType } from 'services/api/core/receipts/enums';

export default createDecorator(
  {
    field: ['items', 'discountTypeId', 'discountPercentage', 'discountAmount'],
    updates: {
      totalAmount: (
        _totalAmount,
        { items, discountTypeId, discountPercentage, discountAmount }: any
      ) => {
        if (!items) return 0;

        let total: number = items.reduce((sum, { price, quantity }) => sum + price * quantity, 0);

        if (discountTypeId === DiscountType.percent && discountPercentage > 0) {
          total *= (100 - discountPercentage) / 100;
        }

        if (discountTypeId === DiscountType.currency && discountAmount > 0) {
          total -= discountAmount;
        }

        return total.toFixed(2);
      },
    },
  },
  {
    field: /items\[\d+\]/,
    updates: (_, field, { items = [] }: any) => {
      const matches = field.match(/\d+/);

      if (!matches) return {};

      const index = matches[0];
      const { quantity, price } = items[index];

      return {
        [`items[${index}.amount]`]: (price * quantity).toFixed(2),
      };
    },
  }
);
