import { Col, Row, Title, Hint, Card, Button } from '@smart-kasa/ui';

import { Field } from 'components';

import styles from './ProfileSecurity.module.scss';

export const ProfileSecurity = ({ disabled }) => (
  <Card>
    <Title variant="h2">Пароль</Title>
    <Hint>
      Пароль має містити щонайменше 8 символів, з них хоча б одну цифру, одну маленьку та одну
      велику латинські літери.
    </Hint>

    <div className={styles.container}>
      <Field.Password label="Старий пароль" name="oldPassword" />
    </div>

    <Row gutter={10} offset="between">
      <Col span={12}>
        <Field.Password label="Новий пароль" name="newPassword" />
      </Col>
      <Col span={12}>
        <Field.Password label="Повторити новий пароль" name="newPasswordConfirmation" />
      </Col>
    </Row>

    <Row>
      <Col span={12}>
        <Button type="submit" color="primary" width="full" disabled={disabled}>
          Зберегти
        </Button>
      </Col>
    </Row>
  </Card>
);
