import { createApi } from '@reduxjs/toolkit/query/react';

import { authBaseQuery } from 'services/queries/authBaseQuery';

import type { AssetFile, AssetFileResponse } from './types';

export const assetApi = createApi({
  reducerPath: 'assetApi',
  baseQuery: authBaseQuery,
  endpoints: (builder) => ({
    getUploadEndpoint: builder.query<AssetFileResponse, AssetFile>({
      query: (asset) => ({
        url: 'assets/endpoints',
        method: 'POST',
        body: { asset },
      }),
    }),
  }),
});

export const { useGetUploadEndpointQuery, useLazyGetUploadEndpointQuery } = assetApi;
