import cs from 'classnames';
import { Icon, IconName } from '@smart-kasa/ui';

import { ImportDocumentState } from 'services/api/core/inventory/enums';
import styles from './ImportDocumentStatus.module.scss';

const STATE_MAPPING: Record<ImportDocumentState, { color: string; icon: IconName }> = {
  [ImportDocumentState.pending]: { color: 'grey', icon: 'sync' },
  [ImportDocumentState.processing]: { color: 'cyan', icon: 'sync' },
  [ImportDocumentState.success]: { color: 'green', icon: 'check' },
  [ImportDocumentState.fail]: { color: 'red', icon: 'close' },
};

export interface IImportDocumentStatus {
  state: ImportDocumentState;
}

export const ImportDocumentStatus = ({ state }: IImportDocumentStatus) => (
  <div className={cs(styles.status, styles[STATE_MAPPING[state].color])}>
    <Icon name={STATE_MAPPING[state].icon} size="small" color="white" />
  </div>
);
