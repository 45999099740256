import { Preloader, Row } from '@smart-kasa/ui';

import { useGetPaidServicesQuery } from 'services/api/core/services/api';

import { ServiceSubscription } from '../ServiceSubscription';
import styles from './ServiceSubscriptionList.module.scss';

export const ServiceSubscriptionList = () => {
  const { data: subscriptions, isLoading } = useGetPaidServicesQuery({});

  if (!subscriptions || isLoading) return <Preloader />;

  return (
    <Row className={styles.list}>
      {subscriptions?.data.map((subscription) => (
        <ServiceSubscription key={subscription.id} subscription={subscription} />
      ))}
    </Row>
  );
};
