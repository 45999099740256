import { BaseQueryFn, FetchArgs, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import humps from 'humps';
import qs from 'query-string';
import streamSaver from 'streamsaver';

import { RootState } from 'redux/store';
import { getFileExtension } from 'utils/getFileExtension';
import type { HttpAdapter } from 'services/http/types';

const { REACT_APP_API_BASE_URL, REACT_APP_API_KEY } = process.env;

const adapter: HttpAdapter = {
  request: humps.decamelizeKeys,
  response: (data) => data,
};

export const downloadBaseQuery: BaseQueryFn<FetchArgs, unknown, unknown, {}, FetchBaseQueryMeta> =
  async ({ method, url, body, params }, { getState }, _extraOptions) => {
    const token = (getState() as RootState).auth.accessToken;
    const query = qs.stringify(adapter.request(params));
    const endpoint = REACT_APP_API_BASE_URL + url + '?' + query;
    const payload = {
      method,
      body: adapter.request(body),
      headers: {
        'X-Api-Key': String(REACT_APP_API_KEY),
        Authorization: String(token),
      },
    };

    const response = await fetch(endpoint, payload);

    try {
      const filename = `export-${new Date().toLocaleString()}.${getFileExtension(url)}`;
      const fileStream = streamSaver.createWriteStream(filename);
      const readableStream = response.body as any;

      if (window.WritableStream && readableStream.pipeTo) {
        readableStream.pipeTo(fileStream);

        return { data: null };
      }

      const writer = fileStream.getWriter();
      const reader = readableStream.getReader();
      const pump = () =>
        reader
          .read()
          .then((result) => (result.done ? writer.close() : writer.write(result.value).then(pump)));

      pump();

      return { data: null };
    } catch (error) {
      return { error };
    }
  };
