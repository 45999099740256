import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import {
  Icon,
  DataTable,
  IDataTableColumn,
  ButtonText,
  InlineSpace,
  Card,
  CardTitle,
  ButtonIcon,
} from '@smart-kasa/ui';

import { Picture } from 'components';
import type { IEmployee } from 'services/api/core/employees/types';

import styles from './StaffList.module.scss';

interface IStaffList {
  staff?: IEmployee[];
  onStaffAdd?: () => void;
}

export const StaffList: FC<IStaffList> = ({ staff, onStaffAdd }) => {
  const navigate = useNavigate();

  const columns: IDataTableColumn<IEmployee>[] = [
    {
      title: 'Працівники',
      key: 'firstName',
      render: (firstName, { lastName, avatar }) => (
        <div className={styles.details}>
          <Picture radius="full" src={avatar} text={firstName[0] + lastName[0]} />
          <InlineSpace />
          {firstName} {lastName}
        </div>
      ),
    },
    {
      title: 'Остання активність',
      key: 'updatedAt',
      render: (updatedAt: string) => (
        <FormattedDate value={updatedAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      className: styles.buttons,
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Card>
      <CardTitle>Персонал</CardTitle>

      <DataTable
        rowIdKey="id"
        data={staff}
        columns={columns}
        emptyText="Створіть список працівників, які мають право доступу до даної торгівельної точки та доступних терміналів. Саме обрані парцівники матимуть можливість входити через персональний код доступу."
        onRowClick={({ id }) => navigate(`/employees/${id}`)}
      />

      <InlineSpace direction="vertical" />

      <ButtonText onClick={onStaffAdd}>
        <Icon name="plus" size="small" />
        <InlineSpace />
        Додати працівника
      </ButtonText>
    </Card>
  );
};
