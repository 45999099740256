import { Hint } from '@smart-kasa/ui';

import type { ITerminal } from 'services/api/core/pos/types';

import styles from './TerminalSelect.module.scss';

export type TerminalSelectOptionProps = {
  terminal: ITerminal;
};

export const TerminalSelectOption = ({ terminal }: TerminalSelectOptionProps) => (
  <div className={styles.option}>
    <div className={styles.label}>
      #{terminal.id} - {terminal.name || 'Смарт Каса'}
    </div>
    <Hint margin={0} className={styles.description}>
      {terminal.serialNumber}
    </Hint>
  </div>
);
