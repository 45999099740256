import { FC, ReactNode } from 'react';
import cs from 'classnames';
import { Hint, Strong } from '@smart-kasa/ui';

import { COLORS } from 'constants/colors';
import { CategoryIcon } from 'components/CategoryIcon';
import type { ICategory } from 'services/api/core/inventory/types';

import styles from './CategoryItem.module.scss';

interface ICategoryItem {
  category: ICategory;
  selected?: boolean;
  className?: string;
  onSelect?: (category: ICategory) => void;
  actions?: (category: ICategory) => ReactNode | null;
}

export const CategoryItem: FC<ICategoryItem> = ({
  category,
  selected = false,
  className,
  actions,
  onSelect,
}) => {
  const handleSelect = () => {
    onSelect && onSelect(category);
  };

  return (
    <div
      className={cs(styles.folder, { [styles.active]: selected }, className)}
      onClick={handleSelect}
    >
      <CategoryIcon color={COLORS[category.colorId || 0]} />

      <div className={styles.info}>
        <Strong>{category.title}</Strong>
        <Hint margin={0}>{category.productsCount} товарів</Hint>
      </div>

      <div className={styles.actions}>{actions && actions(category)}</div>
    </div>
  );
};
