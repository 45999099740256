import { ActionButton, Icon, Tooltip } from '@smart-kasa/ui';

import { useBulkToggleFavoriteProductsMutation } from 'services/api/core/inventory/api';

import type { BulkOperation } from './types';

export const ProductsBulkFavorite = ({ products, onComplete }: BulkOperation) => {
  const isFavorite = products.every(({ isFavorite }) => isFavorite === true);
  const ids = products.map(({ id }) => id);

  const [bulkFavorite] = useBulkToggleFavoriteProductsMutation();

  const handleToggleFavorite = () => {
    bulkFavorite({ ids, isFavorite: !isFavorite });
    onComplete && onComplete();
  };

  return (
    <ActionButton active={isFavorite} onClick={handleToggleFavorite}>
      <Tooltip text="Додати товари в обрані">
        <Icon name="star" />
      </Tooltip>
    </ActionButton>
  );
};
