import { FC, HTMLAttributes } from 'react';
import { FormattedDate } from 'react-intl';
import cs from 'classnames';
import { Card } from '@smart-kasa/ui';

import { Picture } from 'components';
import type { IShop } from 'services/api/core/shop/types';

import Logo from 'assets/logo.svg';
import styles from './ShopReceiptPreview.module.scss';

export interface IShopReceiptPreview {
  shop: IShop | Partial<IShop>;
}

const PreviewWrapper = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => (
  <Card className={cs(styles.wrapper, className)} {...rest} />
);

const PreviewRow = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cs(styles.row, className)} {...rest} />
);

const PreviewDivider = () => <div className={styles.divider} />;

const PreviewFooter = () => (
  <div className={styles.graystyle}>
    <img src={Logo} alt="Receipt logo" />
  </div>
);

export const ShopReceiptPreview: FC<IShopReceiptPreview> = ({ shop }) => (
  <PreviewWrapper>
    <Picture src={shop.picture} size="XL" icon="business" className={styles.graystyle} />
    <PreviewRow>{shop.taxpayerName}</PreviewRow>
    <PreviewRow>{shop.title}</PreviewRow>
    <PreviewRow>{shop.address && shop.address.content}</PreviewRow>
    <PreviewRow>
      {shop.isVatTaxation ? 'ПН' : 'ІД'}: {shop.taxpayerNumber}
    </PreviewRow>

    <PreviewDivider />

    <PreviewRow>
      <FormattedDate value={new Date()} dateStyle="short" timeStyle="short" />
    </PreviewRow>
    <PreviewRow>Дякуємо!</PreviewRow>
    <PreviewRow>{shop.websiteUrl}</PreviewRow>
    <PreviewFooter />
  </PreviewWrapper>
);
