import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import cs from 'classnames';
import { AvatarItem, DropDown, Strong } from '@smart-kasa/ui';

import { Picture, Skeleton } from 'components';
import { ProfileContext } from 'context/ProfileContext';
import { SessionContext } from 'context/SessionContext';

import styles from './CurrentUser.module.scss';

export const CurrentUser = () => {
  const { logout } = useContext(SessionContext);
  const { profile, isLoading } = useContext(ProfileContext);
  const navigate = useNavigate();

  const profileInitials = useMemo(() => {
    return profile?.name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();
  }, [profile]);

  if (isLoading)
    return (
      <AvatarItem
        avatar={<Skeleton className={cs(styles.loader, styles.thumb)} width={40} height={35} />}
        primaryText={<Skeleton className={styles.name} width={140} height={10} />}
        secondaryText={
          <Skeleton className={cs(styles.loader, styles.phone)} width={94} height={8} />
        }
      />
    );

  return (
    <DropDown type="arrow" className={styles.user}>
      <DropDown.Toggle>
        <AvatarItem
          avatar={
            <Picture
              src={profile?.avatar}
              text={profileInitials}
              radius="full"
              className={styles.photo}
            />
          }
          primaryText={<Strong className={styles.name}>{profile?.name}</Strong>}
          secondaryText={<span className={styles.phone}>+{profile?.phoneNumber}</span>}
        />
      </DropDown.Toggle>
      <DropDown.Menu>
        <DropDown.Item onClick={() => navigate('/profile')} icon="user">
          Мій профіль
        </DropDown.Item>
        <DropDown.Item onClick={() => logout()} icon="logout">
          Вийти
        </DropDown.Item>
      </DropDown.Menu>
    </DropDown>
  );
};
