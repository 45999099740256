import { AutoSuggest } from 'components';
import Field from '../Field/Field';

export const AutoSuggestField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <AutoSuggest
        {...props}
        inputProps={{
          ...rest,
          ...input,
        }}
      />
    )}
  </Field>
);
