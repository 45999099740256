import { Fragment, useMemo, useRef } from 'react';
import { FormattedDate } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import { Button, Card, Col, Icon, Preloader, Row, UAH } from '@smart-kasa/ui';

import { useAppSelector, useQuery } from 'hooks';
import { dateToISO8601String } from 'utils/date';
import { selectShopById } from 'features/shops/slice';
import { useGetSummaryReportQuery } from 'services/api/core/reports/api';
import type { TaxesReportQuery } from 'services/api/core/reports/types';

import {
  ReportWrapper,
  ReportTitle,
  ReportShop,
  ReportGroup,
  ReportHeader,
  ReportHeaderRow,
  ReportRow,
} from 'features/reports/components/Report';
import { ReportLayout } from 'features/reports/components/ReportLayout';
import { ReportSummaryFilter } from 'features/reports/components/ReportSummaryFilter';
import styles from './ReportSummary.module.scss';

export const ReportSummary = () => {
  const { query, push } = useQuery<TaxesReportQuery>();
  const params = useMemo(
    () => ({
      dateStart: dateToISO8601String(dayjs().startOf('month').toDate()),
      dateEnd: dateToISO8601String(dayjs().toDate()),
      ...query,
    }),
    [query]
  );
  const { query: fiscalNumber, dateStart, dateEnd, terminalId, shopId } = params;
  const reportRef = useRef<HTMLDivElement>(null);
  const print = useReactToPrint({ content: () => reportRef.current });
  const shop = useAppSelector((state) => selectShopById(state, Number(shopId)));
  const { report, isLoading } = useGetSummaryReportQuery(params, {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, report: response?.data }),
  });

  return (
    <ReportLayout
      actions={
        <Button color="primary" rightIcon={<Icon name="printer" />} onClick={print}>
          Роздрукувати
        </Button>
      }
    >
      <Row gutter={10} className={styles.container}>
        <Col span={12}>
          <Card>
            {!report || isLoading ? (
              <Preloader />
            ) : (
              <ReportWrapper ref={reportRef}>
                <ReportHeader>
                  <ReportTitle>Зведений торговий звіт</ReportTitle>
                </ReportHeader>

                {shopId && shop && <ReportShop shop={shop} />}
                {(dateStart || dateEnd) && (
                  <ReportHeaderRow
                    title="Період:"
                    value={
                      <Fragment>
                        {dateStart && <FormattedDate value={dateStart} dateStyle="short" />}
                        {!dateStart && dateEnd && '∞'}
                        {(dateStart || dateEnd) && (
                          <Fragment>
                            {' - '}
                            <FormattedDate value={dateEnd || new Date()} dateStyle="short" />
                          </Fragment>
                        )}
                      </Fragment>
                    }
                  />
                )}
                {fiscalNumber && <ReportHeaderRow title="ФН/ФН ПРРО:" value={fiscalNumber} />}
                {terminalId && (
                  <ReportHeaderRow title="Номер терміналу:" value={`#${terminalId}`} />
                )}

                <ReportGroup title="Загальні підсумки">
                  <ReportRow title="Загальний обіг" value={<UAH value={report.turnoverAmount} />} />
                </ReportGroup>

                <ReportGroup title="Службові операції">
                  <ReportRow title="Сл. внесення" value={<UAH value={report.serviceInput} />} />
                  <ReportRow title="Сл. видача" value={<UAH value={report.serviceOutput} />} />
                </ReportGroup>

                <ReportGroup title="Продаж">
                  <ReportRow title="Готівка" value={<UAH value={report.saleCashTotal} />} />
                  <ReportRow title="Безготівка" value={<UAH value={report.saleNonCashTotal} />} />
                  <ReportRow title="Картка" value={<UAH value={report.saleCashlessTotal} />} />
                  <ReportRow title="Кількість чеків продажу" value={report.saleReceiptsCount} />
                  <ReportRow
                    title="Загальна сума продажу"
                    value={<UAH value={report.saleTotal} />}
                  />
                  <ReportRow
                    title="Податок продажу. ПДВ (А) 20%"
                    value={<UAH value={report.saleTaxATotal} />}
                  />
                  <ReportRow
                    title="Податок продажу. ПДВ (Б) 7%"
                    value={<UAH value={report.saleTaxBTotal} />}
                  />
                  <ReportRow
                    title="Податок продажу. Без ПДВ (Д)"
                    value={<UAH value={report.saleTaxDTotal} />}
                  />
                  <ReportRow
                    title="Податок продажу. ПДВ (В) 0%"
                    value={<UAH value={report.saleTaxVTotal} />}
                  />
                  <ReportRow
                    title="Податок продажу. Акциз (З) 5%"
                    value={<UAH value={report.saleTaxZTotal} />}
                  />
                  {/* <ReportRow
                    title="Податок продажу. Акциз (Г) 0%"
                    value={<UAH value={report.saleTaxGTotal} />}
                  /> */}
                </ReportGroup>

                <ReportGroup title="Повернення">
                  <ReportRow title="Готівка" value={<UAH value={report.refundCashTotal} />} />
                  <ReportRow title="Безготівка" value={<UAH value={report.refundNonCashTotal} />} />
                  <ReportRow title="Картка" value={<UAH value={report.refundCashlessTotal} />} />
                  <ReportRow
                    title="Кількість чеків повернення"
                    value={report.refundReceiptsCount}
                  />
                  <ReportRow
                    title="Загальна сума повернення"
                    value={<UAH value={report.refundTotal} />}
                  />
                  <ReportRow
                    title="Податок повернення. ПДВ (А) 20%"
                    value={<UAH value={report.refundTaxATotal} />}
                  />
                  <ReportRow
                    title="Податок повернення. ПДВ (Б) 7%"
                    value={<UAH value={report.refundTaxBTotal} />}
                  />
                  <ReportRow
                    title="Податок повернення. Без ПДВ (Д)"
                    value={<UAH value={report.refundTaxDTotal} />}
                  />
                  <ReportRow
                    title="Податок повернення. ПДВ (В) 0%"
                    value={<UAH value={report.refundTaxVTotal} />}
                  />
                  <ReportRow
                    title="Податок повернення. Акциз (З) 5%"
                    value={<UAH value={report.refundTaxZTotal} />}
                  />
                  {/* <ReportRow
                    title="Податок повернення. Акциз (Г) 0%"
                    value={<UAH value={report.refundTaxGTotal} />}
                  /> */}
                </ReportGroup>

                <ReportGroup title="Видача готівки">
                  <ReportRow title="Кількість видаткових чеків" value={report.cashReceiptsCount} />
                  <ReportRow
                    title="Загальна сума видаткових чеків"
                    value={<UAH value={report.cashTotal} />}
                  />
                </ReportGroup>
              </ReportWrapper>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <ReportSummaryFilter initialValues={params} onSubmit={push} />
          </Card>
        </Col>
      </Row>
    </ReportLayout>
  );
};
