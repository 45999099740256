import { FC, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageHeader, InlineSpace, Button, Icon } from '@smart-kasa/ui';

import useModal from 'hooks/useModal';

import { ReportNavigation } from '../ReportNavigation';

interface IReportLayout {
  actions?: ReactNode;
  renderFilter?: FC<IReportFilter>;
  children: ReactNode;
}

interface IReportFilter {
  isVisible: boolean;
  onClose: () => void;
}

export const ReportLayout: FC<IReportLayout> = ({ actions, renderFilter, children }) => {
  const { isShowing, showModal, hideModal } = useModal();

  return (
    <Fragment>
      <PageHeader
        actions={
          <Fragment>
            {actions}
            <InlineSpace />
            {renderFilter && (
              <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
                Фільтр
              </Button>
            )}
          </Fragment>
        }
      >
        <FormattedMessage id="navigation.reports" defaultMessage="Звітність" />
      </PageHeader>

      <ReportNavigation />

      {children}

      {renderFilter &&
        renderFilter({
          isVisible: isShowing,
          onClose: hideModal,
        })}
    </Fragment>
  );
};
