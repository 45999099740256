import { coreApi } from '../api';
import type { Pagination, CoreResponse } from 'types/api';

import type { IEmployee } from './types';

export const employeeApi = coreApi.enhanceEndpoints({ addTagTypes: ['Employee'] }).injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query<CoreResponse<IEmployee[]>, Pagination<IEmployee>>({
      query: (params?) => ({ url: '/rsn/employees', params }),
      providesTags: ['Employee'],
    }),
    findEmployee: builder.query<CoreResponse<IEmployee>, number>({
      query: (id) => ({ url: `/rsn/employees/${id}` }),
      providesTags: ['Employee'],
    }),
    createEmployee: builder.mutation<void, Partial<IEmployee>>({
      query: (employee) => ({
        url: '/rsn/employees',
        method: 'POST',
        body: { employee },
      }),
      invalidatesTags: ['Employee'],
    }),
    updateEmployee: builder.mutation<void, Partial<IEmployee>>({
      query: ({ id, ...employee }) => ({
        url: `/rsn/employees/${id}`,
        method: 'PUT',
        body: { employee },
      }),
      invalidatesTags: ['Employee'],
    }),
    deleteEmployee: builder.mutation<void, number>({
      query: (id) => ({
        url: `/rsn/employees/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employee'],
    }),
    deleteEmployeeAvatar: builder.mutation<void, number>({
      query: (id) => ({
        url: `/rsn/employees/${id}/avatar`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employee'],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useFindEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useDeleteEmployeeAvatarMutation,
} = employeeApi;
