import { FC } from 'react';
import { Form } from 'react-final-form';
import {
  Col,
  FormElement,
  Row,
  Collapse,
  CollapseHeader,
  CollapseContent,
  ButtonText,
  Button,
} from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useRoles } from 'features/roles/hooks/useRoles';
import type { IEmployee } from 'services/api/core/employees/types';

import { EmployeeFormSchema } from './EmployeeFormSchema';
import styles from './EmployeeForm.module.scss';

const optionsGender = [
  {
    label: 'Чоловік',
    value: 1,
    key: '1',
  },
  {
    label: 'Жінка',
    value: 2,
    key: '2',
  },
];

interface IEmployeeForm {
  initialValues: Partial<IEmployee>;
  onDismiss?: () => void;
  onRemoveAttachment?: () => void;
  onSubmit: (fd) => void;
}

export const EmployeeForm: FC<IEmployeeForm> = ({
  initialValues,
  onDismiss,
  onRemoveAttachment,
  onSubmit,
}) => {
  const { rolesOptions } = useRoles();

  return (
    <Form<Partial<IEmployee>>
      validate={transformValidationSchema(EmployeeFormSchema)}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.container}>
            <div className={styles.left}>
              <Field.FileUploader
                name="avatar"
                accept=".png, .jpg, .jpeg"
                onRemove={onRemoveAttachment}
              />
            </div>
            <div className={styles.right}>
              <FormElement
                label="Основна інформація"
                description="Тут вказані базові дані про працівника"
                className={styles.group}
              >
                <Row>
                  <Col span={24}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Field.Input name="lastName" label="Прізвище" />
                      </Col>
                      <Col span={12}>
                        <Field.Input name="firstName" label="Ім’я" />
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Field.Input
                          name="middleName"
                          label="По-Батькові"
                          placeholder="По-Батькові"
                        />
                      </Col>
                      <Col span={12}>
                        <Field.Number name="inn" label="ІПН" placeholder="053543643XXX" />
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Field.Phone
                          name="phoneNumber"
                          label="Телефон"
                          placeholder="+38 (0XX) XX XX XXX"
                        />
                      </Col>
                      <Col span={12}>
                        <Field.Input
                          name="email"
                          label="Email"
                          placeholder="personalmail@mail.com"
                        />
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Field.Select
                          name="roleId"
                          label="Посада"
                          placeholder="Оберіть посаду"
                          options={rolesOptions}
                        />
                      </Col>
                      <Col span={12}>
                        <Field.PinCode name="pinCode" label="Код доступу до каси" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </FormElement>

              <Collapse theme="transparent">
                <CollapseHeader>
                  <FormElement
                    label="Додаткова інформація"
                    description="Тут вказана додаткова інформація про працівника, вона не є обов'язковою для
                заповнення"
                  >
                    <></>
                  </FormElement>
                </CollapseHeader>

                <CollapseContent>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Field.Datepicker name="birthday" label="День народження" />
                    </Col>
                    <Col span={12}>
                      <Field.Select
                        name="genderTypeId"
                        label="Стать"
                        placeholder="Оберіть стать"
                        options={optionsGender}
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Field.Input name="passportNumber" label="Паспорт" placeholder="ББ ХХХХХХ" />
                    </Col>
                    <Col span={12}>
                      <Field.Phone
                        name="additionalPhoneNumber"
                        label="Додатковий телефон"
                        placeholder="+38 (0XX) XX XX XXX"
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={20}>
                      <Field.Input
                        name="addressActual.content"
                        label="Адреса проживання"
                        placeholder="Адреса проживання"
                      />
                    </Col>
                    <Col span={4}>
                      <Field.Number
                        name="addressActual.zipCode"
                        label="Індекс"
                        placeholder="ХХХХХ"
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={20}>
                      <Field.Input
                        name="addressLegal.content"
                        label="Адреса прописки"
                        placeholder="Адреса прописки"
                      />
                    </Col>
                    <Col span={4}>
                      <Field.Number
                        name="addressLegal.zipCode"
                        label="Індекс"
                        placeholder="ХХХХХ"
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Field.Datepicker name="joinedAt" label="День реєстрації у системі" />
                    </Col>
                    <Col span={12}>
                      <Field.Datepicker name="startDate" label="День початку роботи у компанії" />
                    </Col>
                  </Row>
                </CollapseContent>
              </Collapse>
            </div>
          </div>

          <div className={styles.buttons}>
            <ButtonText onClick={onDismiss}>Скасувати</ButtonText>
            <Button color="primary" type="submit">
              Зберегти
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
