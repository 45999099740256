import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Paragraph } from '@smart-kasa/ui';

import { BlankView } from 'components';
import type { IReceiptItem } from 'services/api/core/receipts/types';

import { ReceiptItemRow } from './ReceiptItemRow';
import styles from './ReceiptForm.module.scss';

export const ReceiptItemList = ({ fields }: FieldArrayRenderProps<IReceiptItem, any>) => (
  <div className={styles.items}>
    {!fields.length && (
      <BlankView>
        <Paragraph>Чек порожній.</Paragraph>
        <Paragraph>Ви можете додати товари/послуги через пошук</Paragraph>
      </BlankView>
    )}

    {fields.map((name, index) => (
      <ReceiptItemRow
        key={`${name}:${index}`}
        name={name}
        index={index}
        item={fields.value[index]}
        onRemove={(index) => fields.remove(index)}
      />
    ))}
  </div>
);
