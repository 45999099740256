import { ReactNode } from 'react';
import { FormElement, Label } from '@smart-kasa/ui';

import Field from '../Field/Field';
import { FileUploader, IFileUploaderProps } from '../FileUploader';

interface IWrappedProps {
  description?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  name: string;
  uploaderLabel?: ReactNode;
}

interface IWrappedFileUploaderProps
  extends IWrappedProps,
    Omit<IFileUploaderProps, 'name' | 'onChange'> {}

export const FileUploaderField = ({
  label,
  required,
  disabled,
  name,
  description,
  uploaderLabel,
  onRemove,
  ...props
}: IWrappedFileUploaderProps) => (
  <Field name={name}>
    {({ input, meta, ...rest }) => (
      <FormElement
        description={description}
        label={
          label && (
            <Label htmlFor={name} required={required}>
              {label}
            </Label>
          )
        }
      >
        <FileUploader
          name={name}
          {...meta}
          {...rest}
          {...props}
          label={uploaderLabel}
          onChange={(files) => {
            const { multiple } = props;
            const [file] = files;

            return multiple ? input.onChange(files) : input.onChange(file);
          }}
          onRemove={(file) => {
            input.onChange(null);

            onRemove && onRemove(file);
          }}
        />
      </FormElement>
    )}
  </Field>
);
