import { ReactNode } from 'react';
import { Icon } from '@smart-kasa/ui';

import { TransactionType } from 'services/api/core/transactions/enums';
import styles from './TransactionPaymentMethod.module.scss';

const PAYMENT_METHOD_ICONS: Record<TransactionType, { name: string; icon: ReactNode }> = {
  [TransactionType.cashless]: {
    name: 'Картка',
    icon: <Icon name="creditCard" color="secondary" size="medium" />,
  },
  [TransactionType.cash]: {
    name: 'Готівка',
    icon: <Icon name="cash" color="accent" size="medium" />,
  },
  [TransactionType.nonCash]: {
    name: 'Безготівка',
    icon: <Icon name="receipt" color="primary" size="medium" />,
  },
};

export type TransactionPaymentMethodProps = {
  type: TransactionType;
  name?: ReactNode;
  iconized?: boolean;
  children?: never;
};

export const TransactionPaymentMethod = ({
  type,
  name,
  iconized = true,
}: TransactionPaymentMethodProps) => (
  <div className={styles.paymentMethod}>
    <div className={styles.info}>
      {name || <span className={styles.name}>{PAYMENT_METHOD_ICONS[type].name}</span>}
    </div>

    {iconized && PAYMENT_METHOD_ICONS[type].icon}
  </div>
);
