import type { ISupportCategory } from 'services/api/core/support/types';

import { SupportCategory } from '../SupportCategory/SupportCategory';
import styles from './SupportCategoryList.module.scss';

export const SupportCategoryList = ({ categories }: { categories: ISupportCategory[] }) => (
  <div className={styles.categoryList}>
    {categories.map((category) => (
      <SupportCategory key={category.id} category={category} />
    ))}
  </div>
);
