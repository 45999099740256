import { coreApi } from '../api';
import type { CoreResponse } from 'types';
import type {
  BulkRequest,
  ICategory,
  IImportDocument,
  IImportDocumentFormData,
  IProduct,
  IProductComponent,
  IStockCard,
  ISubgroup,
  ImportDocumentListQuery,
  ProductListQuery,
  StockListQuery,
} from './types';

export const inventoryApi = coreApi
  .enhanceEndpoints({
    addTagTypes: [
      'ProductList',
      'Product',
      'CoreProduct',
      'Component',
      'Category',
      'Subgroup',
      'Stock',
      'ImportDocument',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProducts: builder.query<CoreResponse<IProduct[]>, ProductListQuery>({
        query: (params) => ({ url: '/inventory/products', params }),
        providesTags: ['ProductList'],
      }),
      findProduct: builder.query<CoreResponse<IProduct>, string>({
        query: (id) => ({ url: `/inventory/products/${id}` }),
        providesTags: ['Product'],
      }),
      createProduct: builder.mutation<CoreResponse<IProduct>, Partial<IProduct>>({
        query: (product) => ({
          url: '/inventory/products',
          method: 'POST',
          body: { product },
        }),
        invalidatesTags: ['ProductList'],
      }),
      updateProduct: builder.mutation<void, Pick<IProduct, 'id'> & Partial<IProduct>>({
        query: ({ id, ...product }) => ({
          url: `/inventory/products/${id}`,
          method: 'PUT',
          body: { product },
        }),
        invalidatesTags: ['ProductList', 'Product'],
      }),
      deleteProduct: builder.mutation<{ success: boolean; id: number }, string>({
        query: (id) => ({
          url: `/inventory/products/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ProductList'],
      }),
      deleteProductPhoto: builder.mutation<{ success: boolean; id: number }, string>({
        query: (id) => ({
          url: `/inventory/products/${id}/picture`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ProductList', 'Product'],
      }),

      // Bulk operations
      bulkChangeProductCategory: builder.mutation<CoreResponse<ICategory>, BulkRequest>({
        query: (bulk) => ({
          url: '/inventory/products/bulk/category',
          method: 'POST',
          body: { bulk },
        }),
        invalidatesTags: ['ProductList', 'Product'],
      }),
      bulkToggleFavoriteProducts: builder.mutation<CoreResponse<ICategory>, BulkRequest>({
        query: (bulk) => ({
          url: '/inventory/products/bulk/favorite',
          method: 'POST',
          body: { bulk },
        }),
        invalidatesTags: ['ProductList', 'Product'],
      }),
      bulkChangeProductFreePrice: builder.mutation<CoreResponse<ICategory>, BulkRequest>({
        query: (bulk) => ({
          url: '/inventory/products/bulk/free_price',
          method: 'POST',
          body: { bulk },
        }),
        invalidatesTags: ['ProductList', 'Product'],
      }),
      bulkDeleteProducts: builder.mutation<CoreResponse<ICategory>, BulkRequest>({
        query: (bulk) => ({
          url: '/inventory/products/bulk/destroy',
          method: 'POST',
          body: { bulk },
        }),
        invalidatesTags: ['ProductList'],
      }),

      // Core products
      searchCoreProducts: builder.query<CoreResponse<IProduct[]>, string>({
        query: (term) => ({ url: '/inventory/core/products', params: { q: term } }),
        providesTags: ['CoreProduct'],
      }),

      // Components
      getProductComponents: builder.query<CoreResponse<IProduct>, string>({
        query: (id) => ({ url: `/inventory/components/${id}` }),
        providesTags: ['Component'],
      }),
      updateProductComponents: builder.mutation<
        void,
        { id: string; components: IProductComponent[] }
      >({
        query: ({ id, components }) => ({
          url: `/inventory/components/${id}`,
          method: 'PUT',
          body: { product: { id, components } },
        }),
        invalidatesTags: ['Component'],
      }),

      // Categories
      getCategories: builder.query<CoreResponse<ICategory[]>, void>({
        query: () => ({ url: '/inventory/categories', params: { skipPagination: true } }),
        providesTags: ['Category'],
      }),
      findCategory: builder.query<CoreResponse<ICategory>, string>({
        query: (id) => ({ url: `/inventory/categories/${id}` }),
        providesTags: ['Category'],
      }),
      createCategory: builder.mutation<CoreResponse<ICategory>, Partial<ICategory>>({
        query: (category) => ({
          url: '/inventory/categories',
          method: 'POST',
          body: { category },
        }),
        invalidatesTags: ['Category'],
      }),
      updateCategory: builder.mutation<void, Pick<ICategory, 'id'> & Partial<ICategory>>({
        query: ({ id, ...category }) => ({
          url: `/inventory/categories/${id}`,
          method: 'PUT',
          body: { category },
        }),
        invalidatesTags: ['Category'],
      }),
      deleteCategory: builder.mutation<{ success: boolean; id: number }, string>({
        query: (id) => ({
          url: `/inventory/categories/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Category'],
      }),

      // Subgroups
      getSubgroups: builder.query<CoreResponse<ISubgroup[]>, void>({
        query: () => ({ url: '/inventory/product_subgroups' }),
        providesTags: ['Subgroup'],
      }),
      findSubgroup: builder.query<CoreResponse<ISubgroup>, number>({
        query: (id) => ({ url: `/inventory/product_subgroups/${id}` }),
        providesTags: ['Subgroup'],
      }),
      createSubgroup: builder.mutation<CoreResponse<ISubgroup>, Partial<ISubgroup>>({
        query: (subgroup) => ({
          url: '/inventory/product_subgroups',
          method: 'POST',
          body: { subgroup },
        }),
        invalidatesTags: ['Subgroup'],
      }),
      updateSubgroup: builder.mutation<void, Pick<ISubgroup, 'id'> & Partial<ISubgroup>>({
        query: ({ id, ...subgroup }) => ({
          url: `/inventory/product_subgroups/${id}`,
          method: 'PUT',
          body: { subgroup },
        }),
        invalidatesTags: ['Subgroup'],
      }),
      deleteSubgroup: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({
          url: `/inventory/product_subgroups/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Subgroup'],
      }),

      // Stocks
      getStocks: builder.query<CoreResponse<IStockCard[]>, StockListQuery>({
        query: (params) => ({ url: '/inventory/cards', params }),
        providesTags: ['Stock'],
      }),
      findStock: builder.query<CoreResponse<IStockCard>, number>({
        query: (id) => ({ url: `/inventory/cards/${id}` }),
        providesTags: ['Stock'],
      }),
      createStock: builder.mutation<CoreResponse<ISubgroup>, Partial<IStockCard>>({
        query: (card) => ({
          url: '/inventory/cards',
          method: 'POST',
          body: { card },
        }),
        invalidatesTags: ['Stock', 'ProductList'],
      }),
      updateStock: builder.mutation<void, Pick<ISubgroup, 'id'> & Partial<IStockCard>>({
        query: ({ id, ...card }) => ({
          url: `/inventory/cards/${id}`,
          method: 'PUT',
          body: { card },
        }),
        invalidatesTags: ['Stock', 'ProductList'],
      }),
      deleteStock: builder.mutation<{ success: boolean; id: number }, number>({
        query: (id) => ({
          url: `/inventory/cards/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Stock', 'ProductList'],
      }),

      // Import
      getProductImportList: builder.query<CoreResponse<IImportDocument[]>, ImportDocumentListQuery>(
        {
          query: (params) => ({ url: '/inventory/import_products', params }),
          providesTags: ['ImportDocument'],
        }
      ),
      findProductImportDocument: builder.query<CoreResponse<IImportDocument>, number>({
        query: (id) => ({ url: `/inventory/import_products/${id}` }),
        providesTags: ['ImportDocument'],
      }),
      createProductImportDocument: builder.mutation<
        CoreResponse<IImportDocument>,
        IImportDocumentFormData
      >({
        query: (document) => ({
          url: '/inventory/import_products',
          method: 'POST',
          body: { import: document },
        }),
        invalidatesTags: ['ImportDocument'],
      }),
      getStockImportList: builder.query<CoreResponse<IImportDocument[]>, ImportDocumentListQuery>({
        query: (params) => ({ url: '/inventory/import_cards', params }),
        providesTags: ['ImportDocument'],
      }),
      findStockImportDocument: builder.query<CoreResponse<IImportDocument>, number>({
        query: (id) => ({ url: `/inventory/import_cards/${id}` }),
        providesTags: ['ImportDocument'],
      }),
      createStockImportDocument: builder.mutation<
        CoreResponse<IImportDocument>,
        IImportDocumentFormData
      >({
        query: (document) => ({
          url: '/inventory/import_cards',
          method: 'POST',
          body: { import: document },
        }),
        invalidatesTags: ['ImportDocument'],
      }),
    }),
  });

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useFindProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDeleteProductPhotoMutation,

  useBulkChangeProductCategoryMutation,
  useBulkToggleFavoriteProductsMutation,
  useBulkChangeProductFreePriceMutation,
  useBulkDeleteProductsMutation,

  useSearchCoreProductsQuery,
  useLazySearchCoreProductsQuery,

  useGetProductComponentsQuery,
  useUpdateProductComponentsMutation,

  useGetCategoriesQuery,
  useFindCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,

  useGetSubgroupsQuery,
  useFindSubgroupQuery,
  useCreateSubgroupMutation,
  useUpdateSubgroupMutation,
  useDeleteSubgroupMutation,

  useGetStocksQuery,
  useFindStockQuery,
  useCreateStockMutation,
  useUpdateStockMutation,
  useDeleteStockMutation,

  useGetProductImportListQuery,
  useFindProductImportDocumentQuery,
  useCreateProductImportDocumentMutation,
  useGetStockImportListQuery,
  useFindStockImportDocumentQuery,
  useCreateStockImportDocumentMutation,
} = inventoryApi;
