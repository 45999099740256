import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Hint, Icon, InlineSpace, Strong } from '@smart-kasa/ui';

import { Massenger } from '../Massenger';
import styles from './Support.module.scss';

const { REACT_APP_TELEGRAM_BOT, REACT_APP_VIBER_BOT } = process.env;

export const Support = () => {
  const { formatMessage } = useIntl();
  const openChat = () => window.kw_event('openchat', 1);

  useEffect(() => {
    window.kw('init', {
      language_code: 'uk',
      bot_id: '3',
      params_from_site: {},
      title: formatMessage({ id: 'chat.title' }),
      subtitle: formatMessage({ id: 'chat.subtitle' }),
      titleImageUrl:
        'https://www.prro.online/wp-content/uploads/2024/07/sk-logo-white-background.jpg',
      botAvatarImageUrl: 'https://www.prro.online/wp-content/uploads/2024/07/smart-bot.jpg',
      launcherIsHiddenState: true,
      open_on_msg: false,
      btn_restart_enabled: false,
    });
  }, [formatMessage]);

  return (
    <div className={styles.support}>
      <Hint margin={0} className={styles.phone}>
        <Strong>тел. 0 800 312 371</Strong>
      </Hint>
      <InlineSpace />
      {REACT_APP_TELEGRAM_BOT && <Massenger type="telegram" url={REACT_APP_TELEGRAM_BOT} />}
      <InlineSpace />
      {REACT_APP_VIBER_BOT && <Massenger type="viber" url={REACT_APP_VIBER_BOT} />}
      <InlineSpace />
      <Button
        className={styles.chat}
        rightIcon={<Icon name="support" color="light" />}
        onClick={openChat}
      >
        <span className={styles.chatText}>Online-підтримка</span>
      </Button>
    </div>
  );
};
