import { Select, SelectProps } from '@smart-kasa/ui';

import { COLORS } from 'constants/colors';
import { CategoryIcon } from 'components/CategoryIcon/CategoryIcon';

import styles from './ColorSelect.module.scss';

const DEFAULT_STYLES = {
  control: (provided: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    width: '72px',
    minHeight: '40px',

    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    width: '40px',
    height: '40px',
    padding: '0',
    flex: 'none',
    backgroundColor: '#f3f3f3',
    borderRadius: 8,
    fontSize: 10,
    justifyContent: 'center',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'transparent',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#92929D',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    maxWidth: '100%',
    margin: '0',
    borderRadius: 8,
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '65',
    border: 'none',
    borderRadius: 8,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '0',
    border: 'none',
    overflow: 'hidden',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '0',
    backgroundColor: 'transparent',

    '&:active': { backgroundColor: 'transparent' },
    '&:first-child > div': { borderRadius: '8px 8px 0 0' },
    '&:last-child > div': { borderRadius: '0 0 8px 8px' },
  }),
};

const DEFAULT_OPTIONS = Object.keys(COLORS).map((key, index) => ({
  value: index,
  label: <CategoryIcon className={styles.icon} color={COLORS[key]} />,
}));

export const ColorSelect = ({
  options = DEFAULT_OPTIONS,
  styles = DEFAULT_STYLES,
  defaultValue = 3,
  ...rest
}: SelectProps) => (
  <Select styles={styles} options={options} defaultValue={defaultValue} {...rest} />
);
