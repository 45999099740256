import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';
import { TAX_GROUP } from 'constants/taxGroup';
import { CLASSIFIER_TYPE } from 'constants/classifierType';

export const ProductFormSchema = yup.object().shape({
  title: yup.string().required().max(128, 'Поле занадто довге'),
  alterTitle: yup.string().nullable().max(128, 'Поле занадто довге'),
  categoryId: yup.string().required(),
  price: yup
    .number()
    .required()
    .typeError("Ціна продажу обов'язкове для заповнення поле")
    .moreThan(0, 'Ціна не може бути нульовою!'),
  procurementPrice: yup.number().nullable(),
  taxGroupId: yup.number().required("Група оподаткування обов'язкове для заповнення поле"),
  classifierTypeId: yup.number().when('taxGroupId', {
    is: (value) =>
      [
        TAX_GROUP.AKTSYZ_5,
        TAX_GROUP.AKTSYZ_5_PDV_20,
        TAX_GROUP.AKTSYZ_5_PDV_0,
        TAX_GROUP.AKTSYZ_0_PDV_20,
      ].includes(Number(value)),
    then: yup.number().moreThan(0, "Тип класифікатора обов'язкове для заповнення поле"),
  }),
  classifierCode: yup
    .string()
    .when('classifierTypeId', {
      is: (value) => Number(value) !== CLASSIFIER_TYPE.NONE,
      then: yup.string().required("Код для класифікації обов'язкове для заповнення поле"),
    })
    .when('classifierTypeId', {
      is: (value) => Number(value) === CLASSIFIER_TYPE.DKPP,
      then: yup
        .string()
        .matches(
          /([\d]{2}\.[\d]{2}\.[\d]{2}-[\d]{2}(\.[\d]{1,2})*)|([\d]{2}\.[\d]{2}\.[\d]{2}(-[\d]{1,2})*)|([\d]{2}\.[\d]{2}(\.[\d]{1,2})*)/,
          {
            excludeEmptyString: true,
            message: 'Код ДКПП повинен мати формат XX.XX.XX-XX.XX',
          }
        ),
    })
    .when('classifierTypeId', {
      is: (value) => Number(value) === CLASSIFIER_TYPE.UKTZED,
      then: yup
        .string()
        .matches(/^\d+$/, { excludeEmptyString: true, message: 'Код УКТЗЕД має бути числом' }),
    }),
});

export const ProductFormErrorSchema = schemaError({
  default: 'Невірний товар',
  number: {
    default: 'Номер не знайдено.',
    taken: 'Товар з таким номером вже доданий.',
  },
});
