import { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import {
  Button,
  Col,
  Collapse,
  CollapseContent,
  CollapseHeader,
  Hint,
  Icon,
  Inline,
  Row,
  Strong,
  Tooltip,
} from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { IShop } from 'services/api/core/shop/types';

import { ShopSchema } from './ShopSchema';
import styles from './ShopForm.module.scss';

const options = [
  { label: 'Активна', value: 0, key: '0' },
  { label: 'Неактивна', value: 1, key: '1' },
];

export interface IShopForm {
  initialValues: Partial<IShop>;
  onDismiss?: () => void;
  onSubmit: (fd) => void;
  onRemoveAttachment?: () => void;
}

export const ShopForm: FC<IShopForm> = ({
  initialValues,
  onDismiss,
  onSubmit,
  onRemoveAttachment,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      validate={transformValidationSchema(ShopSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={5}>
              <Field.FileUploader
                name="picture"
                accept=".png, .jpg, .jpeg"
                uploaderLabel="Перетягніть логотип сюди або завантажте"
                className={styles.uploaderField}
                onRemove={onRemoveAttachment}
              />
            </Col>
            <Col span={19}>
              <Row gutter={10}>
                <Col span={12}>
                  <Field.Input
                    name="title"
                    label={
                      <Fragment>
                        Назва господарської одиниці
                        <Tooltip
                          width={300}
                          text="Найменування, яке зазначене в документі на право власності або користування господарською одиницею і відповідає довіднику «Типи об’єктів оподаткування» та повідомлене ДПС у повідомлені про об’єкти оподаткування або об’єкти, пов’язані з оподаткуванням або через які провадиться діяльність за формою № 20-ОПП"
                        >
                          <Icon color="primary" name="info" size="medium" />
                        </Tooltip>
                      </Fragment>
                    }
                  />
                </Col>
                <Col span={12}>
                  <Field.Input name="taxpayerName" label="Найменування суб’єкта господарювання" />
                </Col>
              </Row>

              <Field.Input
                name="address.content"
                label={
                  <Fragment>
                    Адреса господарської одиниці
                    <Tooltip
                      width={300}
                      text="Назва населеного пункту, назва вулиці, номер будинку/офісу/квартири, повідомлена ДПС за ф. № 20-ОПП"
                    >
                      <Icon color="primary" name="info" size="medium" />
                    </Tooltip>
                  </Fragment>
                }
                placeholder="Фактична адреса торгової точки"
              />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={5}>
              <Field.Switch name="isVatTaxation" label="Є платником ПДВ?" />
            </Col>

            <Col span={19}>
              <Field.Input
                name="taxpayerNumber"
                label={
                  <Fragment>
                    ПН/ІД
                    <Tooltip
                      width={300}
                      text="Індивідуальний податковий номер платника ПДВ або податковий номер або серія та номер паспорта/номер ID картки"
                    >
                      <Icon color="primary" name="info" size="medium" />
                    </Tooltip>
                  </Fragment>
                }
              />
            </Col>
          </Row>

          <Collapse theme="transparent" active={true}>
            <CollapseHeader>
              <Strong>Налаштування</Strong>
              <Hint>Керування торговою точкою та додаткова інформація</Hint>
            </CollapseHeader>

            <CollapseContent>
              <Row gutter={10}>
                <Col span={5}>
                  <Field.Select name="state" label="Статус" options={options} />
                </Col>
                <Col span={19}>
                  <Field.Input name="websiteUrl" label="Веб-сайт" />
                </Col>
              </Row>
            </CollapseContent>
          </Collapse>

          <Inline className={styles.buttons}>
            {onDismiss && (
              <Button color="sub-primary" onClick={onDismiss}>
                {formatMessage({ id: 'buttons.cancel', defaultMessage: 'Скасувати' })}
              </Button>
            )}

            <Button color="primary" type="submit">
              {formatMessage({ id: 'buttons.save', defaultMessage: 'Зберегти' })}
            </Button>
          </Inline>
        </form>
      )}
    </Form>
  );
};
