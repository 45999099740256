import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button, Inline } from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import type { ITerminal } from 'services/api/core/pos/types';
import { useShops, ShopSelectField } from 'features/shops';

import { TerminalFormSchema } from './TerminalFormSchema';
import styles from './TerminalForm.module.scss';

interface ITerminalForm {
  initialValues: Partial<ITerminal>;
  onSubmit: (values) => void;
  onDismiss?: () => void;
}

export const TerminalForm: FC<ITerminalForm> = ({ initialValues, onSubmit, onDismiss }) => {
  const { formatMessage } = useIntl();
  const { shops } = useShops();

  return (
    <Form
      validate={transformValidationSchema(TerminalFormSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field.Input label="Назва" name="name" />
          <ShopSelectField
            name="shopId"
            label="Торгова точка"
            placeholder="Оберіть торгову точку"
            options={shops}
          />

          <Inline className={styles.buttons}>
            {onDismiss && (
              <Button color="sub-primary" onClick={onDismiss}>
                {formatMessage({ id: 'buttons.cancel', defaultMessage: 'Скасувати' })}
              </Button>
            )}

            <Button color="primary" type="submit">
              {formatMessage({ id: 'buttons.save', defaultMessage: 'Зберегти' })}
            </Button>
          </Inline>
        </form>
      )}
    </Form>
  );
};
