type LoadScriptOptions = {
  async?: boolean;
};

type LoadScriptResult = Promise<Event>;

export const loadScript = (src: string, options?: LoadScriptOptions): LoadScriptResult =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = src;
    script.async = (options && options.async) || true;
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);

    document.body.appendChild(script);
  });
