import { useNavigate } from 'react-router-dom';

import type { ISupportCategory } from 'services/api/core/support/types';

import styles from './SupportCategory.module.scss';

export const SupportCategory = ({ category }: { category: ISupportCategory }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.category} onClick={() => navigate(`${category.id}`)}>
      {category.title}
    </div>
  );
};
