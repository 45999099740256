import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button, Inline, Modal, PageHeader } from '@smart-kasa/ui';

import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useCreateTerminalMutation } from 'services/api/core/pos/api';
import { useShops, ShopSelectField } from 'features/shops';

import { TerminalFormSchema } from 'features/terminals/components/TerminalForm/TerminalFormSchema';
import styles from './TerminalCreate.module.scss';

type TerminalParams = {
  shopId?: string;
};

export const TerminalCreate = () => {
  const { shopId } = useParams<TerminalParams>();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [create, { isLoading }] = useCreateTerminalMutation();
  const { shops } = useShops();

  const onSubmit = useCallback(
    async (fd) => {
      const { data: terminal } = await create(fd).unwrap();
      const url = shopId
        ? `/shops/${shopId}/terminals/${terminal.id}`
        : `/terminals/${terminal.id}`;

      navigate(url, { replace: true });
    },
    [shopId, create, navigate]
  );

  return (
    <Modal className={styles.modal} onClose={() => navigate(-1)}>
      {({ close }) => (
        <Fragment>
          <PageHeader>Додати термінал</PageHeader>

          <Form
            validate={transformValidationSchema(TerminalFormSchema)}
            initialValues={{ shopId: Number(shopId) }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ShopSelectField
                  name="shopId"
                  label="Торгова точка"
                  placeholder="Оберіть торгову точку"
                  options={shops}
                  disabled={!!shopId || isLoading}
                />

                <Inline className={styles.buttons}>
                  <Button color="sub-primary" onClick={close}>
                    {formatMessage({ id: 'buttons.cancel', defaultMessage: 'Скасувати' })}
                  </Button>

                  <Button color="primary" type="submit" disabled={isLoading}>
                    {formatMessage({ id: 'buttons.save', defaultMessage: 'Зберегти' })}
                  </Button>
                </Inline>
              </form>
            )}
          </Form>
        </Fragment>
      )}
    </Modal>
  );
};
