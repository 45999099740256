import { SelectProps } from '@smart-kasa/ui';

import { ColorSelect } from 'components';

import Field from '../Field/Field';

export type ColorSelectFieldProps = Omit<SelectProps, 'value'> & {
  name: string;
  value?: any;
};

export const ColorSelectField = ({ name, ...props }: ColorSelectFieldProps) => (
  <Field name={name}>
    {({ input, ...rest }) => <ColorSelect {...input} {...rest} {...props} />}
  </Field>
);
