export type Colors =
  | 'transparent'
  | 'orange'
  | 'yellow'
  | 'blue'
  | 'cyan'
  | 'grey'
  | 'violet'
  | 'green';

export const COLORS: { [key: number]: Colors } = {
  0: 'transparent',
  1: 'orange',
  2: 'yellow',
  3: 'blue',
  4: 'cyan',
  5: 'grey',
  6: 'violet',
  7: 'green',
};
