import { FC } from 'react';
import cs from 'classnames';
import dayjs from 'dayjs';

import { Datepicker } from 'components';

import styles from './RangePicker.module.scss';

export interface IRangePicker {
  from: Date | string;
  to: Date | string;
  onChangeDateEnd: (value: any) => void;
  onChangeDateStart: (value: any) => void;
  className?: string;
  width?: 'auto' | 'full';
}

const safeParsedDate = (date) => (date ? dayjs(date).toDate() : null);

export const RangePicker: FC<IRangePicker> = ({
  width,
  from,
  to,
  className,
  onChangeDateEnd,
  onChangeDateStart,
}) => {
  const startDate = safeParsedDate(from);
  const endDate = safeParsedDate(to);

  return (
    <div
      className={cs(styles.datepicker, className, width && styles[width], {
        [styles.widthAuto]: width === 'auto',
        [styles.widthFull]: width === 'full',
      })}
    >
      <Datepicker
        selected={startDate}
        dateFormat="dd.MM.yyyy"
        onChange={onChangeDateStart}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
      />
      <Datepicker
        selected={endDate}
        dateFormat="dd.MM.yyyy"
        onChange={onChangeDateEnd}
        selectsEnd
        startDate={startDate}
        minDate={startDate}
        endDate={endDate}
      />
    </div>
  );
};

RangePicker.defaultProps = {
  width: 'auto',
};
