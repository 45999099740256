import { Skeleton } from 'components';
import styles from './CategoryPreloader.module.scss';
import { Fragment } from 'react';

export const CategoryPreloader = () => (
  <Fragment>
    <div className={styles.folder}>
      <Skeleton width={40} height={40} />

      <div className={styles.info}>
        <Skeleton width="50%" height={12} />
        <Skeleton width="90%" height={12} />
      </div>
    </div>

    <div className={styles.folder}>
      <Skeleton width={40} height={40} />

      <div className={styles.info}>
        <Skeleton width="70%" height={12} />
        <Skeleton width="20%" height={12} />
      </div>
    </div>
  </Fragment>
);
