import { Route, Routes } from 'react-router-dom';

import { TerminalList } from './routes/TerminalList';
import { TerminalCreate } from './routes/TerminalCreate';
import { TerminalEdit } from './routes/TerminalEdit';
import { TerminalDelete } from './routes/TerminalDelete';

export const Terminals = () => (
  <Routes>
    <Route element={<TerminalList />}>
      <Route index element={null} />
      <Route path="add" element={<TerminalCreate />} />
      <Route path=":id" element={<TerminalEdit />} />
      <Route path=":id/delete" element={<TerminalDelete />} />
    </Route>
  </Routes>
);
