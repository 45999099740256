import { Col, Row, Title, Hint, Card, Button } from '@smart-kasa/ui';

import { Field } from 'components';

import styles from './ProfilePinCode.module.scss';

export const ProfilePinCode = ({ disabled }) => (
  <Card>
    <Title variant="h2">Налаштування доступу</Title>
    <Hint>Ваш персональний код доступу на активовані термінали</Hint>

    <Row gutter={10} offset="between" className={styles.container}>
      <Col span={12}>
        <Field.PinCode name="pinCode" label="Код доступу до каси" />
      </Col>
    </Row>

    <Row gutter={10}>
      <Col span={12}>
        <Button type="submit" color="primary" width="full" disabled={disabled}>
          Зберегти
        </Button>
      </Col>
    </Row>
  </Card>
);
