import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { InlineLink } from 'components';
import { useCreateStockMutation } from 'services/api/core/inventory/api';

import { StockForm } from '../../components/StockForm';
import { StockFormErrorSchema } from '../../components/StockForm/StockFormSchema';

export const StockCreate = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [create] = useCreateStockMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          const errors = StockFormErrorSchema.get(err);
          const info = Object.values(errors).join(', ');

          toast.error(info);

          return errors;
        });
    },
    [create, navigate, toast]
  );

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          Облік товарів
        </InlineLink>
      }
    >
      <StockForm
        initialValues={{ countingEnabled: true }}
        onCancel={handleDismiss}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};
