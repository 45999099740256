import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DataTable,
  Icon,
  ButtonIcon,
  IDataTableColumn,
  InlineSpace,
  Page,
} from '@smart-kasa/ui';

import useConfirm from 'hooks/useConfirm';
import { ButtonIconLink } from 'components';
import { useDeleteRoleMutation, useGetRolesQuery } from 'services/api/core/roles/api';
import type { IAMRole } from 'services/api/core/roles/types';
import { IAMPolicyActionType } from 'services/api/core/roles/enums';

import { POLICY_PERMISSIONS_MESSAGES } from 'features/roles/constants/messages';
import styles from './RoleList.module.scss';

export const RoleList: React.FC = () => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { data: roles, isLoading } = useGetRolesQuery();
  const [destroy] = useDeleteRoleMutation();

  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити посаду?');
    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IAMRole>[] = [
    {
      key: 'name',
      sort: false,
      title: 'Посада',
    },
    {
      key: 'usersCount',
      sort: false,
      title: 'Кількість',
    },
    {
      key: 'policyInfo',
      width: 'content',
      title: 'Права доступу',
      render: (policyInfo: IAMPolicyActionType[]) =>
        policyInfo && (
          <div className={styles.description}>
            {policyInfo.flatMap((id) => POLICY_PERMISSIONS_MESSAGES[id]).join(', ')}
          </div>
        ),
    },
    {
      key: 'id',
      render: (id: number) => (
        <Fragment>
          <ButtonIconLink to={`/roles/${id}`} onClick={(event) => event.stopPropagation()}>
            <Icon size="large" name="eye" />
          </ButtonIconLink>
          <InlineSpace />
          <ButtonIcon
            className={styles.button}
            onClick={(event) => {
              handleDelete(id);

              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Fragment>
      ),
    },
  ];

  return (
    <Page
      title="Посади"
      actions={
        <Button
          width="wide"
          color="primary"
          rightIcon={<Icon name="add" size="small" />}
          onClick={() => navigate('create')}
        >
          Додати
        </Button>
      }
    >
      <DataTable
        rowIdKey="id"
        data={roles?.data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={(employee) => navigate(`${employee.id}`)}
        emptyText="Розділ пустий. Посад не знайдено."
      />
    </Page>
  );
};
