import { Fragment } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Button, ButtonText, Icon, InlineSpace, Label } from '@smart-kasa/ui';

import { Field } from 'components';
import type { IEmployee } from 'services/api/core/employees/types';

import styles from './ShopStaffForm.module.scss';

export type IShopStaffRow = FieldArrayRenderProps<any, any> & {
  employees?: IEmployee[];
  onRemove?: (staff) => void;
};

export const ShopStaffRow = ({ fields, employees = [], onRemove }: IShopStaffRow) => {
  const { value: values } = fields;

  const filterEmployees = (option, input) => {
    const isSelected = values.some((item) => item.employeeId === option.value);

    return !isSelected && option.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Fragment>
      {values.length > 0 && <Label>ПІБ працівника</Label>}

      {values.map((employee, index) => (
        <div key={index} className={styles.row}>
          <Field.Select
            placeholder="Оберіть зі списку"
            name={`staff[${index}].employeeId`}
            options={employees}
            getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
            getOptionValue={({ id }) => id}
            filterOption={filterEmployees}
            disabled={Boolean(employee && employee.id)}
          />
          <InlineSpace />
          <Button
            type="button"
            color="light"
            onClick={() => {
              fields.remove(index);

              onRemove && onRemove(employee);
            }}
          >
            <Icon name="trash" />
          </Button>
        </div>
      ))}
      <ButtonText onClick={() => fields.push({})}>
        <Icon name="plus" color="primary" size="small" />
        <InlineSpace />
        Додати працівника
      </ButtonText>
    </Fragment>
  );
};
