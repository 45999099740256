import { Route, Routes } from 'react-router-dom';

import { StatsGoods } from './routes/StatsGoods';
import { StatsSales } from './routes/StatsSales';

export const Stats = () => (
  <Routes>
    <Route path="goods" element={<StatsGoods />} />
    <Route path="*" element={<StatsSales />} />
  </Routes>
);
