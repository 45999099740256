import React from 'react';

import { useGetUnitsQuery } from 'services/api/core/units/api';
import { IUnitType } from 'services/api/core/units/types';

interface IUnitTypesContext {
  unitTypes?: IUnitType[];
}

export const UnitTypesContext = React.createContext<IUnitTypesContext>({});

export const UnitTypesContextProvider: React.FC = ({ children }) => {
  const { data: units } = useGetUnitsQuery();

  return (
    <UnitTypesContext.Provider value={{ unitTypes: units?.data }}>
      {children}
    </UnitTypesContext.Provider>
  );
};
