import { ButtonLink } from 'components/ButtonLink';

import Device from 'assets/pay_device.png';
import styles from './TerminalAdd.module.scss';

export const TerminalAdd = () => (
  <div className={styles.item}>
    <img src={Device} alt="Додайте Смарт Касу" className={styles.image} />
    <div className={styles.title}>Смарт Каса</div>
    <div className={styles.description}>
      Додайте Смарт Касу до Вашого кабінету для синхронізації даних кабінету та пристрою
    </div>
    <ButtonLink width="full" color="primary" to="add">
      Додати
    </ButtonLink>
  </div>
);
