import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';

import { BillingContext } from 'context/BillingContext';

export const RestrictedRoute = () => {
  const { subscription } = useContext(BillingContext);
  const location = useLocation();

  if (subscription && !subscription.isActive) {
    return <Navigate to="/billing/plans" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
