import { Route, Routes } from 'react-router-dom';

import { AuthLogin } from './routes/AuthLogin';
import { AuthRegistration } from './routes/AuthRegistration';
import { AuthRecovery } from './routes/AuthRecovery';

export const Auth = () => (
  <Routes>
    <Route path="sign-in" element={<AuthLogin />} />
    <Route path="sign-up" element={<AuthRegistration />} />
    <Route path="password-recovery" element={<AuthRecovery />} />
  </Routes>
);
