import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const ProductComponentSchema = yup.object().shape({
  itemId: yup.string().required(),
  quantity: yup.string().required().max(8, 'Кількість занадто велика'),
});

export const ProductComponentFormSchema = yup.object().shape({
  cards: yup.array().of(ProductComponentSchema),
});

export const ProductComponentFormErrorSchema = schemaError({
  default: 'Невірний запис',
  itemId: {
    default: 'Помилка збереження товару',
  },
});
