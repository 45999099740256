import { useNavigate } from 'react-router-dom';
import { Button, IButtonProps } from '@smart-kasa/ui';

export type ButtonLinkProps = IButtonProps & {
  to: string;
};

export const ButtonLink = ({ onClick, to, ...props }: ButtonLinkProps) => {
  const navigate = useNavigate();

  return (
    <Button
      {...props}
      onClick={(event) => {
        onClick && onClick(event);

        navigate(to);
      }}
    />
  );
};
