export const convertToCSV = (items) => {
  const replacer = (_, value) => (value === null ? '' : value);
  const header = Object.keys(items[0]);
  const csv = [
    header.join(','),
    ...items.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    ),
  ].join('\r\n');

  return csv;
};
