import { ActionButton, Icon, Tooltip } from '@smart-kasa/ui';

import useConfirm from 'hooks/useConfirm';
import { useBulkDeleteProductsMutation } from 'services/api/core/inventory/api';

import type { BulkOperation } from './types';

export const ProductsBulkDelete = ({ products, onComplete }: BulkOperation) => {
  const { confirm } = useConfirm();
  const [bulkDestroy] = useBulkDeleteProductsMutation();

  const ids = products.map(({ id }) => id);

  const handleDelete = async () => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити товари?');

    if (isConfirmed) {
      bulkDestroy({ ids });
      onComplete && onComplete();
    }
  };

  return (
    <ActionButton onClick={handleDelete}>
      <Tooltip text="Видалення обраних товарів">
        <Icon name="trash" />
      </Tooltip>
    </ActionButton>
  );
};
