import { ActionButton, Icon, Tooltip } from '@smart-kasa/ui';

import { useBulkChangeProductFreePriceMutation } from 'services/api/core/inventory/api';

import type { BulkOperation } from './types';

export const ProductsBulkFreePrice = ({ products, onComplete }: BulkOperation) => {
  const isFreePrice = products.every(({ isFreePrice }) => isFreePrice === true);
  const ids = products.map(({ id }) => id);

  const [bulkUpdate] = useBulkChangeProductFreePriceMutation();

  const handleAction = () => {
    bulkUpdate({ ids, isFreePrice: !isFreePrice });
    onComplete && onComplete();
  };

  return (
    <ActionButton active={isFreePrice} onClick={handleAction}>
      <Tooltip text="Продаж за вільною ціною">
        <Icon name="cash" />
      </Tooltip>
    </ActionButton>
  );
};
