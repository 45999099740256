import { Fragment, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PageHeader, Card, DropDown, Button, Icon, InlineSpace } from '@smart-kasa/ui';

import { ImportNavigation } from '../ImportNavigation';

export interface IImportLayout {
  actions?: ReactNode;
  children: ReactNode;
}

export const ImportLayout = ({ actions, children }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <PageHeader
        actions={
          <Fragment>
            {actions && (
              <Fragment>
                {actions}
                <InlineSpace />
              </Fragment>
            )}
            <DropDown>
              <Button color="primary" rightIcon={<Icon name="arrowDown" size="small" />}>
                Завантажити файл
              </Button>
              <DropDown.Menu>
                <DropDown.Item
                  icon="upload"
                  onClick={() => navigate('/inventory/import/catalog/create')}
                >
                  Завантажити файл імпорту товарів
                </DropDown.Item>
                <DropDown.Item
                  icon="upload"
                  onClick={() => navigate('/inventory/import/stock/create')}
                >
                  Завантажити файл імпорту залишків
                </DropDown.Item>
              </DropDown.Menu>
            </DropDown>
          </Fragment>
        }
      >
        <FormattedMessage id="navigation.import" defaultMessage="Імпорт" />
      </PageHeader>

      <ImportNavigation />

      <Card>{children}</Card>
    </Fragment>
  );
};
