import * as yup from 'yup';

import { schemaName, schemaPassword, schemaPhone } from 'utils/sharedSchemas';
import { schemaError } from 'utils/schemaError';

export const AuthRegistrationPhoneSchema = yup.object().shape({
  phoneNumber: schemaPhone.required("Телефон обов'язкове для заповнення поле"),
});

export const AuthRegistrationPasswordSchema = yup.object().shape({
  password: schemaPassword,
  passwordConfirmation: yup
    .string()
    .required("Пароль обов'язкове для заповнення поле")
    .oneOf([yup.ref('password'), null], 'Перевірте коректність вводу. Паролі не збігаються.'),
});

export const AuthRegistrationNameSchema = yup.object().shape({
  firstName: schemaName.required(),
  lastName: schemaName.required(),
});

export const AuthRegistrationCodeSchema = yup.object().shape({
  code: yup.string().required(),
});

export const AuthRegistrationEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Недопустимий формат. Перевірте правильність вводу та спробуйте ще раз'),
});

export const AuthRegistrationErrorSchema = schemaError({
  default: 'Неправильний номер телефону або пароль. Перевірте, будь ласка, коректність вводу',
  value: {
    default:
      'Номер не знайдено. Перевірте коректність вводу або зареєструйте особистий кабінет просто зараз.',
    taken: 'Користувач з таким номером вже зареєстрований.',
    invalid: 'Неправильний код, перевірте отриманий код з смс та спробуйте ще раз',
  },
});

export const AuthRegistrationEmailErrorSchema = schemaError({
  default: 'Неправильний формат',
  email: {
    default: 'Неправильна адреса електронної скриньки. Перевірте, будь ласка, коректність вводу',
    taken: 'Користувач з такою адресою електронної скриньки вже зареєстрований.',
  },
});
