import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cs from 'classnames';
import SimpleBar from 'simplebar-react';
import { IMenuProps, Icon, Menu } from '@smart-kasa/ui';

import useEventListener from 'hooks/useEventListener';

import { MenuExternalLink, MenuLink } from './MenuLink';
import { MenuGroup } from './MenuGroup';
import styles from './Navigation.module.scss';

export type NavigationProps = Omit<IMenuProps, 'children'> & {
  resticted?: boolean;
};

export const Navigation = ({ resticted = false, ...rest }: NavigationProps) => {
  const { formatMessage } = useIntl();
  const simpleBarRef = useRef<SimpleBar>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = () => {
    const scrollElement = simpleBarRef.current?.getScrollElement();

    if (scrollElement) {
      setIsScrollable(scrollElement.scrollHeight > scrollElement.clientHeight);
    }
  };

  useEffect(handleScroll, []);
  useEventListener('resize', handleScroll);

  return (
    <Menu {...rest}>
      <SimpleBar
        className={cs(styles.scrollbar, { [styles.scrollable]: isScrollable })}
        ref={simpleBarRef}
      >
        <MenuLink
          title={formatMessage({ id: 'navigation.shops' })}
          icon={<Icon name="business" />}
          to="/"
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.terminals' })}
          icon={<Icon name="smartKasa" />}
          to="/terminals"
        />
        <MenuGroup
          title={formatMessage({ id: 'navigation.members' })}
          namespace="/employees/*"
          icon={<Icon name="members" />}
          disabled={resticted}
        >
          <MenuLink title={formatMessage({ id: 'navigation.members.employees' })} to="/employees" />
          <MenuLink
            title={formatMessage({ id: 'navigation.members.roles' })}
            to="/roles"
            disabled
          />
        </MenuGroup>
        <MenuGroup
          title={formatMessage({ id: 'navigation.inventory' })}
          namespace="/inventory/*"
          icon={<Icon name="products" />}
          disabled={resticted}
        >
          <MenuLink
            title={formatMessage({ id: 'navigation.inventory.products' })}
            to="/inventory"
            disabled={resticted}
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.inventory.stocks' })}
            to="/inventory/stock"
            disabled={resticted}
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.inventory.subgroups' })}
            to="/inventory/subgroups"
            disabled={resticted}
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.inventory.import' })}
            to="/inventory/import/catalog"
            disabled={resticted}
          />
        </MenuGroup>

        <MenuLink
          title={formatMessage({ id: 'navigation.shifts' })}
          icon={<Icon name="tax" />}
          to="/shifts"
          disabled={resticted}
        />
        <MenuGroup
          title={formatMessage({ id: 'navigation.receipts' })}
          namespace="/receipts/*"
          icon={<Icon name="receipt" />}
          disabled={resticted}
        >
          <MenuLink
            title={formatMessage({ id: 'navigation.receipts.all' })}
            to="/receipts"
            disabled={resticted}
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.receipts.archive' })}
            to="/receipts/archive"
            disabled={resticted}
          />
        </MenuGroup>
        <MenuGroup
          title={formatMessage({ id: 'navigation.stats' })}
          namespace="/stats/*"
          icon={<Icon name="statistic" />}
          disabled={resticted}
        >
          <MenuLink
            title={formatMessage({ id: 'navigation.stats.sales' })}
            to="/stats/sales"
            disabled={resticted}
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.stats.goods' })}
            to="/stats/goods"
            disabled={resticted}
          />
        </MenuGroup>
        <MenuGroup
          title={formatMessage({ id: 'navigation.reports' })}
          namespace="/transactions/*"
          icon={<Icon name="files" />}
          disabled={resticted}
        >
          <MenuLink
            title={formatMessage({ id: 'navigation.reports.transactions' })}
            to="/transactions"
            disabled={resticted}
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.reports.taxes' })}
            to="/reports"
            disabled={resticted}
          />
        </MenuGroup>
        {/* <MenuLink
          title={formatMessage({ id: 'navigation.market' })}
          icon={<Icon name="shopApps" />}
          to="/market"
          badge="NEW"
          disabled={resticted}
        /> */}
        <MenuGroup
          title={formatMessage({ id: 'navigation.billing' })}
          namespace="/billing/*"
          icon={<Icon name="creditCard" />}
        >
          <MenuLink title={formatMessage({ id: 'navigation.billing.plans' })} to="/billing/plans" />
          <MenuLink
            title={formatMessage({ id: 'navigation.billing.balance' })}
            to="/billing/balance"
          />
          <MenuLink title={formatMessage({ id: 'navigation.billing.cards' })} to="/billing/cards" />
          <MenuLink
            title={formatMessage({ id: 'navigation.billing.history' })}
            to="/billing/history"
          />
        </MenuGroup>
        <MenuLink
          title={formatMessage({ id: 'navigation.support' })}
          icon={<Icon name="support" />}
          to="/faq"
        />
        <MenuExternalLink
          title={formatMessage({ id: 'navigation.news' })}
          icon={<Icon name="news" />}
          url="https://www.smartkasa.ua/news/"
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.notifications' })}
          icon={<Icon name="mail" />}
          to="/notifications"
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.settings' })}
          icon={<Icon name="settings" />}
          to="/profile"
        />
      </SimpleBar>
    </Menu>
  );
};
