import { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { PageHeader, Tabs } from '@smart-kasa/ui';

import { useAppDispatch } from 'hooks';
import useToast from 'hooks/useToast';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import type {
  IAMChangePassword,
  IAMChangePinCode,
  IAMProfileUpdateFormData,
} from 'services/api/core/iam/types';
import { iamApi } from 'services/api/core/iam/api';

import { useProfile } from './hooks/useProfile';
import { ProfileInfo } from './components/ProfileInfo';
import { ProfileSecurity } from './components/ProfileSecurity';
import { ProfilePinCode } from './components/ProfilePinCode';
import {
  ProfileFormErrorSchema,
  ProfilePasswordErrorSchema,
  ProfilePasswordSchema,
  ProfilePinCodeSchema,
  ProfileSchema,
} from './ProfileSchema';
import styles from './Profile.module.scss';

enum ProfileStep {
  INFO,
  SECURITY,
  PIN_CODE,
}

const SCHEMA_BY_STEP = {
  [ProfileStep.INFO]: ProfileSchema,
  [ProfileStep.SECURITY]: ProfilePasswordSchema,
  [ProfileStep.PIN_CODE]: ProfilePinCodeSchema,
};

export const Profile = () => {
  const { toast } = useToast();
  const { profile } = useProfile();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<ProfileStep>(ProfileStep.INFO);
  const currentSchema = SCHEMA_BY_STEP[step];

  const submitUpdateProfile = useCallback(
    (data: IAMProfileUpdateFormData) =>
      dispatch(iamApi.endpoints.updateProfile.initiate(data))
        .unwrap()
        .then(() => toast.success('Дані збережено успішно'))
        .catch((err) => {
          return ProfileFormErrorSchema.get(err);
        }),
    [dispatch, toast]
  );

  const submitResetPassword = useCallback(
    (data: IAMChangePassword) =>
      dispatch(iamApi.endpoints.changePassword.initiate(data))
        .unwrap()
        .then(() => toast.success('Дані збережено успішно'))
        .catch((err) => {
          return ProfilePasswordErrorSchema.get(err);
        }),
    [dispatch, toast]
  );

  const submitPinCode = useCallback(
    (data: IAMChangePinCode) =>
      dispatch(iamApi.endpoints.changePinCode.initiate(data))
        .unwrap()
        .then(() => toast.success('Дані збережено успішно'))
        .catch((err) => {
          return ProfilePasswordErrorSchema.get(err);
        }),
    [dispatch, toast]
  );

  const handleDeletePhoto = () => dispatch(iamApi.endpoints.deleteProfilePhoto.initiate());
  const handleSubmit = (data: IAMProfileUpdateFormData | IAMChangePassword | IAMChangePinCode) => {
    if (step === ProfileStep.INFO) {
      submitUpdateProfile(data as IAMProfileUpdateFormData);
    }

    if (step === ProfileStep.SECURITY) {
      submitResetPassword(data as IAMChangePassword);
    }

    if (step === ProfileStep.PIN_CODE) {
      submitPinCode(data as IAMChangePinCode);
    }
  };

  return (
    <Form
      validate={transformValidationSchema(currentSchema)}
      initialValues={profile}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader>Мій профіль</PageHeader>
          <Tabs
            className={styles.content}
            onChange={setStep}
            tabs={[
              {
                id: 'info',
                title: 'Основна інформація',
                content: <ProfileInfo disabled={!dirty} onRemoveAttachment={handleDeletePhoto} />,
              },
              {
                id: 'security',
                title: 'Безпека та Пароль',
                content: <ProfileSecurity disabled={!dirty} />,
              },
              {
                id: 'access',
                title: 'Налаштування доступу',
                content: <ProfilePinCode disabled={!dirty} />,
              },
            ]}
          />
        </form>
      )}
    </Form>
  );
};
