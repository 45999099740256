import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { useCreateReceiptMutation } from 'services/api/core/receipts/api';
import { ReceiptState } from 'services/api/core/receipts/enums';

import { ReceiptFormErrorSchema } from 'features/receipts/components/ReceiptForm/ReceiptFormSchema';
import { ReceiptForm } from 'features/receipts/components/ReceiptForm';

export const ReceiptCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateReceiptMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (receipt) => {
      create(receipt)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return ReceiptFormErrorSchema.get(err);
        });
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <PageHeader>
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Відкладені чеки
        </InlineLink>
      </PageHeader>

      <ReceiptForm
        initialValues={{ state: ReceiptState.archived }}
        onCancel={handleDismiss}
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
};
