import { Fragment, useMemo } from 'react';
import dayjs from 'dayjs';
import { Button, Card, CardTitle, Icon, InlineSpace, PageHeader, Pagination } from '@smart-kasa/ui';

import { useQueryPagination, useQuery } from 'hooks';
import useModal from 'hooks/useModal';
import { dateToISO8601String } from 'utils/date';
import {
  useGetTransactionStatsQuery,
  useGetTransactionsQuery,
} from 'services/api/core/transactions/api';
import { useLazyExportTransactionsQuery } from 'services/api/core/export/api';
import type { IPaymentTransactionStats } from 'services/api/core/transactions/types';
import { StatsCards } from 'features/stats/components/StatsCards';

import { TransactionTable } from 'features/transactions/components/TransactionTable';
import { TransactionFilter } from 'features/transactions/components/TransactionFilter';

const STATS_MAPPING: Record<keyof Omit<IPaymentTransactionStats, 'id'>, string> = {
  todayAmount: 'Сьогодні',
  weekAmount: 'Тиждень',
  monthAmount: 'Місяць',
};

export const TransactionList = () => {
  const { onPageChange, onSortChange } = useQueryPagination();
  const { query, push: setQuery } = useQuery();
  const params = useMemo(
    () => ({
      dateEnd: dateToISO8601String(dayjs().toDate()),
      dateStart: dateToISO8601String(dayjs().toDate()),
      ...query,
    }),
    [query]
  );
  const { isShowing, showModal, hideModal } = useModal();
  const { data: transactions, isLoading } = useGetTransactionsQuery(params);
  const { data: statistics } = useGetTransactionStatsQuery();
  const [download] = useLazyExportTransactionsQuery();

  const stats = useMemo(
    () =>
      Object.keys(STATS_MAPPING).map((key) => ({
        title: STATS_MAPPING[key],
        value: statistics?.data[key],
        money: true,
      })),
    [statistics]
  );

  return (
    <Fragment>
      <PageHeader
        actions={
          <Fragment>
            <Button rightIcon={<Icon name="template" />} onClick={() => download(params)}>
              Експорт
            </Button>
            <InlineSpace />
            <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
              Фільтр
            </Button>
          </Fragment>
        }
      >
        Транзакції
      </PageHeader>

      {stats && <StatsCards data={stats} />}

      <Card>
        <CardTitle>Останні транзакції</CardTitle>
        <TransactionTable
          data={transactions?.data}
          isLoading={isLoading}
          sortMode={params.sortMode}
          sortColumn={params.sortColumn}
          onSortChange={onSortChange}
        />
        <Pagination
          totalItems={transactions?.meta?.totalCount}
          currentPage={transactions?.meta?.currentPage}
          perPage={transactions?.meta?.limitValue}
          onPageChange={onPageChange}
        />
      </Card>

      <TransactionFilter
        show={isShowing}
        onClose={hideModal}
        initialValues={params}
        onSubmit={setQuery}
      />
    </Fragment>
  );
};
