import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { TAX_GROUP } from 'constants/taxGroup';
import { CLASSIFIER_TYPE } from 'constants/classifierType';
import { useCreateProductMutation } from 'services/api/core/inventory/api';
import type { IProduct } from 'services/api/core/inventory/types';

import { ProductForm } from '../../components/ProductForm';
import { ProductFormErrorSchema } from '../../components/ProductForm/ProductFormSchema';

export const ProductCreate = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [create] = useCreateProductMutation();

  const initialValues: Partial<IProduct> = useMemo(
    () => ({
      categoryId,
      unitTypeId: 2009,
      taxGroupId: TAX_GROUP.GENERAL,
      classifierTypeId: CLASSIFIER_TYPE.NONE,
    }),
    [categoryId]
  );

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return ProductFormErrorSchema.get(err);
        });
    },
    [create, navigate]
  );

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          Створення товару/послуги
        </InlineLink>
      }
    >
      <ProductForm initialValues={initialValues} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Page>
  );
};
