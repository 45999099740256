import cs from 'classnames';
import { Icon } from '@smart-kasa/ui';

import styles from '../BillingCreditCard/BillingCreditCard.module.scss';

export const BillingInvoiceAdd = ({ className, ...rest }) => (
  <a className={cs(styles.card, styles.add, styles.invoice, className)} {...rest}>
    <span className={styles.image}>
      <Icon name="receipt" size="xxx-large" />
    </span>
    <span className={styles.type}>Отримати рахунок</span>
  </a>
);
