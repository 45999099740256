import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { ISupportCategory, ISupportQuestion, SupportQuestionListParams } from './types';

export const supportApi = coreApi
  .enhanceEndpoints({ addTagTypes: ['SupportCategory', 'SupportQuestion'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFaqCategories: builder.query<CoreResponse<ISupportCategory[]>, void>({
        query: () => ({
          url: 'faq/categories',
        }),
        providesTags: ['SupportCategory'],
      }),
      getFaqQuestions: builder.query<CoreResponse<ISupportQuestion[]>, SupportQuestionListParams>({
        query: (params?) => ({
          url: 'faq/questions',
          params,
        }),
        providesTags: ['SupportQuestion'],
      }),
    }),
  });

export const { useGetFaqCategoriesQuery, useGetFaqQuestionsQuery } = supportApi;
