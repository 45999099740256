import { createContext, ReactNode, useCallback } from 'react';

import type { IWallet } from 'services/api/core/wallet/types';
import type { IBillingSubscription } from 'services/api/core/billing/types';
import { useCurrentBillingPlanQuery } from 'services/api/core/billing/api';
import { useGetWalletQuery } from 'services/api/core/wallet/api';

export type BillingContextProps = {
  subscription?: IBillingSubscription;
  wallet?: IWallet;
  isLoading: boolean;
  refresh: () => void;
};

export const BillingContext = createContext<BillingContextProps>({} as any);

export const BillingContextProvider = ({ children }: { children: ReactNode }) => {
  const { data: wallet, refetch: refetchWallet, isLoading: isWalletLoading } = useGetWalletQuery();
  const {
    data: subscription,
    refetch: refetchSubscription,
    isLoading: isSubscriptionLoading,
  } = useCurrentBillingPlanQuery();

  const refresh = useCallback(() => {
    refetchSubscription();
    refetchWallet();
  }, [refetchWallet, refetchSubscription]);

  return (
    <BillingContext.Provider
      value={{
        subscription: subscription?.data,
        wallet: wallet?.data,
        isLoading: isSubscriptionLoading || isWalletLoading,
        refresh,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};
