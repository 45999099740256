import { createContext } from 'react';

import useModal from 'hooks/useModal';
import { FeedbackDialog } from 'features/support/components/FeedbackDialog';

interface IFeedbackContext {
  isVisible: boolean;
  showModal: () => void;
  hideModal: () => void;
}

export const FeedbackContext = createContext<IFeedbackContext>({
  isVisible: false,
  showModal: () => {},
  hideModal: () => {},
});

export const FeedbackContextProvider = ({ children }) => {
  const { isShowing, showModal, hideModal } = useModal();

  return (
    <FeedbackContext.Provider value={{ isVisible: isShowing, showModal, hideModal }}>
      {children}

      <FeedbackDialog isVisible={isShowing} onClose={hideModal} />
    </FeedbackContext.Provider>
  );
};
