import { useCallback, useContext, useState } from 'react';
import { Button, Col, InlineSpace, Modal, Paragraph, Row } from '@smart-kasa/ui';

import useConfirm from 'hooks/useConfirm';
import useToast from 'hooks/useToast';
import { BillingPlan } from 'features/billing/components/BillingPlan';
import { IBillingPlan } from 'services/api/core/billing/types';
import { BillingContext } from 'context/BillingContext';
import {
  useActivateBillingPlanMutation,
  useGetBillingPlansQuery,
} from 'services/api/core/billing/api';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';

import styles from './BillingTariffPlans.module.scss';

export const BillingTariffPlans = () => {
  const { toast } = useToast();
  const { confirm } = useConfirm();
  const [isActivation, setIsActivation] = useState<boolean>(false);
  const { subscription, refresh } = useContext(BillingContext);
  const { data: plans } = useGetBillingPlansQuery();
  const [activatePlan] = useActivateBillingPlanMutation();

  const handleActivate = async (plan: IBillingPlan) => {
    const isConfirmed = await confirm(
      `Натискаючи кнопку "Підтвердити" буде ініційовано поповнення балансу. Попередній тарифний план буде деактивовано. Ви дійсно бажаєте підключити тарифний план "${plan.title}"?`
    );

    if (isConfirmed) activate(plan);
  };

  const activate = useCallback(
    (plan: IBillingPlan) => {
      activatePlan(plan.id)
        .unwrap()
        .then(({ data: subscription }) => {
          if (subscription.invoiceUrl) {
            const wayforpay = new window.Wayforpay();

            wayforpay.invoice(subscription.invoiceUrl);
          }

          if (subscription.state === BillingSubscriptionState.ACTIVE) {
            toast.success('Тарифний план успішно активовано');
            refresh();
          }

          if (subscription.state === BillingSubscriptionState.WAITING) {
            setIsActivation(true);
          }
        })
        .catch(() => {
          toast.error('Не вдається активувати тарифний план');
          setIsActivation(false);
        });
    },
    [activatePlan, refresh, toast]
  );

  const onActivate = () => {
    refresh();
    setIsActivation(false);
  };

  return (
    <>
      <Row gutter={10}>
        {plans?.data.map((plan) => (
          <Col span={8} key={plan.id}>
            <BillingPlan
              plan={plan}
              onActivate={handleActivate}
              isActive={(plan) => plan.id === (subscription && subscription.planId)}
            />
          </Col>
        ))}
      </Row>

      <Modal show={isActivation} onClose={onActivate} className={styles.dialog}>
        <Paragraph>Платіж передано в обробку</Paragraph>
        <InlineSpace direction="vertical" size={20} />
        <Button color="primary" width="wide" onClick={onActivate}>
          Зрозуміло
        </Button>
      </Modal>
    </>
  );
};
