import { CoreResponse } from 'types/api';

import { coreApi } from '../api';
import { BillingHistoryQuery, IBillingInvoice } from './types';

const invoiceApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<CoreResponse<IBillingInvoice[]>, BillingHistoryQuery>({
      query: (params?) => ({ url: 'billing/invoices', params }),
    }),
    findInvoice: builder.query<CoreResponse<IBillingInvoice>, string>({
      query: (id) => ({ url: `billing/invoices/${id}` }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetInvoicesQuery, useFindInvoiceQuery } = invoiceApi;
