export enum PaymentNetwork {
  undefined = 0,
  mastercard = 5,
  visa = 4,
  americanExpress = 3,
  prostir = 9,
  unionPay = 6,
  cash = 100,
  other = 101,
}

export enum TransactionPaymentType {
  pay = 0,
  reversal = 1,
  refund = 2,
}

export enum TransactionType {
  cash = 0,
  cashless = 1,
  nonCash = 2,
}
