import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '@smart-kasa/ui';

import { useDeleteShopMutation } from 'services/api/core/shop/api';

export const ShopDelete = () => {
  const { shopId } = useParams();
  const [destroy] = useDeleteShopMutation();
  const navigate = useNavigate();

  const onDestroy = useCallback(async () => {
    await destroy(Number(shopId));

    navigate(-1);
  }, [shopId, destroy, navigate]);

  return (
    <ConfirmDialog onClose={() => navigate(-1)} onConfirm={onDestroy}>
      Ви дійсно бажаєте видалити торгову точку?
    </ConfirmDialog>
  );
};
