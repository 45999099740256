import { CoreResponse } from 'types/api';

import { coreApi } from '../api';
import type { IBillingCard, IWallet } from './types';
import type { IBillingInvoice } from '../invoices/types';

const walletApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query<CoreResponse<IWallet>, void>({
      query: () => ({ url: 'billing/balance' }),
    }),
    creditBalance: builder.mutation<CoreResponse<IBillingInvoice>, number>({
      query: (amount) => ({
        url: 'billing/balance/credit',
        method: 'POST',
        body: { invoice: { amount } },
      }),
    }),
    getCreditCards: builder.query<CoreResponse<IBillingCard[]>, void>({
      query: () => ({ url: 'billing/cards' }),
    }),
    addCreditCard: builder.query<CoreResponse<IBillingInvoice>, void>({
      query: () => ({ url: 'billing/cards/new' }),
    }),
    updateCreditCard: builder.mutation<void, Pick<IBillingCard, 'id'> & Partial<IBillingCard>>({
      query: ({ id, ...data }) => ({
        url: `billing/cards/${id}`,
        method: 'PUT',
        data,
      }),
    }),
    deleteCreditCard: builder.mutation<void, number>({
      query: (id) => ({
        url: `billing/cards/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWalletQuery,
  useCreditBalanceMutation,
  useGetCreditCardsQuery,
  useLazyAddCreditCardQuery,
  useAddCreditCardQuery,
  useUpdateCreditCardMutation,
  useDeleteCreditCardMutation,
} = walletApi;
