import { useContext } from 'react';

import { DigitalSignatureContext } from './DigitalSignatureContext';

export const useDigitalSignature = () => {
  const { openDialog } = useContext(DigitalSignatureContext);

  const getDigitalSignature = () =>
    new Promise((resolve) => {
      openDialog({ actionCallback: resolve });
    });

  return { getDigitalSignature };
};
