import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';

import { SessionContext } from 'context/SessionContext';

export type AuthRouteProps = {
  children?: any;
};

export const AuthRoute = ({ children }: AuthRouteProps) => {
  const { isLogged } = useContext(SessionContext);
  const location = useLocation();

  if (isLogged) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
