import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { useCreateCategoryMutation } from 'services/api/core/inventory/api';

import { CategoryForm } from '../../components/CategoryForm';

export const CategoryCreate = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [create] = useCreateCategoryMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(-1));
    },
    [create, navigate]
  );

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={handleDismiss}>
          Створення каталогу
        </InlineLink>
      }
    >
      <CategoryForm
        initialValues={{
          parentId: categoryId,
          shopIds: [],
          colorId: 5,
        }}
        onCancel={handleDismiss}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};
