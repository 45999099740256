import ReactAutosuggest from 'react-autosuggest';
import { Preloader } from '@smart-kasa/ui';

import theme from './theme.module.scss';
import styles from './AutoSuggest.module.scss';

export type IAutoSuggestProps = any;

export const AutoSuggest = ({ isLoading = false, ...props }: IAutoSuggestProps) => (
  <div className={styles.autosuggest}>
    <div className={styles.autosuggestStatus}>
      {isLoading && <Preloader className={styles.loader} />}
    </div>

    <ReactAutosuggest theme={theme} {...props} />
  </div>
);
