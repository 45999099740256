import { Route, Routes } from 'react-router-dom';

import { FeedbackContextProvider } from './context/FeedbackContext/FeedbackContext';
import { FaqList } from './routes/FaqList';
import { FaqShow } from './routes/FaqShow';

export const Support = () => (
  <FeedbackContextProvider>
    <Routes>
      <Route index element={<FaqList />} />
      <Route path=":id" element={<FaqShow />} />
    </Routes>
  </FeedbackContextProvider>
);
