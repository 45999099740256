import { FC, ReactNode } from 'react';
import { Form } from 'react-final-form';
import { Button, Drawer, FormElement, Icon, Label } from '@smart-kasa/ui';

import { Field, RangePicker } from 'components';
import { dateToISO8601String } from 'utils/date';
import { TransactionPaymentType, TransactionType } from 'services/api/core/transactions/enums';

import { PaymentType } from '../PaymentType';
import { TransactionFilterGroup } from './TransactionFilterGroup';
import { ITransactionFilterField, TransactionFilterField } from './TransactionFilterField';

type IStatusField = Omit<ITransactionFilterField, 'icon' | 'name' | 'value'> & {
  value: TransactionPaymentType;
};

type ITenderField = Omit<ITransactionFilterField, 'icon' | 'name' | 'value'> & {
  value: TransactionType;
};

const TRANSACTION_TYPE_ICONS: Record<TransactionType, ReactNode> = {
  [TransactionType.cash]: <Icon name="cash" color="accent" />,
  [TransactionType.cashless]: <Icon name="creditCard" color="secondary" />,
  [TransactionType.nonCash]: <Icon name="receipt" color="primary" />,
};

export const StatusField: FC<IStatusField> = ({ value, ...rest }) => (
  <TransactionFilterField
    name="paymentTypeIds"
    icon={<PaymentType status={value} size="small" />}
    value={String(value)}
    {...rest}
  />
);

export const TenderField: FC<ITenderField> = ({ value, ...rest }) => (
  <TransactionFilterField
    name="transactionTypeIds"
    icon={TRANSACTION_TYPE_ICONS[value]}
    value={String(value)}
    {...rest}
  />
);

export interface ITransactionFilterForm {
  show: boolean;
  initialValues: any;
  onClose: () => void;
  onSubmit: (values) => void;
}

export const TransactionFilterForm: FC<ITransactionFilterForm> = ({
  initialValues,
  onSubmit,
  onClose,
  ...rest
}) => {
  const handleSubmit = (fd) => {
    onSubmit(fd);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <Drawer
          onClose={onClose}
          title="Фільтр"
          footer={
            <Button onClick={handleSubmit} width="full" color="primary">
              Застосувати
            </Button>
          }
          {...rest}
        >
          <FormElement label={<Label>Переглянути за датами</Label>}>
            <RangePicker
              from={values.dateStart}
              to={values.dateEnd}
              onChangeDateStart={(date) => form.change('dateStart', dateToISO8601String(date))}
              onChangeDateEnd={(date) => form.change('dateEnd', dateToISO8601String(date))}
            />
          </FormElement>

          <Field.Input name="rrn" label="Номер транзакції" placeholder="Введіть номер для пошуку" />

          <TransactionFilterGroup title="Статус">
            <StatusField title="Прийняті" value={TransactionPaymentType.pay} />
            <StatusField title="Повернені" value={TransactionPaymentType.refund} />
            <StatusField title="Відхилені / Анульовані" value={TransactionPaymentType.reversal} />
          </TransactionFilterGroup>

          <TransactionFilterGroup title="Вид платежу">
            <TenderField title="Готівка" value={TransactionType.cash} />
            <TenderField title="Кредитна картка" value={TransactionType.cashless} />
            <TenderField title="Безготівка" value={TransactionType.nonCash} />
          </TransactionFilterGroup>
        </Drawer>
      )}
    </Form>
  );
};
