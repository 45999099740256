import { Route, Routes } from 'react-router-dom';

import { ReportTaxes } from './routes/ReportTaxes';
import { ReportSummary } from './routes/ReportSummary';

export const Reports = () => (
  <Routes>
    <Route index element={<ReportTaxes />} />
    <Route path="summary" element={<ReportSummary />} />
  </Routes>
);
