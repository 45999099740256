import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button, Inline, InlineSpace } from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { IFeedbackMessage } from 'services/api/core/feedback/types';

import { FeedbackFormSchema } from './FeedbackFormSchema';

interface IFeedbackForm {
  initialValues: Partial<IFeedbackMessage>;
  onSubmit: (values) => void;
  onDismiss: () => void;
}

export const FeedbackForm: FC<IFeedbackForm> = ({ initialValues, onSubmit, onDismiss }) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      validate={transformValidationSchema(FeedbackFormSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field.Input label="Ім'я" name="name" />
          <Field.Input label="Email" name="email" />
          <Field.Phone label="Телефон" name="phoneNumber" />
          <Field.TextArea label="Повідомлення" name="content" />

          <Inline>
            <Button color="sub-primary" onClick={onDismiss}>
              {formatMessage({ id: 'buttons.cancel', defaultMessage: 'Скасувати' })}
            </Button>
            <InlineSpace />
            <Button type="submit" color="primary">
              {formatMessage({ id: 'buttons.send', defaultMessage: 'Відправити' })}
            </Button>
          </Inline>
        </form>
      )}
    </Form>
  );
};
