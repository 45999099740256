import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, PageHeader } from '@smart-kasa/ui';

import { useCreateSubgroupMutation } from 'services/api/core/inventory/api';

import { SubgroupFormErrorSchema } from '../../components/SubgroupForm/SubgroupFormSchema';
import { SubgroupForm } from '../../components/SubgroupForm';
import styles from './SubgroupCreate.module.scss';

export const SubgroupCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateSubgroupMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return SubgroupFormErrorSchema.get(err);
        });
    },
    [create, navigate]
  );

  return (
    <Modal className={styles.modal} onClose={handleDismiss}>
      <PageHeader>Створення товарної підгрупи</PageHeader>

      <SubgroupForm initialValues={{}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Modal>
  );
};
