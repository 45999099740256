import type { ISupportQuestion } from 'services/api/core/support/types';

import { SupportQuestion } from '../SupportQuestion/SupportQuestion';
import styles from './SupportQuestionList.module.scss';

export const SupportQuestionList = ({ questions }: { questions: ISupportQuestion[] }) => (
  <div className={styles.questions}>
    {questions.map((question) => (
      <SupportQuestion key={question.id} question={question} />
    ))}
  </div>
);
