import React, { FC } from 'react';
import cs from 'classnames';
import styles from './CategoryIcon.module.scss';
import { Icon, IconName, Tag } from '@smart-kasa/ui';

interface ICategoryIcon {
  color?: any; // TODO: Color must have not any type
  icon?: IconName;
  className?: string;
}

export const CategoryIcon: FC<ICategoryIcon> = ({ icon = 'folder', color, className }) => (
  <Tag color={color} className={cs(styles.tag, className)}>
    <Icon name={icon} className={styles.icon} />
  </Tag>
);
