import { createContext, useState, useEffect, useCallback, ReactNode, useContext } from 'react';

import useToast from 'hooks/useToast';
import { ConfigContext } from 'context/ConfigContext';
import { readFile } from 'utils/readFile';
import { loadScript } from 'utils/loadScript';

import { DigitalSignatureDialog } from './DigitalSignatureDialog';

interface IDigitalSignatureContext {
  digitalSignature: any;
  initialized: boolean;
  openDialog: (config: any) => void;
}

interface IDigitalSignatureProps {
  proxy?: string;
  children: ReactNode;
}

interface IDigitalSignatureDialogConfig {
  actionCallback?: (digitalSignature: any) => any;
}

export const DigitalSignatureContext = createContext<IDigitalSignatureContext>({
  digitalSignature: null,
  initialized: false,
  openDialog: () => {},
});

export const DigitalSignatureContextProvider = ({ proxy, children }: IDigitalSignatureProps) => {
  const { config } = useContext(ConfigContext);
  const [digitalSignature, setDigitalSignature] = useState(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<IDigitalSignatureDialogConfig>();
  const { toast } = useToast();

  const openDialog = ({ actionCallback }) => {
    setDialogOpen(true);
    setDialogConfig({ actionCallback });
  };

  const onVerfiy = useCallback(
    async (fd) => {
      setVerified(true);

      try {
        const privateKey = await readFile(fd.privateKeyFile);

        //@ts-ignore
        digitalSignature.setAuthority(fd.authorityIndex);
        //@ts-ignore
        digitalSignature.ReadPrivateKeyBinary(privateKey, fd.privateKeyPassword);

        setDialogOpen(false);

        dialogConfig &&
          dialogConfig.actionCallback &&
          dialogConfig.actionCallback(digitalSignature);
      } catch (err) {
        // @ts-ignore
        toast.error(err.message);
      }

      setVerified(false);
    },
    [digitalSignature, dialogConfig, toast]
  );

  const onDismiss = () => {
    dialogConfig && dialogConfig.actionCallback && dialogConfig.actionCallback(null);
    setDialogOpen(false);
  };

  const load = useCallback(async () => {
    await loadScript('/digital-signature.min.js', { async: true });

    const ds = await window.DigitalSignature.initialize({
      proxy: proxy || config.digitalSignatureProxyUrl,
    });

    setDigitalSignature(ds);
    setInitialized(true);
  }, [proxy, config]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <DigitalSignatureContext.Provider value={{ initialized, digitalSignature, openDialog }}>
      <DigitalSignatureDialog
        onConfirm={onVerfiy}
        onClose={onDismiss}
        isVisible={dialogOpen && initialized}
        isLoading={verified || !initialized}
      />

      {children}
    </DigitalSignatureContext.Provider>
  );
};
