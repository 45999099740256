import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { Button } from '@smart-kasa/ui';

import { Error } from 'components';
import styles from './AuthForm.module.scss';

export type AuthFormProps = {
  initialValues: any;
  title: string;
  description?: ReactNode;
  footer: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
  onSubmit: (values) => void;
  validate?: (values) => ValidationErrors | Promise<ValidationErrors>;
};

export const AuthForm: FC<AuthFormProps> = ({
  title,
  description,
  footer,
  initialValues,
  disabled = false,
  children,
  validate,
  onSubmit,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
    </div>

    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, submitErrors }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.form}>
            {children}

            {submitErrors && submitErrors.base && <Error>{submitErrors.base}</Error>}
          </div>

          <Button
            className={styles.button}
            type="submit"
            color="orange"
            width="full"
            disabled={disabled}
          >
            <FormattedMessage id="auth.buttons.next" />
          </Button>
        </form>
      )}
    </Form>

    <div className={styles.footer}>{footer}</div>
  </div>
);
