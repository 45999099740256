import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Icon,
  DataTable,
  IDataTableColumn,
  Pagination,
  InlineSpace,
  Input,
  Row,
  Page,
  Paragraph,
  AvatarItem,
  Strong,
  ButtonIcon,
} from '@smart-kasa/ui';

import { BlankView, ButtonIconLink, Picture } from 'components';
import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import useConfirm from 'hooks/useConfirm';
import { useDeleteEmployeeMutation, useGetEmployeesQuery } from 'services/api/core/employees/api';
import type { IEmployee, IRole } from 'services/api/core/employees/types';

export const EmployeeList = () => {
  const navigate = useNavigate();
  const { query, push: setQuery } = useQuery();
  const { confirm } = useConfirm();
  const { onPageChange, onSortChange } = useQueryPagination();
  const { data: employees, isLoading } = useGetEmployeesQuery(query);
  const [destroy] = useDeleteEmployeeMutation();

  const handleSearch = (term: string) => setQuery({ ...query, term });
  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити працівника?');
    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IEmployee>[] = [
    {
      key: 'firstName',
      title: 'Працівник',
      render: (firstName: string, { lastName, avatar }: IEmployee) => (
        <AvatarItem
          avatar={<Picture icon="members" src={avatar} size="M" />}
          primaryText={
            <Strong>
              {firstName} {lastName}
            </Strong>
          }
        />
      ),
    },
    {
      key: 'role',
      title: 'Посада',
      render: (role: IRole) => (role ? role.name : '-'),
    },
    {
      key: 'phoneNumber',
      title: 'Телефон',
    },
    {
      key: 'updatedAt',
      title: 'Остання активність',
      render: (updatedAt) => (
        <FormattedDate value={updatedAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      render: (id: number) => (
        <Fragment>
          <ButtonIconLink to={`/employees/${id}`} onClick={(event) => event.stopPropagation()}>
            <Icon size="large" name="eye" />
          </ButtonIconLink>
          <InlineSpace />
          <ButtonIcon
            onClick={(event) => {
              handleDelete(id);
              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Fragment>
      ),
    },
  ];

  return (
    <Page
      title="Працівники"
      actions={
        <Row>
          <Input onChange={(event) => handleSearch(event.target.value)} placeholder="Пошук" />
          <InlineSpace />
          <Button
            color="primary"
            rightIcon={<Icon name="add" size="small" />}
            onClick={() => navigate('/employees/create')}
          >
            Додати
          </Button>
        </Row>
      }
    >
      <DataTable
        rowIdKey="id"
        data={employees?.data}
        columns={columns}
        isLoading={isLoading}
        onSortChange={onSortChange}
        onRowClick={(employee) => navigate(`/employees/${employee.id}`)}
        emptyText={
          <BlankView>
            Придбайте Смарт Касу для активації розділу
            <Paragraph>
              Створіть зручну базу даних Вашого персоналу. Додавайте своїх працівників, визначайте
              їх права доступу та повноваження для роботи за Смарт Касою. Відчуйте переваги
              автоматизації роботи персоналу, придбавши Вашу першу Смарт Касу.
            </Paragraph>
          </BlankView>
        }
      />

      <Pagination
        totalItems={employees?.meta?.totalCount}
        currentPage={employees?.meta?.currentPage}
        perPage={employees?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Page>
  );
};
