import * as yup from 'yup';

import { schemaError } from 'utils/schemaError';

export const StockFormSchema = yup.object().shape({
  productId: yup.string().required(),
  shopId: yup.number().required(),
  count: yup.string().required().max(8, 'Кількість занадто велика'),
});

export const StockFormErrorSchema = schemaError({
  default: 'Невірний запис',
  productId: {
    default: 'Помилка збереження товару',
    invalid: 'Інвентарна картка для товару вже створена',
  },
});
