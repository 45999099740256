import { FC } from 'react';
import { Modal, PageHeader, Preloader } from '@smart-kasa/ui';

import { DigitalSignatureForm } from './DigitalSignatureForm';
import styles from './DigitalSignatureDialog.module.scss';

interface IDigitalSignatureDialog {
  isVisible?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onConfirm: (fd) => void;
}

export const DigitalSignatureDialog: FC<IDigitalSignatureDialog> = ({
  isLoading,
  isVisible,
  onClose,
  onConfirm,
}) => (
  <Modal onClose={onClose} show={isVisible} className={styles.dialog}>
    <PageHeader>Оберіть ключ ЕЦП</PageHeader>

    {isLoading && <Preloader className={styles.preloader} />}
    <DigitalSignatureForm onSubmit={onConfirm} onCancel={onClose} />
  </Modal>
);
