import { CoreResponse } from 'types/api';

import { coreApi } from '../api';
import { IAMPermission, IAMRole, IAMRoleFormData } from './types';

const roleApi = coreApi.enhanceEndpoints({ addTagTypes: ['Role', 'Permission'] }).injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<CoreResponse<IAMRole[]>, void>({
      query: () => ({ url: '/iam/roles' }),
      providesTags: ['Role'],
    }),
    getPermissions: builder.query<CoreResponse<IAMPermission[]>, void>({
      query: () => ({ url: '/iam/permissions' }),
      providesTags: ['Permission'],
    }),
    findRole: builder.query<CoreResponse<IAMRole>, number>({
      query: (id) => ({ url: `/iam/roles/${id}` }),
      providesTags: ['Role'],
    }),
    createRole: builder.mutation<CoreResponse<IAMRole[]>, IAMRoleFormData>({
      query: (role) => ({
        url: '/iam/roles',
        method: 'POST',
        body: { role },
      }),
      invalidatesTags: ['Role'],
    }),
    updateRole: builder.mutation<void, Pick<IAMRole, 'id'> & IAMRoleFormData>({
      query: ({ id, ...role }) => ({
        url: `/iam/roles/${id}`,
        method: 'PUT',
        body: { role },
      }),
      invalidatesTags: ['Role'],
    }),
    deleteRole: builder.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/iam/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRolesQuery,
  useGetPermissionsQuery,
  useFindRoleQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;
