import { Route, Routes } from 'react-router-dom';

import { Catalog } from './routes/Catalog';
import { Subgroups } from './routes/Subgroups';
import { Stocks } from './routes/Stocks';
import { Import } from './routes/Import';

export const Inventory = () => (
  <Routes>
    <Route path="*" element={<Catalog />} />
    <Route path="subgroups/*" element={<Subgroups />} />
    <Route path="stock/*" element={<Stocks />} />
    <Route path="import/*" element={<Import />} />
  </Routes>
);
