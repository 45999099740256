import { Fragment, useCallback } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, PageHeader, Strong } from '@smart-kasa/ui';

import { useAppSelector } from 'hooks/useAppStore';
import { useUpdateTerminalMutation } from 'services/api/core/pos/api';
import { TerminalState } from 'services/api/core/pos/enums';

import { selectTerminalById } from 'features/terminals/slice';
import { TerminalStatus } from 'features/terminals/components/TerminalStatus';
import { TerminalForm } from 'features/terminals/components/TerminalForm';
import styles from './TerminalEdit.module.scss';

export const TerminalEdit = () => {
  const { id } = useParams();
  const terminal = useAppSelector((state) => selectTerminalById(state, Number(id)));
  const [update] = useUpdateTerminalMutation();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (values) => {
      await update(values);

      navigate(-1);
    },
    [update, navigate]
  );

  return (
    <Modal className={styles.modal} onClose={() => navigate(-1)}>
      {({ close }) => (
        <Fragment>
          <PageHeader>Керування терміналом: #{terminal?.id}</PageHeader>

          <div className={styles.item}>
            <div className={styles.picture}>
              <div className={styles.badge}>QR для активації</div>
              <img src={terminal && terminal.picture && terminal.picture.url} alt="" />
            </div>
            <div className={styles.description}>
              <div className={styles.line}>
                <Strong>Термінал #{id}</Strong>
              </div>
              <div className={styles.line}>
                <TerminalStatus state={terminal?.state || TerminalState.PENDING} />
              </div>
              <div className={styles.line}>
                <Strong>Серійний номер:</Strong>
                <div className={styles.text}>{terminal?.serialNumber || '-'}</div>
              </div>
              <div className={styles.line}>
                <Strong>Дата активації:</Strong>
                <div className={styles.text}>
                  {terminal?.activatedAt ? (
                    <FormattedDate
                      value={terminal.activatedAt}
                      dateStyle="short"
                      timeStyle="short"
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </div>

          <TerminalForm initialValues={terminal || {}} onSubmit={onSubmit} onDismiss={close} />
        </Fragment>
      )}
    </Modal>
  );
};
