import * as yup from 'yup';

import { schemaPassword, schemaPhone } from 'utils/sharedSchemas';
import { schemaError } from 'utils/schemaError';

export const ProfileSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phoneNumber: schemaPhone,
});

export const ProfilePasswordSchema = yup.object().shape({
  oldPassword: schemaPassword,
  newPassword: schemaPassword,
  newPasswordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword')], 'Пароль має співпадати із значенням поля “Новий пароль“'),
});

export const ProfilePinCodeSchema = yup.object().shape({
  pinCode: yup.string().required(),
});

export const ProfileFormErrorSchema = schemaError({
  default: 'Помилка збереження',
  email: {
    default: 'Помилка email',
    invalid: 'Невірний формат email',
  },
  phoneNumber: {
    default: 'Помилка телефону',
    invalid: 'Невірний телефон',
  },
});

export const ProfilePasswordErrorSchema = schemaError({
  default: 'Помилка паролю',
  oldPassword: {
    default: 'Помилка паролю',
    invalid: 'Невірний пароль',
  },
});
