import { FC } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, ButtonIcon, Card, DropDown, Hint, Icon, Title } from '@smart-kasa/ui';

import { useQuery } from 'hooks';
import useConfirm from 'hooks/useConfirm';
import { useDeleteCategoryMutation } from 'services/api/core/inventory/api';
import type { ICategory } from 'services/api/core/inventory/types';

import { CategoryItem } from '../CategoryItem';
import { CategoryPreloader } from '../CategoryPreloader';
import styles from './CategoryList.module.scss';
import { BlankView, InlineLink } from 'components';

export interface ICategoryList {
  categories: ICategory[];
  isLoading?: boolean;
  selected?: ICategory;
  onSelect?: (category: ICategory) => void;
}

export const CategoryList: FC<ICategoryList> = ({ categories, isLoading, selected, onSelect }) => {
  const { query } = useQuery();
  const navigate = useNavigate();
  const [destroy] = useDeleteCategoryMutation();
  const { confirm } = useConfirm();

  const handleAdd = () => {
    const pathname = selected
      ? `/inventory/categories/${selected.id}/create`
      : '/inventory/categories/create';

    navigate(pathname);
  };

  const handleSelect = (category: ICategory) => {
    navigate({
      pathname: `/inventory/categories/${category.id}`,
      search: createSearchParams(query).toString(),
    });

    onSelect && onSelect(category);
  };

  const handleBack = () => {
    const pathname = selected?.parentId
      ? `/inventory/categories/${selected.parentId}`
      : '/inventory';

    navigate({ pathname, search: createSearchParams(query).toString() });
  };

  const handleDelete = async (category: ICategory) => {
    const isConfirmed = await confirm(`Ви дійсно бажаєте видалити категорію ${category.title}?`);

    if (isConfirmed) destroy(category.id);
  };

  return (
    <Card className={styles.list}>
      {selected && (
        <InlineLink onClick={handleBack} className={styles.back}>
          <Title variant="h3" className={styles.title}>
            <Icon name="arrowLeft" size="small" />
            повернутись
          </Title>
        </InlineLink>
      )}

      {isLoading && <CategoryPreloader />}
      {!isLoading && categories.length === 0 && (
        <BlankView>
          Список каталогів порожній
          <Hint>Каталоги допоможуть вам легше впоратися з організацією товарів</Hint>
          <Button
            color="sub-primary"
            rightIcon={<Icon name="plus" size="small" />}
            onClick={handleAdd}
          >
            Додати
          </Button>
        </BlankView>
      )}
      {categories.map((category) => (
        <CategoryItem
          key={category.id}
          category={category}
          onSelect={handleSelect}
          selected={selected?.id === category.id}
          actions={(category) => (
            <DropDown>
              <ButtonIcon size="small">
                <Icon name="settings" size="medium" />
              </ButtonIcon>
              <DropDown.Menu>
                <DropDown.Item
                  icon="edit"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/inventory/categories/${category.id}/edit`);
                  }}
                >
                  Редагувати
                </DropDown.Item>
                <DropDown.Item
                  icon="trash"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDelete(category);
                  }}
                >
                  Видалити
                </DropDown.Item>
              </DropDown.Menu>
            </DropDown>
          )}
        />
      ))}
    </Card>
  );
};
