import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Card } from '@smart-kasa/ui';

import { AreaChart } from 'components';
import { gradientFillColor } from 'components/AreaChart';
import { IReportPaymentTransactions } from 'services/api/core/transactions/types';

import styles from './SalesChart.module.scss';

dayjs.extend(customParseFormat);

interface ISalesChart {
  data: IReportPaymentTransactions;
}

enum ReportChartType {
  cash = 'cashTransactions',
  cashless = 'cashlessTransactions',
  nonCash = 'nonCashTransactions',
  revenue = 'revenues',
}

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ';
const SERIES_BY_TYPE: Record<ReportChartType, { name: string; color: string }> = {
  [ReportChartType.cash]: {
    name: 'Готівка',
    color: '#57B7DA',
  },
  [ReportChartType.cashless]: {
    name: 'Картка',
    color: '#FE8257',
  },
  [ReportChartType.nonCash]: {
    name: 'Безготівка',
    color: '#29cd6b',
  },
  [ReportChartType.revenue]: {
    name: 'Обіг',
    color: '#6D2AD8',
  },
};

export const SalesChart: FC<ISalesChart> = ({ data }) => {
  const series = useMemo(
    () =>
      Object.values(ReportChartType).map((key) => ({
        name: SERIES_BY_TYPE[key].name,
        fillColor: gradientFillColor(SERIES_BY_TYPE[key].color),
        data: (data[key] || []).map(([date, amount]) => [
          dayjs(date, DATE_FORMAT).toDate().getTime(),
          Number(amount),
        ]),
      })),
    [data]
  );

  return (
    <Card className={styles.container}>
      <AreaChart title="Графіки" series={series} />
    </Card>
  );
};
