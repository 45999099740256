import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { IShop } from './types';

export const shopApi = coreApi.enhanceEndpoints({ addTagTypes: ['Shop'] }).injectEndpoints({
  endpoints: (builder) => ({
    getShops: builder.query<CoreResponse<IShop[]>, void>({
      query: () => ({ url: '/rsn/shops?skip_pagination=true' }),
      providesTags: ['Shop'],
    }),
    findShop: builder.query<CoreResponse<IShop>, number>({
      query: (id) => ({ url: `/rsn/shops/${id}` }),
      providesTags: ['Shop'],
    }),
    createShop: builder.mutation<CoreResponse<IShop>, Partial<IShop>>({
      query: (shop) => ({
        url: '/rsn/shops',
        method: 'POST',
        body: { shop },
      }),
      invalidatesTags: ['Shop'],
    }),
    updateShop: builder.mutation<void, Pick<IShop, 'id'> & Partial<IShop>>({
      query: ({ id, ...shop }) => ({
        url: `/rsn/shops/${id}`,
        method: 'PUT',
        body: { shop },
      }),
      invalidatesTags: ['Shop'],
    }),
    deleteShopPicture: builder.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/rsn/shops/${id}/picture`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Shop'],
    }),
    deleteShop: builder.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/rsn/shops/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Shop'],
    }),
  }),
});

export const {
  useGetShopsQuery,
  useFindShopQuery,
  useCreateShopMutation,
  useUpdateShopMutation,
  useDeleteShopMutation,
  useDeleteShopPictureMutation,
} = shopApi;
