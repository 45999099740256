import { FC, useContext } from 'react';
import { Form } from 'react-final-form';
import { Button, InlineSpace, Preloader } from '@smart-kasa/ui';

import { Field } from 'components';
import { DigitalSignatureContext } from './DigitalSignatureContext';

interface IDigitalSignatureForm {
  onSubmit: (fd) => void;
  onCancel?: () => void;
}

export const DigitalSignatureForm: FC<IDigitalSignatureForm> = ({ onSubmit, onCancel }) => {
  const { initialized, digitalSignature } = useContext(DigitalSignatureContext);

  if (!initialized) return <Preloader />;

  return (
    <Form onSubmit={onSubmit} initialValues={{}}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Field.Select
            name="authorityIndex"
            label="АЦСК"
            placeholder="Оберіть АЦСК"
            isSearchable={false}
            options={digitalSignature.authorities}
            isClearable
            getOptionLabel={(authority) => authority.issuerCNs[0]}
            getOptionValue={(authority) =>
              authority == null ? undefined : digitalSignature.authorities.indexOf(authority)
            }
          />
          <Field.File
            name="privateKeyFile"
            label="Файл із закритим ключем"
            icon="look"
            placeholder="Оберіть файл"
          />
          <Field.Password name="privateKeyPassword" label="Пароль до закритого ключа" />

          <Button color="primary" width="narrow" type="submit">
            Продовжити
          </Button>
          <InlineSpace />
          <Button color="light" width="narrow" onClick={onCancel}>
            Скасувати
          </Button>
        </form>
      )}
    </Form>
  );
};
