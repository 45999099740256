import { Route, Routes } from 'react-router-dom';

import { ShiftList } from './routes/ShiftList';
import { ShiftShow } from './routes/ShiftShow';

export const Shifts = () => (
  <Routes>
    <Route index element={<ShiftList />} />
    <Route path=":id" element={<ShiftShow />} />
  </Routes>
);
