import { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Button } from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import type { IProduct } from 'services/api/core/inventory/types';
import { useShops } from 'features/shops/hooks/useShops';

import { ProductStockFormErrorSchema, ProductStockFormSchema } from './ProductStockFormSchema';
import { ProductStockCardForm } from './ProductStockCardForm';
import styles from './ProductStockForm.module.scss';
import {
  useCreateStockMutation,
  useDeleteStockMutation,
  useUpdateStockMutation,
} from 'services/api/core/inventory/api';

interface IProductStockForm {
  product: IProduct;
  onChange?: () => void;
}

export const ProductStockForm: FC<IProductStockForm> = ({ product, onChange }) => {
  const { shops } = useShops();
  const { toast } = useToast();
  const [create] = useCreateStockMutation();
  const [update] = useUpdateStockMutation();
  const [destroy] = useDeleteStockMutation();

  const handleSubmit = useCallback(
    ({ cards }) => {
      const requests = cards.map((card) => (card.id ? update(card) : create(card)));

      return Promise.all(requests)
        .then(() => {
          toast.success('Збережено');
          onChange && onChange();
        })
        .catch((err) => {
          const errors = ProductStockFormErrorSchema.get(err);
          const info = Object.values(errors).join(', ');

          toast.error(info);

          return errors;
        });
    },
    [create, update, onChange, toast]
  );

  const handleDelete = useCallback(
    (id: number) => {
      destroy(id)
        .unwrap()
        .then(() => toast.success('Видалено'));
    },
    [destroy, toast]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={transformValidationSchema(ProductStockFormSchema)}
      mutators={{ ...arrayMutators }}
      initialValues={{ cards: product.cards }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray
            name="cards"
            component={ProductStockCardForm}
            initialCard={{ productId: product.id, countingEnabled: true }}
            shops={shops}
            onRemove={handleDelete}
          />

          <div className={styles.actions}>
            <Button type="submit" className={styles.button} color="primary">
              Зберегти
            </Button>
          </div>
        </form>
      )}
    />
  );
};
