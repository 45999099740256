import { Fragment } from 'react';
import { Form } from 'react-final-form';
import { Button, Collapse, CollapseContent, CollapseHeader, Divider } from '@smart-kasa/ui';

import { Field } from 'components';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useGetPermissionsQuery } from 'services/api/core/roles/api';
import type { IAMPolicyActionType } from 'services/api/core/roles/enums';

import {
  POLICY_GROUP_MESSAGES,
  POLICY_PERMISSIONS_MESSAGES,
} from 'features/roles/constants/messages';
import { RoleFormSchema } from './RoleFormSchema';
import styles from './RoleForm.module.scss';

export interface IRoleFormData {
  name?: string;
  actionTypeIds?: IAMPolicyActionType[];
}

export interface IRoleForm {
  initialValues: IRoleFormData;
  onSubmit: (values: IRoleFormData) => void;
}

export const RoleForm = ({ initialValues, onSubmit }: IRoleForm) => {
  const { data: permissions } = useGetPermissionsQuery();

  return (
    <Form
      validate={transformValidationSchema(RoleFormSchema)}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <Field.Input label="Назва посади" name="name" />
          <Divider type="horizontal" />

          {permissions?.data.map((permission) => {
            const group = POLICY_GROUP_MESSAGES[permission.id];

            return (
              <Collapse key={permission.id} theme="transparent" className={styles.container} active>
                <CollapseHeader>
                  <Fragment>
                    <div className={styles.title}>{group.title}</div>
                    <div className={styles.description}>{group.description}</div>
                  </Fragment>
                </CollapseHeader>
                <CollapseContent className={styles.collapseContent}>
                  {permission.actions.map((action) => (
                    <Field.SwitchArray
                      key={action.id}
                      name="actionTypeIds"
                      value={action.id}
                      label={POLICY_PERMISSIONS_MESSAGES[action.id]}
                      className={styles.switch}
                      direction="row"
                      justifyContent="space-between"
                    />
                  ))}
                </CollapseContent>
              </Collapse>
            );
          })}

          <div className={styles.buttons}>
            <Button color="primary" type="submit">
              Зберегти
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
