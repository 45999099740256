import { Route, Routes } from 'react-router-dom';

import { ReceiptList } from './routes/ReceiptList';
import { ReceiptArchive } from './routes/ReceiptArchive';
import { ReceiptCreate } from './routes/ReceiptCreate';
import { ReceiptEdit } from './routes/ReceiptEdit';

export const Receipts = () => (
  <Routes>
    <Route index element={<ReceiptList />} />
    <Route path="archive" element={<ReceiptArchive />} />
    <Route path="create" element={<ReceiptCreate />} />
    <Route path=":id/edit" element={<ReceiptEdit />} />
  </Routes>
);
