import cs from 'classnames';

import { ExternalLink, ExternalLinkProps } from 'components';

import styles from './Bot.module.scss';

export type BotProps = Omit<ExternalLinkProps, 'to'> & {
  url: string;
};

export const Bot = ({ url, className, ...rest }: BotProps) => (
  <ExternalLink to={url} className={cs(styles.bot, className)} {...rest} />
);
