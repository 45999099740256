import { Fragment, useMemo } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  DataTable,
  Icon,
  IDataTableColumn,
  InlineSpace,
  Pagination,
  UAH,
  ButtonIcon,
  Button,
  Card,
  PageHeader,
  CardTitle,
} from '@smart-kasa/ui';

import { ButtonLink } from 'components';
import { useQuery, useQueryPagination } from 'hooks';
import useConfirm from 'hooks/useConfirm';
import { useDeleteReceiptMutation, useGetReceiptsQuery } from 'services/api/core/receipts/api';
import { useLazyExportReceiptsQuery } from 'services/api/core/export/api';
import type { IReceipt } from 'services/api/core/receipts/types';
import { ReceiptState } from 'services/api/core/receipts/enums';
import { ReceiptArchiveFilter } from 'features/receipts/components/ReceiptArchiveFilter';

export const ReceiptArchive = () => {
  const navigate = useNavigate();
  const { onPageChange } = useQueryPagination();
  const { query, push: setQuery } = useQuery();
  const { confirm } = useConfirm();
  const params = useMemo(
    () => ({
      state: ReceiptState.archived,
      ...query,
    }),
    [query]
  );
  const { data: receipts, isLoading } = useGetReceiptsQuery(params);
  const [download] = useLazyExportReceiptsQuery();
  const [destroy] = useDeleteReceiptMutation();

  const handleDelete = async (id: string) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити відкладений чек?');
    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IReceipt>[] = [
    {
      key: 'title',
      title: 'Назва',
    },
    {
      key: 'totalAmount',
      title: 'Сума',
      render: (totalAmount) => <UAH value={totalAmount} />,
    },
    {
      key: 'discountPercentage',
      title: 'Знижка',
      render: (discountPercentage, { discountAmount }) => (
        <Fragment>
          {discountPercentage}% (<UAH value={discountAmount} />)
        </Fragment>
      ),
    },
    {
      key: 'createdAt',
      title: 'Дата',
      render: (createdAt) => (
        <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      key: 'id',
      render: (id: string) => (
        <Fragment>
          <ButtonLink to={`/receipts/${id}/edit`} onClick={(event) => event.stopPropagation()}>
            Редагувати
          </ButtonLink>
          <InlineSpace />
          <ButtonIcon
            onClick={(event) => {
              handleDelete(id);

              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Fragment>
            <Button rightIcon={<Icon name="template" />} onClick={() => download(params)}>
              Експорт
            </Button>
            <InlineSpace />
            <ButtonLink
              to="/receipts/create"
              color="primary"
              rightIcon={<Icon name="plus" size="small" />}
            >
              Створити чек
            </ButtonLink>
          </Fragment>
        }
      >
        Відкладені чеки
      </PageHeader>

      <Card>
        <ReceiptArchiveFilter initialValues={params} onChange={setQuery} />
      </Card>

      <InlineSpace direction="vertical" size={20} />

      <Card>
        <CardTitle>Список відкладених чеків</CardTitle>

        <DataTable
          rowIdKey="id"
          data={receipts?.data}
          columns={columns}
          isLoading={isLoading}
          onRowClick={(receipt) => navigate(`/receipts/${receipt.id}/edit`)}
        />
        <Pagination
          totalItems={receipts?.meta?.totalCount}
          currentPage={receipts?.meta?.currentPage}
          perPage={receipts?.meta?.limitValue}
          onPageChange={onPageChange}
        />
      </Card>
    </Fragment>
  );
};
