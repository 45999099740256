import InputMask from 'react-input-mask';
import { Input, IInputProps } from '@smart-kasa/ui';

export type PhoneInputProps = IInputProps & {
  mask?: string;
};

export const PhoneInput = ({
  mask = '+38 (099) 999-99-99',
  placeholder = '+38 (0XX) XXX-XX-XХ',
  ref,
  ...rest
}: PhoneInputProps) => (
  <InputMask {...rest} placeholder={placeholder} mask={mask}>
    {(inputProps: PhoneInputProps) => <Input {...inputProps} ref={ref} />}
  </InputMask>
);
