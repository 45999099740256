import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Drawer, FormElement, Label } from '@smart-kasa/ui';

import { Field, RangePicker } from 'components';
import { dateToISO8601String } from 'utils/date';
import { useGetSubgroupsQuery } from 'services/api/core/inventory/api';
import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

export interface IGoodsFilterForm {
  show: boolean;
  initialValues: any;
  onClose: () => void;
  onSubmit: (values) => void;
}

export const GoodsFilterForm: FC<IGoodsFilterForm> = ({
  initialValues,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { shops } = useShops();
  const { terminals } = useTerminals();
  const { data: subgroups } = useGetSubgroupsQuery();

  const handleSubmit = (fd) => {
    onSubmit(fd);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <Drawer
          onClose={onClose}
          title="Фільтр"
          footer={
            <Button onClick={handleSubmit} width="full" color="primary">
              Застосувати
            </Button>
          }
          {...rest}
        >
          <FormElement label={<Label>Переглянути за датою</Label>}>
            <RangePicker
              from={values.dateStart ? values.dateStart : new Date()}
              to={values.dateEnd ? values.dateEnd : new Date()}
              onChangeDateStart={(date) => form.change('dateStart', dateToISO8601String(date))}
              onChangeDateEnd={(date) => form.change('dateEnd', dateToISO8601String(date))}
            />
          </FormElement>
          <Field.Input name="q" label="Пошук" placeholder="Введіть текст" />
          <Field.Input
            name="registrarFiscalNumber"
            label="ФН ПРРО"
            placeholder="Введіть фіскальний номер реєстратора"
          />
          <Field.Select
            name="subgroupId"
            label="Підгрупа товарів"
            placeholder="Оберіть підгрупу"
            options={subgroups?.data}
            isClearable
            value={Number(values.subgroupId)}
            getOptionLabel={(subgroup) => subgroup?.title}
            getOptionValue={(subgroup) => subgroup?.id}
          />
          <ShopSelectField
            name="shopId"
            label="Торгова точка"
            placeholder="Оберіть торгову точку"
            options={shops}
            isClearable
            value={Number(values.shopId)}
          />
          <TerminalSelectField
            name="terminalId"
            label="Термінал"
            placeholder="Оберіть термінал"
            options={terminals.filter((item) => item.shopId === Number(values.shopId))}
            isClearable
            value={Number(values.terminalId)}
          />
        </Drawer>
      )}
    </Form>
  );
};
