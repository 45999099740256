import { Colors } from 'constants/colors';
import { BillingInvoiceState } from 'services/api/core/invoices/enums';

export const BILLING_INVOICE_STATUS: Record<BillingInvoiceState, { title: string; color: Colors }> =
  {
    [BillingInvoiceState.waiting]: {
      title: 'Очікує на сплату',
      color: 'yellow',
    },
    [BillingInvoiceState.paid]: {
      title: 'Транзакція успішна',
      color: 'blue',
    },
    [BillingInvoiceState.past_due]: {
      title: 'Платіж прострочено',
      color: 'green',
    },
    [BillingInvoiceState.cancelled]: {
      title: 'Платіж відхилено',
      color: 'grey',
    },
    [BillingInvoiceState.draft]: {
      title: 'Попередній рахунок',
      color: 'orange',
    },
  };
