import { FC, useCallback } from 'react';
import { Button, Hint, Paragraph, Title, UAH } from '@smart-kasa/ui';
import cs from 'classnames';
import styles from './BillingPlan.module.scss';
import { IBillingPlan } from 'services/api/core/billing/types';

interface IBillingPlanProps {
  plan: IBillingPlan;
  isActive?: (plan: IBillingPlan) => boolean;
  onActivate?: (plan: IBillingPlan) => void;
}

export const BillingPlan: FC<IBillingPlanProps> = ({ plan, isActive, onActivate }) => {
  const isActivated = isActive ? isActive(plan) : false;
  const handleClick = useCallback(() => {
    onActivate && onActivate(plan);
  }, [plan, onActivate]);

  return (
    <div className={cs(styles.plan, isActivated && styles.active)}>
      {isActivated && <div className={styles.badge}>Активовано</div>}
      <div className={cs(styles.row, styles.title)}>
        <Title variant="h2">{plan.title}</Title>
      </div>
      <div className={styles.row}>
        <UAH value={plan.price} decreaseHundredths className={styles.price} /> /{' '}
        {plan.isRecurrent ? 'місяць' : `${plan.billingPeriod} днів`}
        <Paragraph>За одну касу</Paragraph>
      </div>
      <div className={cs(styles.row, styles.description)}>
        <Hint margin={0}>{plan.description}</Hint>
      </div>
      <div className={styles.actions}>
        <Button color="primary" width="full" disabled={isActivated} onClick={handleClick}>
          Підключити
        </Button>
      </div>
    </div>
  );
};
