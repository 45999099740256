import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { InlineRouteLink } from 'components';
import { useCreateShopMutation } from 'services/api/core/shop/api';
import { ShopState } from 'services/api/core/shop/enums';

import { ShopForm } from 'features/shops/components/ShopForm';
import { ShopErrorSchema } from 'features/shops/components/ShopForm/ShopSchema';

export const ShopCreate = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [create] = useCreateShopMutation();

  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(({ data: shop }) => {
          toast.success('Збережено');

          navigate(`/shops/${shop.id}`);
        })
        .catch((err) => {
          return ShopErrorSchema.get(err);
        });
    },
    [create, navigate, toast]
  );

  return (
    <Page
      title={
        <InlineRouteLink icon="arrowLeft" to={'/shops/create'}>
          Додати торгову точку
        </InlineRouteLink>
      }
    >
      <ShopForm
        initialValues={{ state: ShopState.active }}
        onDismiss={() => navigate(-1)}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};
