export enum ReceiptType {
  sale = 0,
  serviceInput = 1,
  serviceOutput = 2,
  refund = 3,
  reversal = 4,
  offlineStart = 102,
  offlineFinish = 103,
}

export enum ReceiptState {
  pending = 0,
  archived = 1,
  paid = 2,
  refunded = 3,
}

export enum DiscountType {
  percent = 0,
  currency = 1,
}
