import { Route, Routes } from 'react-router-dom';

import { CatalogList } from './routes/CatalogList';
import { ProductCreate } from './routes/ProductCreate';
import { ProductEdit } from './routes/ProductEdit';
import { CategoryCreate } from './routes/CategoryCreate';
import { CategoryEdit } from './routes/CategoryEdit';

export const Catalog = () => (
  <Routes>
    <Route index element={<CatalogList />} />
    <Route path="products/*">
      <Route path="create" element={<ProductCreate />} />
      <Route path=":id/edit" element={<ProductEdit />} />
    </Route>
    <Route path="categories/*">
      <Route path="create" element={<CategoryCreate />} />
      <Route path=":id/edit" element={<CategoryEdit />} />
      <Route path=":categoryId" element={<CatalogList />} />
      <Route path=":categoryId/create" element={<CategoryCreate />} />
      <Route path=":categoryId/products/create" element={<ProductCreate />} />
    </Route>
  </Routes>
);
