import React from 'react';
import { FormElement, Label } from '@smart-kasa/ui';

import { Datepicker, IDatepicker } from 'components';

import Field from '../Field/Field';

interface IWrappedProps {
  label?: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  name: string;
}

interface DatepickerFieldProps extends IWrappedProps, Omit<IDatepicker, 'name' | 'onChange'> {}

export const DatepickerField = ({
  label,
  required,
  disabled,
  name,
  description,
  ...rest
}: DatepickerFieldProps) => (
  <Field name={name}>
    {({ input, meta, ...rest }) => (
      <FormElement
        description={description}
        label={
          label && (
            <Label htmlFor={name} required={required}>
              {label}
            </Label>
          )
        }
      >
        <Datepicker
          name={name}
          {...meta}
          {...rest}
          selected={input.value}
          required={required}
          disabled={disabled || meta.submitting}
          onChange={(value) => input.onChange(value)}
        />
      </FormElement>
    )}
  </Field>
);
