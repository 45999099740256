export const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = reject;
    reader.onerror = reject;

    reader.onload = (event) => {
      //@ts-ignore
      const typedArray = new Uint8Array(event.target.result);

      resolve(typedArray);
    };

    reader.readAsArrayBuffer(file);
  });
