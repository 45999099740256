import { ButtonText, PinInput } from '@smart-kasa/ui';

import Field from '../../Field/Field';
import styles from './PinField.module.scss';

export const generatePinCode = () => (1000 + Math.random() * 9000).toFixed(0);

export const PinField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <div className={styles.container}>
        <PinInput {...input} {...rest} {...props} value={String(input.value)} />
        <ButtonText className={styles.button} onClick={() => input.onChange(generatePinCode())}>
          Згенерувати
        </ButtonText>
      </div>
    )}
  </Field>
);
