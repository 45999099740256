import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Page, Preloader } from '@smart-kasa/ui';

import { InlineLink } from 'components';
import { useAppSelector } from 'hooks/useAppStore';
import { useGetFaqCategoriesQuery } from 'services/api/core/support/api';
import { FeedbackContext } from 'features/support/context/FeedbackContext';
import { selectCategoryById } from 'features/support/slice';
import { SupportQuestionList } from 'features/support/components/SupportQuestionList';

export const FaqShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showModal } = useContext(FeedbackContext);
  const { isLoading } = useGetFaqCategoriesQuery();
  const category = useAppSelector((state) => selectCategoryById(state, Number(id)));

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          {category?.title}
        </InlineLink>
      }
      actions={
        <Button color="primary" onClick={showModal}>
          Надіслати запит
        </Button>
      }
    >
      {!category || isLoading ? (
        <Preloader />
      ) : (
        <SupportQuestionList questions={category.questions} />
      )}
    </Page>
  );
};
