import { ReactNode } from 'react';
import { Title } from '@smart-kasa/ui';

import styles from './TransactionFilter.module.scss';

interface ITransactionFilterGroup {
  title?: string;
  children: ReactNode;
}

export const TransactionFilterGroup = ({ title, children }: ITransactionFilterGroup) => (
  <div className={styles.block}>
    {title && (
      <Title variant="h2" className={styles.blockTitle}>
        {title}
      </Title>
    )}

    {children}
  </div>
);
