import { coreApi } from '../api';
import type { CoreResponse } from 'types';
import type {
  AuthResetPassword,
  AuthResetPasswordCodeResponse,
  ILogin,
  ISession,
  SignUpFormData,
} from './types';

export const authApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<CoreResponse<ISession>, ILogin>({
      query: (session) => ({
        url: '/auth/sessions',
        method: 'POST',
        body: { session },
      }),
    }),
    signUp: builder.mutation<CoreResponse<ISession>, SignUpFormData>({
      query: (user) => ({
        url: '/signup/users',
        method: 'POST',
        body: { user },
      }),
    }),
    validatePhone: builder.mutation<CoreResponse<ISession>, string>({
      query: (value) => ({
        url: '/signup/validations/phone_number',
        method: 'PUT',
        body: { value },
      }),
    }),
    validateEmail: builder.mutation<CoreResponse<ISession>, string>({
      query: (value) => ({
        url: '/signup/validations/email',
        method: 'PUT',
        body: { value },
      }),
    }),
    requestPasswordChange: builder.mutation<CoreResponse<AuthResetPasswordCodeResponse>, string>({
      query: (phoneNumber) => ({
        url: '/auth/passwords',
        method: 'POST',
        body: { user: { phoneNumber } },
      }),
    }),
    resetPassword: builder.mutation<CoreResponse<AuthResetPasswordCodeResponse>, AuthResetPassword>(
      {
        query: ({ token, ...user }) => ({
          url: `/auth/passwords/${token}`,
          method: 'PUT',
          body: { user },
        }),
      }
    ),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useValidatePhoneMutation,
  useValidateEmailMutation,
  useRequestPasswordChangeMutation,
  useResetPasswordMutation,
} = authApi;
