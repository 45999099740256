import { createSelector } from '@reduxjs/toolkit';

import { supportApi } from 'services/api/core/support/api';

export const selectSupportCategoriesResult = supportApi.endpoints.getFaqCategories.select();

export const selectAllCategories = createSelector(
  selectSupportCategoriesResult,
  (usersResult) => usersResult?.data?.data ?? []
);

export const selectCategoryById = createSelector(
  [selectAllCategories, (_state, categoryId) => categoryId],
  (categories, categoryId) => categories?.find((category) => category.id === categoryId)
);
