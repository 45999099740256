import { FC, createContext, useContext } from 'react';
import axios from 'axios';
import { PRROClient } from '@smart-kasa/prro-api';

import { ConfigContext } from 'context/ConfigContext';
import { useDigitalSignature } from 'components/DigitalSignature';

interface IRROContext {
  prroApi: PRROClient;
}

export const RROContext = createContext<IRROContext>({
  prroApi: new PRROClient(),
});

export const RROContextProvider: FC = ({ children }) => {
  const { config } = useContext(ConfigContext);
  const { getDigitalSignature } = useDigitalSignature();

  const api = new PRROClient({ baseURL: config.fiscalProxyUrl });

  api.client.interceptors().request.use(async (request) => {
    const digitalSignature = await getDigitalSignature();

    if (!digitalSignature) throw new axios.Cancel('Operation canceled by the user.');

    // @ts-ignore
    request.data = digitalSignature.SignDataInternal(true, JSON.stringify(request.data), false);

    return request;
  });

  return <RROContext.Provider value={{ prroApi: api }}>{children}</RROContext.Provider>;
};
