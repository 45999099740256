import { FC, Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import {
  Hint,
  Title,
  InlineSpace,
  Paragraph,
  Row,
  Col,
  Strong,
  UAH,
  Table,
  TBody,
  THead,
  Td,
  Th,
  Tr,
} from '@smart-kasa/ui';

import { IBillingInvoice } from 'services/api/core/invoices/types';
import { IAMProfile } from 'services/api/core/iam/types';

import Logo from 'assets/logo.svg';
import { INVOICE_TITLE } from 'features/billing/constants/invoiceTitle';
import { BILLING_INVOICE_STATUS } from 'features/billing/constants/invoiceStatus';
import styles from './Bill.module.scss';

export interface IBillProps {
  invoice: IBillingInvoice;
  payer?: IAMProfile;
}

const BillHeader = (props) => <div {...props} className={styles.header} />;

export const Bill: FC<IBillProps> = ({ invoice, payer }) => (
  <Fragment>
    <BillHeader>
      <Title>
        {INVOICE_TITLE[invoice.invoiceTypeId]}
        <Hint margin={0}>{BILLING_INVOICE_STATUS[invoice.state].title}</Hint>
      </Title>

      <img src={Logo} className={styles.logo} alt="Invoice logo" />
    </BillHeader>

    <InlineSpace direction="vertical" size={30} />

    <Strong>Номер рахунку: №{invoice.number}</Strong>
    {invoice.paidAt && (
      <Paragraph>
        Дата сплати: <FormattedDate value={invoice.paidAt} dateStyle="short" timeStyle="short" />
      </Paragraph>
    )}

    <InlineSpace direction="vertical" size={30} />

    <Row>
      <Col span={12}>
        <Strong>Смарт Каса</Strong>
        <Paragraph>help@smartkasa.ua</Paragraph>
      </Col>

      <Col span={12}>
        <Strong>Рахунок виставлено на:</Strong>
        <Paragraph>{payer?.name}</Paragraph>
        <Paragraph>{payer?.phoneNumber}</Paragraph>
        <Paragraph>{payer?.email}</Paragraph>
      </Col>
    </Row>

    <InlineSpace direction="vertical" size={30} />

    <Table>
      <THead>
        <Tr>
          <Th>Опис</Th>
          <Th>Сума</Th>
        </Tr>
      </THead>
      <TBody>
        <Tr>
          <Td>
            <Strong>{invoice.payload?.details}</Strong>
          </Td>
          <Td>
            <UAH value={invoice.amount} />
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Strong>Загальна сума до сплати</Strong>
          </Td>
          <Td>
            <Strong>
              <UAH value={invoice.amount} />
            </Strong>
          </Td>
        </Tr>
      </TBody>
    </Table>
  </Fragment>
);
