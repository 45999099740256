import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AssetFileFormData } from './types';

export const awsApi = createApi({
  reducerPath: 'awsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    upload: builder.mutation<unknown, { url: string; data: AssetFileFormData }>({
      query: ({ url, data }) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          formData.append(key, data[key as keyof AssetFileFormData]);
        });

        return {
          url,
          method: 'POST',
          body: formData,
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const { useUploadMutation } = awsApi;
