import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TabsNavigation, TabsNavigationItem } from '@smart-kasa/ui';

interface IImportNavigationTab {
  path: string;
  title: string;
}

export const ImportNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const navigation: IImportNavigationTab[] = [
    {
      path: '/inventory/import/catalog',
      title: formatMessage({ id: 'navigation.import.catalog', defaultMessage: 'Імпорт товарів' }),
    },
    {
      path: '/inventory/import/stock',
      title: formatMessage({ id: 'navigation.import.stock', defaultMessage: 'Імпорт залишків' }),
    },
  ];

  return (
    <TabsNavigation>
      {navigation.map(({ path, title }) => (
        <TabsNavigationItem
          key={title}
          onClick={() => navigate(path)}
          isActive={location.pathname === path}
        >
          {title}
        </TabsNavigationItem>
      ))}
    </TabsNavigation>
  );
};
