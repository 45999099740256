import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineSpace, Preloader, Title } from '@smart-kasa/ui';

import useConfirm from 'hooks/useConfirm';
import type { IAMIntegration } from 'services/api/core/integrations/types';
import {
  useConnectChannelMutation,
  useDeleteChannelMutation,
  useGetChannelsQuery,
} from 'services/api/core/integrations/api';

import { Channel } from '../Channel';

export const ChannelList = () => {
  const { data: integrations, isLoading } = useGetChannelsQuery();
  const { confirm } = useConfirm();
  const [updateChannel] = useConnectChannelMutation();
  const [deleteChannel] = useDeleteChannelMutation();

  const connect = (channel: IAMIntegration) => updateChannel({ id: channel.id, isPrimary: true });
  const disconnect = async (channel: IAMIntegration) => {
    const isConfirmed = await confirm("Ви дійсно бажаєте видалити канал зв'язку?");

    if (isConfirmed) deleteChannel(channel.id);
  };

  if (isLoading) return <Preloader />;

  return (
    <Fragment>
      <Title variant="h3">
        <FormattedMessage id="integrations.connected" defaultMessage="Підключені канали зв'язку:" />
      </Title>
      <InlineSpace direction="vertical" size={10} />

      {integrations?.data.map((channel) => (
        <Channel
          channel={channel}
          key={channel.id}
          onRemove={() => disconnect(channel)}
          onUpdate={() => connect(channel)}
        />
      ))}

      {integrations?.data.length === 0 && (
        <Fragment>
          <FormattedMessage id="integrations.empty" defaultMessage="Підписки відсутні" />
        </Fragment>
      )}
    </Fragment>
  );
};
