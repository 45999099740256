import { Fragment, useMemo, useRef } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import {
  BreadCrumb,
  BreadCrumbItem,
  Button,
  Col,
  DropDown,
  Icon,
  InlineSpace,
  PageHeader,
  Pagination,
  Row,
} from '@smart-kasa/ui';

import { useAppSelector, useQueryPagination, useQuery } from 'hooks';
import useModal from 'hooks/useModal';
import { useGetCategoriesQuery, useGetProductsQuery } from 'services/api/core/inventory/api';
import { useLazyExportProductsQuery } from 'services/api/core/export/api';
import {
  selectCategoryById,
  selectCategorySiblings,
  selectCategoryAncestors,
} from 'features/inventory/slice';

import { ProductFilter } from '../../components/ProductFilter';
import { CategoryList } from '../../components/CategoryList';
import { ProductList } from '../../components/ProductList';

export const CatalogList = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { query, push: setQuery } = useQuery();
  const { categoryId } = useParams();
  const { isShowing, showModal, hideModal } = useModal();
  const { onPageChange, onSortChange } = useQueryPagination();

  const params = useMemo(() => ({ categoryId, ...query }), [categoryId, query]);
  const { isLoading } = useGetCategoriesQuery();
  const { data: products, isFetching } = useGetProductsQuery(params);
  const [download] = useLazyExportProductsQuery();
  const category = useAppSelector((state) => selectCategoryById(state, categoryId));
  const categories = useAppSelector((state) => selectCategorySiblings(state, categoryId));
  const breadcrumbs = useAppSelector((state) => selectCategoryAncestors(state, categoryId));

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Fragment>
      <PageHeader
        actions={
          <Fragment>
            <Button rightIcon={<Icon name="template" />} onClick={() => download(params)}>
              Експорт
            </Button>
            <InlineSpace />
            <DropDown>
              <Button color="primary" rightIcon={<Icon name="arrowDown" size="small" />}>
                Додати
              </Button>
              <DropDown.Menu>
                <DropDown.Item
                  icon="folder"
                  onClick={() => {
                    navigate(
                      category
                        ? `/inventory/categories/${categoryId}/create`
                        : '/inventory/categories/create'
                    );
                  }}
                >
                  Додати каталог
                </DropDown.Item>
                <DropDown.Item
                  icon="list"
                  onClick={() => {
                    navigate(
                      category
                        ? `/inventory/categories/${categoryId}/products/create`
                        : '/inventory/products/create'
                    );
                  }}
                >
                  Додати товар
                </DropDown.Item>
              </DropDown.Menu>
            </DropDown>
            <InlineSpace />
            <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
              Фільтр
            </Button>
          </Fragment>
        }
      >
        <BreadCrumb>
          <BreadCrumbItem onClick={() => navigate('/inventory')}>Каталог товарів</BreadCrumbItem>
          {breadcrumbs.map(({ id, title }, index) => (
            <BreadCrumbItem
              key={id}
              index={index + 1}
              onClick={() =>
                navigate({
                  pathname: `/inventory/categories/${id}`,
                  search: createSearchParams(query).toString(),
                })
              }
            >
              {title}
            </BreadCrumbItem>
          ))}
        </BreadCrumb>
      </PageHeader>

      <Row gutter={10}>
        <Col span={7}>
          <CategoryList
            categories={categories}
            isLoading={isLoading}
            selected={category}
            onSelect={handleScroll}
          />
        </Col>
        <Col span={17}>
          <ProductList
            ref={ref}
            products={products?.data}
            isLoading={isFetching}
            sortMode={query.sortMode}
            sortColumn={query.sortColumn}
            onSortChange={onSortChange}
          />
          <Pagination
            totalItems={products?.meta?.totalCount}
            currentPage={products?.meta?.currentPage}
            perPage={products?.meta?.limitValue}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>

      <ProductFilter
        show={isShowing}
        onClose={hideModal}
        initialValues={query}
        onSubmit={setQuery}
      />
    </Fragment>
  );
};
