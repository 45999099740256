import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ExternalLink, Logo } from 'components';
import styles from './AuthLayout.module.scss';

const AGREEMENT_URL = 'https://smartkasa.ua/publichnyj-dogovir-oferty-pro-nadannya-poslug/';
const PRIVACY_URL = 'https://www.smartkasa.ua/privacy-policy/';

export const AuthLayout = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <Logo />

      <div className={styles.content}>
        <Outlet />
      </div>

      <div className={styles.footer}>
        <FormattedMessage
          id="auth.policy"
          values={{
            agreement: (text) => <ExternalLink to={AGREEMENT_URL}>{text}</ExternalLink>,
            privacy: (text) => <ExternalLink to={PRIVACY_URL}>{text}</ExternalLink>,
          }}
        />
      </div>
    </div>
  </div>
);
