import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, Card, Icon, InlineSpace, PageHeader } from '@smart-kasa/ui';

import { Field, InlineLink } from 'components';
import { MIME_TYPES } from 'constants/mimeTypes';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { useCreateStockImportDocumentMutation } from 'services/api/core/inventory/api';

import { ImportStockErrorSchema, ImportStockSchema } from './ImportStockSchema';
import styles from './ImportStockCreate.module.scss';

export const ImportStockCreate = () => {
  const { csv, xls, xlsx } = MIME_TYPES;
  const [create] = useCreateStockImportDocumentMutation();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (fd) => {
      create(fd)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => {
          return ImportStockErrorSchema.get(err);
        });
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <PageHeader
        actions={
          <a
            href="https://smartkasa-landing.s3.eu-central-1.amazonaws.com/import/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD+%D0%B7%D0%B0%D0%B2%D0%B0%D0%BD%D1%82%D0%B0%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F+%D0%B7%D0%B0%D0%BB%D0%B8%D1%88%D0%BA%D1%96%D0%B2.xlsx"
            className={styles.download}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Скачати шаблон</span>
            <Icon name="template" />
          </a>
        }
      >
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Завантажити файл імпорту залишків
        </InlineLink>
      </PageHeader>

      <Card>
        <Form validate={transformValidationSchema(ImportStockSchema)} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field.FileUploader
                name="attachment"
                className={styles.fileUploader}
                accept={`${csv}, ${xlsx}, ${xls}`}
                icon="files"
                size="full"
                uploaderLabel={
                  <Fragment>
                    <div>Перетягніть файли сюди або завантажте тут</div>
                    <Button color="sub-primary" className={styles.button}>
                      Додати файл
                    </Button>
                  </Fragment>
                }
              />

              <div className={styles.actions}>
                <Button width="wide" onClick={() => navigate(-1)}>
                  Скасувати
                </Button>
                <InlineSpace />
                <Button width="wide" color="primary" type="submit">
                  Зберегти
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Card>
    </Fragment>
  );
};
