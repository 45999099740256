import { Route, Routes } from 'react-router-dom';

import { EmployeeList } from './routes/EmployeeList';
import { EmployeeCreate } from './routes/EmployeeCreate';
import { EmployeeEdit } from './routes/EmployeeEdit';

export const Employees = () => (
  <Routes>
    <Route index element={<EmployeeList />} />
    <Route path=":id" element={<EmployeeEdit />} />
    <Route path="create" element={<EmployeeCreate />} />
  </Routes>
);
