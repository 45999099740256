import { useMemo } from 'react';
import { ISelectOption } from '@smart-kasa/ui';

import { IAMRole } from 'services/api/core/roles/types';
import { useGetRolesQuery } from 'services/api/core/roles/api';

const transformRoles = (roles: IAMRole[] = []): ISelectOption[] =>
  roles.map((role) => ({
    label: role.name,
    value: role.id,
    key: role.id,
  }));

type UseRolesResult = {
  roles?: IAMRole[];
  rolesOptions?: ISelectOption[];
  refresh: () => void;
};

export const useRoles = (): UseRolesResult => {
  const { roles, refetch } = useGetRolesQuery(undefined, {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, roles: response?.data ?? [] }),
  });

  const rolesOptions = useMemo(() => transformRoles(roles), [roles]);

  return {
    roles,
    rolesOptions,
    refresh: refetch,
  };
};
