import { coreApi } from '../api';
import type { CoreResponse } from 'types/api';
import type { IEmployee } from '../employees/types';
import type { IStaff } from './types';

const staffApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaff: builder.query<CoreResponse<IEmployee[]>, number>({
      query: (shopId) => ({ url: `rsn/shops/${shopId}/staffs` }),
    }),
    createStaff: builder.mutation<CoreResponse<IStaff>, Partial<IStaff>>({
      query: ({ shopId, ...staff }) => ({
        url: `rsn/shops/${shopId}/staffs`,
        method: 'POST',
        body: { staff },
      }),
    }),
    updateStaff: builder.mutation<CoreResponse<IStaff>, Partial<IStaff>>({
      query: ({ id, shopId, ...staff }) => ({
        url: `rsn/shops/${shopId}/staffs/${id}`,
        method: 'PUT',
        body: { staff },
      }),
    }),
    deleteStaff: builder.mutation<void, Partial<IStaff>>({
      query: ({ id, shopId }) => ({
        url: `rsn/shops/${shopId}/staffs/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStaffQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = staffApi;
