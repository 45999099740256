import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import {
  ButtonText,
  Card,
  CardTitle,
  DataTable,
  Icon,
  IDataTableColumn,
  InlineSpace,
} from '@smart-kasa/ui';

import type { ITerminal } from 'services/api/core/pos/types';

import { TerminalStatus } from 'features/terminals';
import styles from './TerminalList.module.scss';

export interface IShopTerminalList {
  terminals: ITerminal[] | undefined;
  onTerminalClick?: (terminal: ITerminal) => void;
  onTerminalAdd?: () => void;
}

export const TerminalList: FC<IShopTerminalList> = ({
  terminals,
  onTerminalClick,
  onTerminalAdd,
}) => {
  const columns: IDataTableColumn<ITerminal>[] = [
    {
      key: 'name',
      title: 'Назва',
      render: (name, { id }) => name || `Смарт Каса #${id}`,
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <TerminalStatus state={state} />,
    },
    {
      key: 'serialNumber',
      title: 'Серійний номер',
    },
    {
      key: 'activatedAt',
      title: 'Дата активації',
      render: (activatedAt: string) =>
        activatedAt && <FormattedDate value={activatedAt} dateStyle="short" timeStyle="short" />,
    },
  ];

  return (
    <Card className={styles.wrapper}>
      <CardTitle>Обладнання</CardTitle>

      <DataTable
        rowIdKey="id"
        data={terminals}
        columns={columns}
        onRowClick={onTerminalClick}
        emptyText="Відчуйте переваги автоматизації підключивши Вашу першу Смарт Касу."
      />

      <InlineSpace direction="vertical" />

      <ButtonText onClick={onTerminalAdd}>
        <Icon name="plus" size="small" />
        <InlineSpace />
        Додати термінал
      </ButtonText>
    </Card>
  );
};
