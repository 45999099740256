import { useCallback } from 'react';
import { Button, Preloader, Row } from '@smart-kasa/ui';

import useToast from 'hooks/useToast';
import { useActivateServiceMutation, useGetServicesQuery } from 'services/api/core/services/api';
import { BillingSubscriptionState } from 'services/api/core/billing/enums';

import { ServiceCard } from '../ServiceCard';
import styles from './ServiceList.module.scss';

export const ServiceList = () => {
  const { toast } = useToast();
  const { data: services, isLoading } = useGetServicesQuery({});
  const [activeteService] = useActivateServiceMutation();
  const activate = useCallback(
    (service) => {
      activeteService(service.id)
        .unwrap()
        .then(({ data: subscription }) => {
          const wayforpay = new window.Wayforpay();

          if (subscription?.payload?.invoiceUrl) {
            wayforpay.invoice(subscription.payload.invoiceUrl);
          }

          if (subscription.state === BillingSubscriptionState.ACTIVE)
            toast.success('Послугу успішно активовано');
        })
        .catch(() => toast.error('Не вдалось підключити послугу'));
    },
    [activeteService, toast]
  );

  if (!services || isLoading) return <Preloader />;

  return (
    <Row className={styles.list}>
      {services?.data.map((service) => (
        <ServiceCard
          key={service.id}
          service={service}
          actions={(service) => (
            <Button color="primary" width="full" onClick={() => activate(service)}>
              Активувати
            </Button>
          )}
        />
      ))}
    </Row>
  );
};
