import { Route, Routes } from 'react-router-dom';

import { SubgroupList } from './routes/SubgroupList';
import { SubgroupCreate } from './routes/SubgroupCreate';
import { SubgroupEdit } from './routes/SubgroupEdit';
import { SubgroupDelete } from './routes/SubgroupDelete';

export const Subgroups = () => (
  <Routes>
    <Route element={<SubgroupList />}>
      <Route index element={null} />
      <Route path="create" element={<SubgroupCreate />} />
      <Route path=":id/edit" element={<SubgroupEdit />} />
      <Route path=":id/delete" element={<SubgroupDelete />} />
    </Route>
  </Routes>
);
