import { Fragment } from 'react';
import { EmptyState, Icon, Modal, PageHeader, Paragraph } from '@smart-kasa/ui';

import { useProfile } from 'features/profile/hooks/useProfile';
import { useCreateFeedbackMutation } from 'services/api/core/feedback/api';

import { FeedbackForm } from '../FeedbackForm';
import styles from './FeedbackDialog.module.scss';

interface IFeedbackDialog {
  isVisible?: boolean;
  onClose: () => void;
}

export const FeedbackDialog = ({ isVisible, onClose }: IFeedbackDialog) => {
  const { profile } = useProfile();
  const [create, { isSuccess }] = useCreateFeedbackMutation();

  return (
    <Modal onClose={onClose} show={isVisible} className={styles.modal}>
      {isSuccess ? (
        <EmptyState
          header={<Icon color="primary" name="support" size="xxx-large" />}
          description={
            <Paragraph className={styles.text}>
              Дякуємо, запит направлено до служби підтримки
            </Paragraph>
          }
        />
      ) : (
        <Fragment>
          <PageHeader>Надіслати запит</PageHeader>

          <FeedbackForm
            initialValues={{
              name: profile?.name,
              phoneNumber: profile?.phoneNumber,
              email: profile?.email,
            }}
            onSubmit={create}
            onDismiss={onClose}
          />
        </Fragment>
      )}
    </Modal>
  );
};
