import { Options } from 'highcharts';

const locale: Options['lang'] = {
  weekdays: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', "П'ятниця", 'Субота'],
  months: [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ],
  shortMonths: [
    'Січ',
    'Лют',
    'Бер',
    'Квіт',
    'Трав',
    'Черв',
    'Лип',
    'Серп',
    'Вер',
    'Жовт',
    'Лист',
    'Груд',
  ],
  resetZoom: 'Скинути масштаб',
};

export default locale;
