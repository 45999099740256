import { FormattedDate, FormattedMessage } from 'react-intl';
import { Badge, ButtonIcon, DropDown, Hint, Icon, IconName } from '@smart-kasa/ui';

import type { IAMIntegration } from 'services/api/core/integrations/types';

import styles from './Channel.module.scss';

export interface IChannelProps {
  channel: IAMIntegration;
  onRemove?: (channel) => void;
  onUpdate?: (channel) => void;
}

export const Channel = ({ channel, onUpdate, onRemove }: IChannelProps) => (
  <div className={styles.channel}>
    <div className={styles.info}>
      <div className={styles.provider}>
        <Icon name={channel.providerName as IconName} className={styles.icon} />
        {channel.providerName}
        {channel.isPrimary && (
          <Badge className={styles.badge}>
            <FormattedMessage id="integrations.channel.primary" defaultMessage="ОСНОВНИЙ" />
          </Badge>
        )}
      </div>
      <Hint margin={0} className={styles.date}>
        <FormattedDate value={channel.subscribedAt} dateStyle="short" timeStyle="short" />
      </Hint>
    </div>

    <DropDown>
      <ButtonIcon size="small">
        <Icon name="settings" size="medium" />
      </ButtonIcon>
      <DropDown.Menu>
        {!channel.isPrimary && (
          <DropDown.Item icon="star" onClick={() => onUpdate && onUpdate(channel)}>
            <FormattedMessage
              id="integrations.channel.actions.make-primary"
              defaultMessage="Зробити основним"
            />
          </DropDown.Item>
        )}
        <DropDown.Item icon="trash" onClick={() => onRemove && onRemove(channel)}>
          <FormattedMessage id="integrations.channel.actions.delete" defaultMessage="Видалити" />
        </DropDown.Item>
      </DropDown.Menu>
    </DropDown>
  </div>
);
