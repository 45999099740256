import { Fragment, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  DataTable,
  IDataTableColumn,
  UAH,
  Tooltip,
  Icon,
  Button,
  InlineSpace,
  Badge,
  AvatarItem,
  Card,
  PageHeader,
  Strong,
} from '@smart-kasa/ui';

import { Picture } from 'components';
import { useQueryPagination, useQuery } from 'hooks';
import useModal from 'hooks/useModal';
import { dateToISO8601String } from 'utils/date';
import type { GoodsFilterQuery, IGoodsItem } from 'services/api/core/stats/types';
import { useGetSalesReportQuery } from 'services/api/core/stats/api';
import { useLazyExportSalesQuery } from 'services/api/core/export/api';
import { StatsCards } from 'features/stats/components/StatsCards';

import { GoodsFilter } from 'features/stats/components/GoodsFilter';

export const StatsGoods = () => {
  const { query, push: setQuery } = useQuery();
  const { isShowing, showModal, hideModal } = useModal();
  const { onSortChange } = useQueryPagination();
  const params: GoodsFilterQuery = useMemo(
    () => ({
      sortMode: 'desc',
      sortColumn: 'popularity',
      dateStart: dateToISO8601String(dayjs().startOf('month').toDate()),
      dateEnd: dateToISO8601String(dayjs().endOf('day').toDate()),
      ...query,
    }),
    [query]
  );
  const { data: goods, isLoading } = useGetSalesReportQuery(params);
  const [download] = useLazyExportSalesQuery();

  const stats: any = useMemo(
    () => [
      {
        title: 'Загальна кількість',
        value: goods?.data.totalCount,
        money: false,
      },
      {
        title: (
          <>
            Загальна сума
            <Tooltip text="Загальна сума не враховує знижки які надано на весь чек під час продажу">
              <Icon color="primary" name="info" size="medium" />
            </Tooltip>
          </>
        ),
        value: goods?.data.totalAmount,
        money: true,
      },
    ],
    [goods]
  );

  const columns: IDataTableColumn<IGoodsItem>[] = [
    {
      key: 'title',
      width: '45%',
      sort: true,
      title: 'Назва товару або послуги',
      render: (name: string, { picture, number }) => (
        <AvatarItem
          avatar={<Picture src={picture} icon="camera" fill="green" />}
          primaryText={<Strong>{name}</Strong>}
          secondaryText={number}
        />
      ),
    },

    { key: 'remoteId', title: 'Код', sort: true },
    { key: 'alterNumber', title: 'Артикул', sort: true },
    { key: 'totalCount', title: 'Кількість', sort: true },
    { key: 'unitTypeName', title: 'Од. виміру', sort: true },
    {
      key: 'price',
      sort: true,
      title: 'Ціна',
      render: (price: number) => <UAH value={price} />,
    },
    {
      key: 'totalAmount',
      sort: true,
      title: 'Загальна сума',
      render: (summary: number) => <UAH value={summary} />,
    },
    {
      key: 'profitMargin',
      title: (
        <Fragment>
          Прибуток
          <Tooltip text="Прибуток вираховується як різніця ціни продажу і ціни закупки за умови що ціна закупки заповнена в картці товару">
            <Badge>BETA</Badge>
          </Tooltip>
        </Fragment>
      ),
      render: (profit: number) => (profit ? <UAH value={profit} /> : '-'),
    },
    {
      key: 'popularity',
      sort: true,
      title: (
        <Fragment>
          Популярність
          <Tooltip text="Відсоток чеків з артикулом до всієї кількості чеків">
            <Icon color="primary" name="info" size="medium" />
          </Tooltip>
        </Fragment>
      ),
      render: (popularity: number) => <>{Number(popularity).toFixed(2)}%</>,
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Fragment>
            <Button rightIcon={<Icon name="template" />} onClick={() => download(params)}>
              Експорт
            </Button>
            <InlineSpace />
            <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
              Фільтр
            </Button>
          </Fragment>
        }
      >
        Продукти та послуги
      </PageHeader>

      {stats && <StatsCards data={stats} />}

      <Card>
        <DataTable
          rowIdKey="id"
          data={goods?.data.products}
          columns={columns}
          isLoading={isLoading}
          emptyText="Продукти та послуги за даний період часу не знайдені"
          onSortChange={onSortChange}
          sortMode={params.sortMode}
          sortColumn={params.sortColumn}
        />
      </Card>

      <GoodsFilter
        initialValues={params}
        show={isShowing}
        onClose={hideModal}
        onSubmit={setQuery}
      />
    </Fragment>
  );
};
