import { Route, Routes } from 'react-router-dom';

import { RoleList } from './routes/RoleList';
import { RoleEdit } from './routes/RoleEdit';
import { RoleCreate } from './routes/RoleCreate';

export const Roles = () => (
  <Routes>
    <Route index element={<RoleList />} />
    <Route path=":id" element={<RoleEdit />} />
    <Route path="create" element={<RoleCreate />} />
  </Routes>
);
