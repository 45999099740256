import { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu, IMenuItemProps } from '@smart-kasa/ui';

export type MenuLinkProps = Omit<IMenuItemProps, 'children'> & {
  to: string;
};

export type MenuExternalLinkProps = Omit<IMenuItemProps, 'children'> & {
  url: string;
};

export const MenuLink: FC<MenuLinkProps> = ({ to, ...rest }) => {
  const match = useMatch(to);

  return <Menu.Item {...rest} active={Boolean(match)} component={Link} componentProps={{ to }} />;
};

export const MenuExternalLink: FC<MenuExternalLinkProps> = ({ url, ...rest }) => (
  <Menu.Item {...rest} component="a" componentProps={{ href: url, target: '_blank' }} />
);
